export default {
  TALQUI: {
    CAMPAIGNS: {
      DEFAULT: {
        FILTERS_CHANNELS_TITLE: "Campanhas por canal",
        FILTERS_CHANNEL_ALL: "Todos os canais",
        FILTERS_CHANNEL_WHATSAPP: "WhatsApp",
        FILTERS_CHANNEL_TELEGRAM: "Telegram",
        FILTERS_TEMPLATES_TITLE: "Modelos por canal",
        FILTERS_TEMPLATES_ALL: "Todos os canais",
        FILTERS_TEMPLATES_WHATSAPP: "WhatsApp",
        FILTERS_TEMPLATES_TELEGRAM: "Telegram",
        ROW_STATUS_DRAFT: "Rascunho",
        ROW_STATUS_SCHEDULED: "Agendado",
        ROW_STATUS_PROCESSING: "Enviando",
        ROW_STATUS_SUCCESS: "Envio concluído",
        ROW_STATUS_FAILURE: "Falha ao enviar",
        EDITION_DISABLED: "Esta campanha não permite edição.",
      },
      FINISH: "Finalizar",
      SCHEDULE: "Agendar",
      RECIPIENTS: {},
      TEMPLATES: {
        BOX_TITLE: "Os seus modelos disponíveis",
        BOX_DESCRIPTION:
          "Fizemos uma lista de todos o modelos previamente criados e disponíveis para este canal.",
      },
      COMMON: {
        BREADCRUMB_RECIPIENTS: "Destinatários",
        BREADCRUMB_TEMPLATE: "Modelo",
        BREADCRUMB_PREVIEW: "Prévia",
        BREADCRUMB_SETTINGS: "Configurações",
      },
      EDIT: {
        CONTACTS_CHANNEL_IMPORT: "Importar contatos",
        CONTACTS_CHANNEL_REACHABLE: "Todos os contatos do canal",
        CONTACTS_CHANNEL_SELECTED: "Selecionar contatos do canal",
      },
    },
    ANALYTICS: {
      BOXES: {
        MESSAGING: {
          CONVERSATION_ACTIVITY: {
            HEADER_TITLE:
              "Atividade da semana atual comparada com a da semana anterior",
            ACTIVITY_WEEK_BEFORE: "na semana anterior",
            ACTIVITY_CONVERSATIONS_TITLE: "atendimentos",
            ACTIVITY_MEAN_RESPONSE_TIME_TITLE: "Tempo médio de atendimento",
            ACTIVITY_WEBSITE_VISITORS_TITLE: "novos contatos",
          },
        },
      },
    },
    TOOLTIPS: {
      INBOX_USERINFO_NICKNAME_VERIFIED: "Nome do contato foi verificado",
      INBOX_USERINFO_BROKER: "Canal de atendimento",
      INBOX_USERINFO_CONTACT_PHONE: "Telefone do contato",
      INBOX_USERINFO_CONTACT_NICKNAME: "Perfil do contato",
      NO_CHANNEL: "Canal desconhecido",
      VERIFY_NICKNAME: "Verificar nome",
    },
    POPUPS: {
      IMPORT_CONTACTS: {
        PROCEED_TEXT_LABEL_ESTIMATED: "estimados",
        PROCEED_TEXT_LABEL_ACCEPTED: "importados",
        PROCEED_TEXT_LABEL_REFUSED: "recusados",
        CONFIGURE_TITLE: "Configurar a Importação do CSV",
        CONFIGURE_LABEL:
          "Mapeie campos personalizados de contatos do seu CSV para os campos reconhecidos pela Reinvent - Após a importação você poderá prosseguir com a configuração da campanha.",
      },
      NEW_CAMPAIGN: {
        STEP_CHANNEL: "Canal de disparo",
        STEP_DETAILS: "Detalhes da campanha",
        CHANNEL_PROCEED: "Continuar com este canal",
        CHANNEL_TITLE: "Selecione o canal de saída da campanha",
        CHANNEL_LABEL:
          "Você deverá criar campanhas unitáriamente por canal; detalhes do canal e contatos destino serão configurados nos próximos passos.",
        CHANNEL_WHATSAPP_SECONDARY_TITLE: "WhatsApp Paralelo",
        CHANNEL_WHATSAPP_SECONDARY_DESCRIPTION:
          "Envie campanhas pelo canal do WhatsApp Paralelo, atenção para as ",
        CHANNEL_WHATSAPP_TITLE: "WhatsApp",
        CHANNEL_WHATSAPP_DESCRIPTION:
          "Inicie conversas com contatos do whatsapp enviando uma mensagem de forma ativa",
        CHANNEL_TELEGRAM_TITLE: "Telegram",
        CHANNEL_TELEGRAM_DESCRIPTION:
          "Envie mensagens de forma ativa para contatos no telegram sem custos adicionais",
        DETAILS_PROCEED: "Configurar minha campanha",
        DETAILS_TITLE: "Insira os detalhes campanha",
        DETAILS_LABEL:
          "O nome da campanha é uma informação interna para identificação e não será enviada para os contatos.",
        DETAILS_FIELD_NAME_LABEL: "Nome da Campanha",
        DETAILS_FIELD_NAME_PLACEHOLDER: "Digite o nome da campanha…",
      },
      SET_SESSION_CLOSED: {
        TITLE: "Motivo de encerramento",
        DESCRIPTION:
          "Selecione uma opção que representa o assunto deste atendimento",
        CONFIRM_CLOSE: "Confirmar encerramento",
      },
      INVITE_OPERATOR: {
        TITLE: "Convide um operador",
        DESCRIPTION:
          "Insira um e-mail no campo abaixo para convidar um novo operador - ele receberá o convite nos próximos minutos e deverá seguir o formulário para ingressar na equipe",
        INVITE_EMAIL_LABEL: "E-mail do destinatário",
        INVITE_EMAIL_PLACEHOLDER:
          "Entre com o e-mail do operador que deseja convidar",
        CONFIRM_INVITE: "Enviar convite",
        TOAST_SUCCESS: "Seu convite foi enviado com sucesso",
        TOAST_ERROR:
          "Não foi possível enviar seu convite no momento - ente em contato com suporte",
      },
      REMOVE_MESSAGE: {
        QUESTION: "Tem a certeza de remover esta mensagem?",
        LABEL:
          "Esta mensagem será retirada para sempre da nossa plataforma. Se a sua mensagem já foi entregue através de correio electrónico ou de integrações de terceiros, tais como Messenger ou Whatsapp, poderá ser impossível removê-la.",
        PROCEED: "Remover Mensagem",
      },
      CONFIRM_NEW_SESSION: {
        TITLE: "Você deseja abrir um novo atendimento?",
        DESCRIPTION:
          "Este atendimento já foi encerrado anteriormente, se você enviar esta mensagem estará abrindo um novo atendimento com este contato.",
        PROCEED: "Abrir novo atendimento",
        ON_PROCEED: "Abrindo novo atendimento",
      },
    },
    INITIATE: {
      REGISTER: {
        NAME_LABEL: "Qual é o nome da sua empresa?",
        NAME_PLACEHOLDER: "Nome da empresa ou organização",
        DOMAIN_LABEL: "Qual é o endereço do seu site?",
        DOMAIN_PLACEHOLDER: "www.minha-empresa.com",
      },
      OPERATOR: {
        LOGIN_SUBTITLE:
          "Entre na sua conta da Reinvent para participar da equipe.",
        BUTTON_LOGIN: "Entrar & Participar",
        FAILED: {
          TITLE: "Convite Inválido",
          SUBTITLE: "Este convite expirou. Será que já foi usado antes?",
        },
        ABORTED: {
          TITLE: "Não Foi Possível Participar do Site",
          SUBTITLE:
            "Este site atingiu o limite de operadores. Por favor, atualize o plano.",
        },
        WELCOME: {
          TITLE: "Você foi convidado para participar da equipe {website}",
          SUBTITLE:
            "Reinvent é um aplicativo de atendimento ao cliente usado por {website}.",
          SUBTITLE_EXISTING:
            "Como você já tem uma conta cadastrada na Reinvent com o e-mail do convite, clique em 'Continuar' associar {website} a sua caixa de entrada.",
          SUBTITLE_NEW:
            "Vamos criar uma nova conta do Reinvent para que você possa entrar na Caixa de Entrada de {website}, clique em 'Continuar' para criar sua nova conta.",
          BUTTON: "Continuar",
        },
        REGISTER: {
          TITLE: "Sua Conta",
          SUBTITLE:
            "Crie sua conta e escolha uma senha para entrar na Caixa de Entrada de {website}",
          ACCOUNT: "Conta",
        },
        SUCCESS: {
          TITLE: "Já está pronto!",
          SUBTITLE:
            "Você está na Caixa de Entrada de {website}. Você pode agora colaborar com seus colegas e conversar com os clientes.",
          BUTTON: "Ir para Caixa de Entrada de {website}",
        },
        AVAILABILITY_SELECT: {
          TITLE: "Disponibilidade",
          SUBTITLE:
            "Escolha o melhor modo de disponibilidade para você, assim não vamos notificá-lo quando você estiver ausente.",
          AVAILABLE_APP: {
            TITLE: "Disponível ao usar o aplicativo",
            SUBTITLE:
              "Você será visto como disponível apenas quando o aplicativo do Reinvent estiver aberto no seu computador ou no seu celular.",
          },
          SCHEDULE: {
            TITLE: "Predefinir horário de expediente",
            SUBTITLE:
              "Você será visto como disponível apenas durante o horário de trabalho que definir.",
          },
        },
        AVAILABILITY_SCHEDULE: {
          TITLE: "Disponibilidade",
          SUBTITLE:
            "Personalize sua agenda de dias e horários disponíveis (no seu fuso horário). Nos demais horários, você será marcado como ausente para sua equipe e seus clientes.",
          BUTTON: "Continuar",
        },
        NOTIFICATIONS: {
          TITLE: "Notificações",
          SUBTITLE:
            "Escolha como você receberá suas notificações. Não recomendamos desativá-las, pois você não será notificado quando chegarem novas mensagens.",
          ENABLE: {
            TITLE: "Ativar notificações",
            SUBTITLE: "Você será notificado de novas mensagens (via push)",
          },
          SKIP: "Ignorar por enquanto.",
          BUTTON: "Continuar",
        },
        UPDATE_AVATAR: {
          TITLE: "Perfil",
          SUBTITLE:
            "É hora de escolher sua foto de perfil! Recomendamos usar sua foto de verdade, pois isso estimulará os visitantes a conversar com você.",
          SKIP: "Ignorar por enquanto.",
          BUTTON: "Continuar",
        },
        TWO_FACTOR_PHONE: {
          TITLE: "Autenticação de Dois Fatores Obrigatória",
          SUBTITLE:
            "Por razões de segurança, este site exige a Autenticação de Dois Fatores. Por tanto, você precisa digitar um número válido de celular.",
          PHONE_LABEL: "Qual é o número de seu celular?",
          PHONE_PLACEHOLDER: "+5511987654321",
          BUTTON: "Continuar",
        },
        TWO_FACTOR_QRCODE: {
          TITLE: "Autenticação de Dois Fatores Obrigatória",
          SUBTITLE:
            "Fotografe este QR code com seu aplicativo de autenticação.",
          BUTTON: "Eu Fotografei o Código",
        },
        TWO_FACTOR_VERIFY: {
          TITLE: "Verificar o Código Fotografado",
          SUBTITLE:
            "Para confirmar a sua instalação, digite abaixo o código de 6 dígitos gerado por seu aplicativo de autenticação.",
          BUTTON: "Verificar",
        },
      },
    },
    INBOX: {
      DEFAULT: {
        ONBOARDING_HEAD_TITLE: "Preparar, treinar, atender!",
        ONBOARDING_HEAD_LABEL_1:
          "Tire o máximo proveito da sua conta Reinvent seguindo estas etapas de configuração.",
        ONBOARDING_HEAD_LABEL_2:
          "Seu trabalho fica mais fácil a cada etapa concluída.",
        ONBOARDING_STEPS: {
          APPS: {
            TITLE: "Obter as aplicações do Reinvent para telemóveis",
            LABEL: "Use o Reinvent em movimento",
          },
          WHATSAPP: {
            TITLE: "Instale a integração com WhatsApp",
            LABEL: "Comunique-se com contatos via WhatsApp",
          },
          TELEGRAM: {
            TITLE: "Receba mensagens do Telegram",
            LABEL: "Envie e receba mensagens por meio do Telegram",
          },
          SHORTCUTS: {
            TITLE: "Crie atalhos para mensagens rápidas",
            LABEL: "Cadastre mensagens rápidas usando atalhos",
          },
          TEAMMATES: {
            TITLE: "Adicione colegas de equipe",
            LABEL: "Envie convites para colaboradores da sua equipe",
          },
          CHATBOX: {
            TITLE: "Configure a janela de chat no seu site",
            LABEL:
              "Envie e receba mensagens em tempo real com pessoas no seu site",
          },
          MESSENGER: {
            TITLE: "Conecte-se ao Facebook Messenger",
            LABEL: "Responda às mensagens enviadas pelo Messenger.",
          },
          EMAIL: {
            TITLE: "Receba os seus e-mails no Reinvent",
            LABEL: "Gerenciar e-mails recebidos no Reinvent",
          },
          SLACK: {
            TITLE: "Conecte-se a sua equipa do Slack",
            LABEL: "Deixe sua equipa do Slack responder do Slack",
          },
          TRIGGERS: {
            TITLE: "Enviar mensagens automáticas",
            LABEL:
              "Automatize o seu marketing com ações automáticas na janela de chat.",
          },
          HELPDESK: {
            TITLE: "Construir sua Central de Ajuda",
            LABEL: "Escreva artigos para ajudar seus usuários.",
          },
          BOT: {
            TITLE: "Construa seu primeiro robô",
            LABEL: "Escreva roteiros para o robô e automatize suas respostas.",
          },
        },
        ONBOARDING_APPS: {
          DOWNLOAD_IOS: "iOS",
          DOWNLOAD_ANDROID: "Android",
        },
        ONBOARDING_TRIAL_DAY: "dia de teste",
        ONBOARDING_TRIAL_DAYS: "dias de teste",
        ONBOARDING_SETUP: "Configurar agora",
        ONBOARDING_ALREADY_CONFIGURED: "Já está configurado!",
        ONBOARDING_HELP: "Precisa de ajuda para configurar algo?",
        ONBOARDING_HIDE: "Ocultar isto",
        POPUPS_HIDE_SETUP_QUESTION: "Ocultar instruções de configuração?",
        POPUPS_HIDE_SETUP_LABEL:
          "As instruções de instalação ficarão ocultas para sempre. Você ainda pode configurar o Reinvent sem essa tela, mas não será guiado pelas etapas recomendadas.",
        POPUPS_HIDE_SETUP_PROCEED: "Ocultar instruções.",
      },
      FLOW: {
        MORE: "Mais",
        DROPDOWN_REMOVE: "Deletar",
        DROPDOWN_COPY: "Copiar conteúdo",
        EVENT_STATE_SET_CLOSED: "Atendimento encerrado",
        EVENT_STATE_SET_ROUTING: "Operador responsável",
        EVENT_STATE_TAG_ADDED: "Uma nova tag foi adicionada",
        EVENT_STATE_TAG_REMOVED: "Uma tag foi removida",
        EVENT_STATE_CALL_ATTEMPT: "Tentativa de ligação",
        EVENT_STATE_CHANGE_TYPE: "Atendimento mudou de estado",
        CONTROLS_AUDIO_TRANSCRIBE: "Transcrever",
        CONTROLS_AUDIO_TRANSCRIBING: "Transcrevendo",
        CONTROLS_AUDIO_TRANSCRIBED_FROM: "Transcrição de audio:",
        CONTROLS_TEXT_PREDICT: "Gerar resposta",
        CONTROLS_TEXT_PREDICT_AGAIN: "Re-gerar",
        CONTROLS_TEXT_PREDICTING: "Gerando",
        CONTROLS_TEXT_PREDICTED: "Gerado por GPT (ainda não enviado):",
      },
      USER_INFO: {
        HANDLE_SESSION: "Assumir atendimento",
        FINALIZE_SESSION: "Encerrar atendimento",
      },
      CONVERSATION: {
        PENDING: "Conversa em automático, aguarde transbordo",
        MARK_RESOLVED: "Conversa pendente de resposta",
        MARK_UNRESOLVED: "Aguardando resposta do usuário",
        SHARE_FILE: "Selecione um arquivo para enviar",
        SHARE_AUDIO_RECORD: "Mantenha pressionado para gravar um áudio",
        SESSION_INACTIVE:
          "Atendimento encerrado - inicie um novo utilizando o botão de ação",
      },
      CONVERSATION_MENU: {
        AUTO: "Robô",
        QUEUED: "Fila",
        MINE: "Atendendo",
        FILTERS: "Filtros",
        FILTER_ITENS: {
          MANUAL: "Em Atendimento",
          MENTION: "Menções",
          RECENT: "Recentes",
          INACTIVE: "Finalizado",
        },
        LIST: {
          CUSTOM: "Novo filtro personalizado",
        },
      },
    },
    SETTINGS: {
      NAVIGATION: {
        SECTIONS: {
          TENANTS_TITLE: "Configurações da Empresa",
          TENANTS_DESCRIPTION: "Equipe, horários e mais",
        },
      },
      TENANTS: {
        SUBTITLE: "Configurações da Empresa",
        DESCRIPTION_PART_1: "Veja as empresas que você faz parte.",
        DESCRIPTION_PART_2:
          'Clique em "Configurações" para alterar detalhes de cada empresa.',
        CHANGE_SETTINGS: "Configurações",
        TITLE: "Configurações da Empresa",
        SUBTITLE_1: "Informações da Empresa",
        DESCRIPTION_1:
          "Configure as informações da sua empresa. Isso é visto por seu time de operadores",
      },
    },
  },
  SIDEBAR: {
    INBOX: "Caixa de Entrada",
    VISITORS: "Visitantes",
    ANALYTICS: "Estatísticas",
    PLUGINS: "Extensões",
    SETTINGS: "Configurações",
    UPGRADE: "Atualizar",
    SETUP: "Configurar",
    SWITCHER: {
      MENU_SUPPORT: "Acessar Ajuda do Reinvent",
      MENU_GO_OFFLINE: "Ativar modo invisível",
      MENU_GO_ONLINE: "Desativar modo invisível",
      MENU_LOGOUT: "Sair da aplicação",
      MENU_CREATE_WEBSITE: "Criar novo site",
      MENU_ALERTS: "Ver alertas",
      MENU_MANAGE_ACCOUNT: "Administrar conta",
      MENU_INVITE_OPERATOR: "Convidar um operador",
    },
    CAMPAIGNS: "Campanhas",
    HELPDESK: "Central de Ajuda",
    CONTACTS: "Contatos",
    SEARCH: "Buscar",
  },
  TITLES: {
    INBOX: "Caixa de Entrada",
    VISITORS: "Visitantes",
    ANALYTICS: "Análises",
    PLUGINS: "Módulos de Extensão",
    SETTINGS: "Configurações",
    CAMPAIGNS: "Campanhas",
    HELPDESK: "Central de Ajuda",
    CONTACTS: "Contatos",
  },
  TOASTS: {
    INBOX_DROP_FILE_SESSION_IS_CLOSED:
      "Você não pode anexar arquivos em um atendimento já encerrado, inicie um novo atendimento.",
    INBOX_MESSSAGE_DELETED_SUCCESS: "A mensagem foi excluída.",
    INBOX_MESSSAGE_DELETED_ERROR:
      "Não foi possível excluir a mensagem - falhou ou não foi autorizado.",
    INBOX_MESSAGE_CONTENT_COPIED:
      "Mensagem copiada para área de transferência.",
    CONTACT_ATTRIBUTES_UPDATED: "O contato desta conversa foi atualizado!",
    INVALID_DATA:
      "Os dados que você enviou são inválidos. Verifique e tente de novo.",
    NOT_ALLOWED: "Você não tem permissão para acessar esta ferramenta.",
    INVALID_SESSION:
      "A sua sessão é inválida. Você não pode acessar esta ferramenta.",
    AUTHENTICATED_ROUTE:
      "Esta ferramenta está disponível apenas para usuários autenticados.",
    RATE_LIMITED:
      "Opa. Parece que você usou esta ferramenta além do limite. Tente mais tarde.",
    TIMED_OUT: "Tempo de acesso expirado. Tente mais tarde.",
    SERVICE_DEGRADED:
      "Esta opção está temporariamente indisponível, porque o Reinvent não pode atender agora. Tente de novo em alguns segundos.",
    PLAN_SUBSCRIPTION_REQUIRED:
      "É necessária uma assinatura de plano para realizar esta ação. Por favor, inscreva-se em um plano.",
    SUBSCRIPTION_UPGRADE_REQUIRED:
      "Esta ferramenta não está disponível para a sua assinatura. Por favor, atualize o seu plano.",
    TOKEN_SCOPE_FORBIDDEN:
      "Seu token de autenticação não tem o escopo necessário para realizar esta ação.",
    TOKEN_FORCED_MEMBER_WEBSITES:
      "A autenticação de dois fatores não pode ser desabilitada, porque pelo menos um dos seus sites ativou a obrigatoriedade.",
    WEBSITE_MEMBER_TOKEN_MISSING:
      "Pelo menos um dos membros do site ainda não habilitou a autenticação de dois fatores. Todos devem configurá-la antes que você ative a obrigatoriedade.",
    WEBSITE_VERIFICATION_REQUIRED:
      "Seu website ainda não foi verificado. Por favor, entre em contato com nosso suporte e solicite ser verificado para poder continuar.",
    CONVERSATION_NOT_FOUND: "Não foi possível encontrar a conversa.",
    SESSION_NOT_FOUND: "Não foi possível encontrar a sessão de conversa.",
    PEOPLE_EMAIL_EXISTS:
      "Existe outro perfil com este e-mail. Use outro e-mail.",
    DOMAIN_TAKEN: "O domínio já existe. Tente outro.",
    DOMAIN_REUSED:
      "Você não pode reutilizar um domínio já configurado para outro serviço Reinvent (por exemplo, e-mail, helpdesk ou status). Tente outro subdomínio.",
    CUSTOM_DOMAIN_IP_REQUIRED:
      "Existe um IP dedicado para e-mails. Remova-o antes de desativar o seu domínio personalizado.",
    CAMPAIGN_RECIPIENTS_NONE:
      "Você não inseriu nenhum destinatário na campanha, ou não pudemos verificar a quantidade. Verifique a configuração da sua campanha.",
    CAMPAIGN_RECIPIENTS_UNFILTERABLE:
      "A sua campanha tem um filtro de destinatários muito complexo para processar, pois você possui muitos contatos. Tente simplificá-lo.",
    CAMPAIGN_RECIPIENTS_LIMITED:
      "Você inseriu destinatários demais. Atualize o seu plano, verifique se não está usando uma conta de teste ou adquira um IP dedicado para e-mails.",
    CAMPAIGN_SLOT_UNAVAILABLE:
      "A campanha não pode ser enviada agora, porque ainda há outra em andamento.",
    CAMPAIGN_LOCKED:
      "Esta campanha foi enviada. Ela não pode mais ser editada.",
    CAMPAIGN_NAME_EXISTS:
      "Este nome de campanha já é usado em outra campanha. Por favor, introduza um nome diferente.",
    CAMPAIGN_FORMAT_INCOMPATIBLE:
      "O formato da sua campanha é incompatível com o canal de entrega (ex. HTML não funciona com a janela de chat).",
    CAMPAIGN_HTML_INVALID_CODE:
      "A sua campanha tem algum código HTML inválido. As tags HTML e body são obrigatórias.",
    CAMPAIGN_HTML_NO_UNSUBSCRIBE:
      "A sua campanha HTML não possui um URL de cancelamento de subscrição. Por favor, adicione um. (Isto é um requisito legal)",
    TEMPLATE_EXISTS: "Já existe um modelo com este nome.",
    TEMPLATE_NAME_EXISTS:
      "Este nome de modelo já é usado em outro modelo. Por favor, introduza um nome diferente.",
    TEMPLATE_NOT_WRITABLE:
      "Os modelos fornecidos por Reinvent não podem ser editados ou removidos. Apenas os seus próprios templates podem ser alterados.",
    TEMPLATE_HTML_INVALID_CODE:
      "O seu template HTML tem um código inválido. Ele deve conter uma tag HTML e uma body tag.",
    TEMPLATE_HTML_NO_UNSUBSCRIBE:
      "Seu modelo HTML não tem uma URL para cancelar a inscrição. Favor adicioná-lo (a lei exige isso).",
    SHORTCUT_EXISTS: "O atalho que você quer criar já existe. Troque-o.",
    ROOT_DOMAIN_CONFLICT:
      "Por favor use um subdomínio (ex. emails.empresa.com e não empresa.com).",
    WEBSITE_MEMBERSHIP_LEFT:
      "Você ainda é membro de um site. Por favor, saia de todos os seus sites.",
    PHONE_REQUIRED:
      "É necessário um número de telefone para a Autenticação de Dois Fatores. Por favor, defina um.",
    BILLING_CARD_LEFT:
      "Você ainda possui cartões de pagamento registrados. Por favor, remova-os.",
    BILLING_OWNER:
      "Este operador é o responsável pelos pagamentos. Por favor, conecte o método de pagamento à conta de outro operador e tente novamente.",
    NO_PAYMENT_CARD:
      "Você não salvou nenhum método de pagamento. Como isto é uma funcionalidade paga, é necessário informar um método de pagamento nas configurações de cobrança.",
    QUOTA_LIMIT_EXCEEDED:
      "A sua cota para esta ação foi excedida, seja para o site ou para a sua conta. Tente novamente mais tarde, esta cota pode ser redefinida em breve.",
    REMOVAL_NOT_PERMITTED:
      "Você não tem permissão para remover isto, como um operador não-proprietário.",
    ORIGINAL_VANISHED:
      "O original que você está procurando expirou, pois era muito antigo. Removemos automaticamente os originais após algum tempo.",
    PASSWORD_UNVERIFIED:
      "A senha digitada não pode ser verificada. Por favor, tente novamente.",
    INBOX_CONVERSATION_CREATED: "A conversa foi criada.",
    INBOX_CONVERSATION_REMOVED: "A conversa foi removida.",
    INBOX_CUSTOM_FILTER_SAVED: "O filtro personalizado foi salvo.",
    INBOX_CUSTOM_FILTER_REMOVED: "O filtro personalizado foi removido.",
    INBOX_CONVERSATION_NICKNAME_SAVED: "O nome do contato foi salvo.",
    INBOX_CONVERSATION_EMAIL_SAVED: "O e-mail do contato foi salvo.",
    INBOX_CONVERSATION_PHONE_SAVED: "O telefone da conversa foi salvo.",
    INBOX_CONVERSATION_SEGMENT_ADDED: "Tag adicionada.",
    INBOX_CONVERSATION_SEGMENT_REMOVED: "Tag removida.",
    INBOX_CONVERSATION_PARTICIPANT_ADDED:
      "O participante foi adicionado à conversa. Eles começarão a receber mensagens de conversas.",
    INBOX_CONVERSATION_PARTICIPANT_REMOVED:
      "O participante foi removido da conversa. Eles não receberão mais mensagens.",
    INBOX_CONVERSATION_PARTICIPANT_ADD_FAIL_UPGRADE:
      "Não foi possível adicionar o participante porque já há muitos participantes na conversa. Você pode atualizar seu plano para adicionar mais.",
    INBOX_CONVERSATION_TRANSCRIPT_EMAILED:
      "A transcrição foi enviada por e-mail.",
    INBOX_CONVERSATION_CHATBOX_CLEAR_SESSION_REQUESTED:
      "A sessão da janela de chat do visitante foi limpa.",
    INBOX_CONVERSATION_SHORTCUT_CREATED: "O atalho foi criado.",
    SETTINGS_ACCOUNT_SAVED: "As configurações da conta foram salvas.",
    SETTINGS_ACCOUNT_TOKEN_ENABLE_FAIL:
      "A Autenticação de Dois Fatores falhou a ser ativada. Verifique o token que você forneceu e verifique se um número de telefone está configurado.",
    SETTINGS_ACCOUNT_TOKEN_ENABLED:
      "A Autenticação de Dois Fatores foi ativada.",
    SETTINGS_ACCOUNT_TOKEN_DISABLED:
      "A Autenticação de Dois Fatores foi desativada. ",
    SETTINGS_ACCOUNT_TOKEN_SECRET_COPIED:
      "A chave privada foi copiada para a sua área de transferência.",
    SETTINGS_ACCOUNT_REMOVED:
      "A sua conta Reinvent foi excluída. Você será desconectado dentro de alguns momentos…",
    SETTINGS_ACCOUNT_EMAIL_DONT_MATCH:
      "O e-mail não está correto. Por favor tente de novo.",
    SETTINGS_ACCOUNT_SESSION_CLOSED:
      "A sessão selecionada foi fechada. A aplicação conectada não tem mais acesso à sua conta.",
    SETTINGS_AVAILABILITY_SAVED:
      "As configurações de disponibilidade foram salvas.",
    SETTINGS_BILLING_DEFAULT_METHOD_REMOVED:
      "O método de pagamento foi removido.",
    SETTINGS_NOTIFICATIONS_SAVED:
      "As configurações de notificação foram salvas.",
    SETTINGS_PLANS_SUBSCRIBED: "O site foi subscrito ao plano.",
    SETTINGS_COUPON_NOT_APPLIED:
      "Não foi possível aplicar o cupão. Ele ainda está válido?",
    SETTINGS_COUPON_APPLIED: "O cupão foi aplicado.",
    SETTINGS_SHORTCUTS_ADDED: "O atalho foi adicionado.",
    SETTINGS_SHORTCUTS_DELETED: "O atalho foi excluído.",
    SETTINGS_SHORTCUTS_UPDATED: "O atalho foi atualizado.",
    SETTINGS_WEBSITES_WEBSITE_CREATED: "O site foi criado.",
    SETTINGS_WEBSITE_SETUP_IDENTIFIER_COPIED:
      "O identificador do site foi copiado para a área de transferência.",
    SETTINGS_WEBSITE_OPERATOR_ROLE_CHANGED:
      "A função do operador foi alterada.",
    SETTINGS_WEBSITE_OPERATOR_EDITED: "A associação do operador foi editada.",
    SETTINGS_WEBSITE_OPERATOR_REMOVED: "O operador foi removido.",
    SETTINGS_WEBSITE_OPERATOR_LIST_EMPTIED:
      "A lista dos últimos operadores foi esvaziada.",
    SETTINGS_WEBSITE_VISITORS_BLOCKED_CLEARED:
      "Todas as regras de visitantes bloqueados foram limpas.",
    SETTINGS_WEBSITE_INVITE_SENT:
      "O convite foi enviado para o e-mail do operador.",
    SETTINGS_WEBSITE_PAYMENT_METHOD_LINKED:
      "O método de pagamento foi associado.",
    SETTINGS_WEBSITE_PAYMENT_METHOD_UNLINKED:
      "O método de pagamento foi dissociado.",
    SETTINGS_WEBSITE_REMOVED: "O site foi removido.",
    SETTINGS_WEBSITE_DOMAIN_DONT_MATCH:
      "O domínio do site está incorreto. Por favor, tente novamente.",
    SETTINGS_WEBSITE_SAVED: "As configurações da empresa foram salvas.",
    POPUPS_ADD_CARD_ADDED: "O método do pagamento foi adicionado.",
    POPUPS_ADD_CARD_INVALID:
      "O método de pagamento não foi validado pela rede do cartão, verifique se há dados incorretos (ex. CVV inválido)",
    APP_INVISIBLE_MODE_ENABLED:
      "O modo invisível foi ativado. Agora você é visto como desligado, até desativar o modo invisível.",
    APP_INVISIBLE_MODE_DISABLED:
      "O modo invisível foi desativado. O seu horário de disponibilidade regular está agora a ser usado.",
    RUNTIME_UNEXPECTED_ERROR:
      "Ocorreu um erro inesperado no servidor. Está conectado a uma rede?",
    FACTORY_UPLOAD_TOO_LARGE:
      "Arquivo muito grande. Reduza o tamanho e tente novamente.",
    FACTORY_UPLOAD_POLICY:
      "A extensão do arquivo não é permitida por razões de segurança. A lista de formatos de arquivo permitidos está disponível [aqui](https://help.talqui.chat/en/article/ubuxcu/).",
    FACTORY_UPLOAD_ERROR:
      "Ocorreu um erro ao carregar o arquivo. O arquivo é muito grande?",
    FACTORY_DATATABLE_ITEM_DELETED: "Os itens selecidados foram excluídos.",
    FACTORY_DATATABLE_ITEM_NOT_DELETED:
      "Os itens selecionados não poderiam ter sido excluídos. Por favor, tente novamente.",
    FACTORY_DATATABLE_ITEM_DUPLICATED: "Os itens selecionados foram duplicados",
    PLUGINS_DEFAULT_INSTALLED: "A extensão foi instalada com sucesso.",
    PLUGINS_DEFAULT_UNINSTALLED:
      "A extensão foi desinstalada. Agora está desativada no site.",
    PLUGINS_SETTINGS_SAVED: "As configurações da extensão foram salvas.",
    INBOX_DEFAULT_SETUP_HIDDEN:
      "As instruções de configuração foram ocultadas para sempre.",
    INBOX_CONVERSATION_DATA_ADDED: "O ponto de dados foi adicionado.",
    INBOX_CONVERSATION_DATA_REMOVED: "O ponto de dados foi removido.",
    INBOX_CONVERSATION_DATA_FORGET_SUGGEST_FAIL:
      "Falha em esquecer a sugestão da chave de dados. Tente novamente.",
    INBOX_CONVERSATION_NOTEPAD_SAVED: "O bloco de notas foi guardado.",
    WEBSITE_MEMBER_ROLE_NOT_ALLOWED:
      "A sua função de membro do site não tem poderes para essa ferramenta. Talvez você não seja o proprietário do site?",
    SETTINGS_BILL_PERIOD_UPDATED: "O período da fatura foi atualizado",
    SETTINGS_BILL_PERIOD_MISMATCH_ERROR: "A sua resposta está incorreta",
    INBOX_ORIGINAL_VIEWER_LOAD_FAILED:
      "Não foi possível carregar o original. Por favor tente novamente.",
    INBOX_ORIGINAL_VIEWER_FORMAT_UNSUPPORTED:
      "O formato original ainda não é compatível, por isso não foi possível abrir o arquivo.",
    INBOX_CONVERSATION_BATCH_RESOLVED: "Todas as conversas foram resolvidas",
    INBOX_CONVERSATION_BATCH_READ:
      "Todas as conversas foram marcadas como lidas.",
    INBOX_CONVERSATION_BLOCKED: "O usuário desta conversa foi bloqueado.",
    INBOX_CONVERSATION_TRANSCRIBE_ERROR:
      "Não foi possível transcrever a sua mensagem.",
    INBOX_CONVERSATION_UNBLOCKED: "O usuário desta conversa foi desbloqueado.",
    SETTINGS_WEBSITE_CUSTOM_EMAIL_DOMAIN_CHECKING:
      "Os registros de DNS do domínio estão sendo verificados. Isto levará alguns segundos.",
    SETTINGS_WEBSITE_CUSTOM_EMAIL_DOMAIN_CHANGED:
      "O domínio do e-mail foi alterado. Agora as mensagens serão enviados a partir deste domínio.",
    SETTINGS_WEBSITE_CUSTOM_EMAIL_DOMAIN_NOT_CHANGED:
      "O domínio do e-mail não foi atualizado. Os registros DNS mostrados são os que estão em falta. Atualize-os e aguarde algumas horas para a propagação do DNS.",
    SETTINGS_WEBSITE_EMAIL_SMTP_CHECKING:
      "As credenciais SMTP personalizadas estão sendo verificadas. Isto levará alguns segundos.",
    SETTINGS_WEBSITE_EMAIL_SMTP_LOCKED:
      "As configurações SMTP personalizadas só podem ser definidas se você tiver um domínio de email personalizado ativo.",
    SETTINGS_WEBSITE_EMAIL_SMTP_CHANGED:
      "As configurações SMTP personalizadas foram alteradas com sucesso.",
    SETTINGS_WEBSITE_EMAIL_SMTP_NOT_CHANGED:
      "As configurações SMTP personalizadas não puderam ser atualizadas. Verifique se as credenciais fornecidas são válidas.",
    SETTINGS_WEBSITE_VERIFY_KEY_ROLLING:
      "A sua chave secreta de verificação está sendo criada. Isso levará alguns segundos.",
    SETTINGS_WEBSITE_VERIFY_KEY_ROLLED:
      "A sua chave secreta de verificação foi criada. Atualize seu back-end com essa nova chave.",
    SETTINGS_WEBSITE_VERIFY_KEY_NOT_ROLLED:
      "A sua chave secreta de verificação não foi criada ainda. Está agora na fila de processamento.",
    SETTINGS_WEBSITE_HOOK_ADDED: "O gancho foi adicionado.",
    SETTINGS_WEBSITE_HOOK_EDITED: "O gancho foi editado.",
    SETTINGS_WEBSITE_HOOK_DELETED: "O gancho foi removido.",
    SETTINGS_WEBSITE_CONTRACT_AGREED: "O contrato foi confirmado e salvo.",
    SETTINGS_WEBSITE_CONTRACT_CANCELED: "O contrato foi cancelado.",
    SETTINGS_WEBSITE_EMAIL_IPS_ASSIGNED:
      "O IP de e-mail foi vinculado ao seu site.",
    SETTINGS_WEBSITE_EMAIL_IPS_UNASSIGNED:
      "O IP de e-mail foi desvinculado de seu site.",
    CONTACTS_GENERIC_CONTACT_CREATED: "O contato foi criado.",
    CONTACTS_INDEX_PROFILES_EXPORTING:
      "Os perfis dos seus contatos estão sendo exportados.",
    CONTACTS_INDEX_SEARCH_DESTROYED:
      "Os contatos selecionados foram removidos.",
    CONTACTS_PROFILE_SAVED: "O perfil foi salvo.",
    CONTACTS_PROFILE_SEGMENT_ADDED: "O segmento foi adicionado.",
    CONTACTS_PROFILE_SEGMENT_REMOVED: "O segmento foi removido.",
    CONTACTS_PROFILE_SEGMENT_FORGET_SUGGEST_FAIL:
      "Falha em esquecer a sugestão do segmento. Tente novamente.",
    CONTACTS_PROFILE_DATA_ADDED: "A informação foi adicionada.",
    CONTACTS_PROFILE_DATA_REMOVED: "A informação foi removida.",
    CONTACTS_PROFILE_DATA_FORGET_SUGGEST_FAIL:
      "Falha em esquecer a sugestão da chave de dados. Tente novamente.",
    CONTACTS_PROFILE_NOTEPAD_SAVED: "O bloco de notas foi guardado.",
    CONTACTS_PROFILE_SUBSCRIPTION_EMAIL_ENABLED:
      "A inscrição de e-mail foi ativada.",
    CONTACTS_PROFILE_SUBSCRIPTION_EMAIL_DISABLED:
      "A assinatura de email foi desativada.",
    CAMPAIGNS_CREATED: "A campanha foi criada com sucesso.",
    CAMPAIGNS_MODEL_CREATED: "O Modelo de campanha foi criado com sucesso.",
    CAMPAIGNS_PAUSED:
      "A campanha foi pausada. É possível retomá-la quando desejar.",
    CAMPAIGNS_DEACTIVATED: "A campanha foi desativada.",
    CAMPAIGNS_RESUMED: "A campanha foi reativada.",
    CAMPAIGNS_ACTIVATED: "A campanha foi ativada.",
    CAMPAIGNS_SAVED: "A campanha foi salva.",
    CAMPAIGNS_TESTED:
      "Uma mensagem de teste da campanha foi enviada para seu e-mail.",
    CAMPAIGNS_DISPATCHED:
      "A campanha foi programada e logo começará a ser executada.",
    CAMPAIGNS_TEMPLATES_CREATED: "O modelo da campanha foi criado.",
    CAMPAIGNS_TEMPLATES_CREATE_DONE: "O modelo da campanha foi salvo.",
    CAMPAIGNS_TEMPLATES_CREATE_NO_CONTENT:
      "O modelo da campanha não tem nenhum conteúdo para salvar. Por favor, volte ao editor.",
    CAMPAIGNS_TEMPLATES_INSERT_DONE:
      "O modelo foi inserido na sua campanha atual.",
    CAMPAIGNS_TEMPLATES_UPDATE_DONE: "O modelo da campanha foi atualizado.",
    CAMPAIGNS_TEMPLATES_UPDATE_NO_CONTENT:
      "O modelo da campanha não possui conteúdo a ser atualizado. Por favor, volte para o editor.",
    POPUPS_IMPORT_CONTACTS_BUSY_INGESTING:
      "Outra importação de contatos ainda está em andamento. Tente novamente depois que terminar a importação.",
    POPUPS_IMPORT_CONTACTS_QUOTA_LIMIT:
      "Este site ultrapassou o limite de importação de contatos. Aguarde pelo menos uma semana antes de tentar novamente.",
    POPUPS_IMPORT_CONTACTS_ERROR:
      "Ocorreu um erro na importação de contatos, tente novamente.",
    POPUPS_IMPORT_CONTACTS_INVALID_MAPPING:
      "Para configurar a importação, mapeie pelo menos o e-mail e o nome completo de um usuário.",
    POPUPS_IMPORT_CONTACTS_INVALID_SEPARATOR:
      "Para configurar a importação, indique o separador de colunas.",
    POPUPS_IMPORT_CONTACTS_INVALID_DATA_KEY:
      "A chave de dados personalizada é inválida. Não use caracteres especiais ou espaços.",
    POPUPS_IMPORT_CONTACTS_DATA_KEY_ADDED:
      "A chave de dados personalizada foi adicionada e agora pode ser usada.",
    POPUPS_IMPORT_HELPDESK_IS_IMPORTING:
      "A sua Central de Ajuda está sendo importada.",
    POPUPS_IMPORT_HELPDESK_QUOTA_LIMIT:
      "Este site ultrapassou o limite de importação da Central de Ajuda. Aguarde pelo menos uma semana antes de tentar novamente.",
    POPUPS_IMPORT_HELPDESK_ERROR:
      "Ocorreu um erro na importação da Central de Ajuda, tente novamente.",
    INBOX_CONVERSATION_MESSAGE_EDIT_NONE:
      "Nenhuma mensagem pode ser editada. Provavelmente, a última mensagem já foi enviada ou o destinatário está desconectado.",
    INBOX_CONVERSATION_MESSAGE_EDITED: "A mensagem foi editada.",
    INBOX_CONVERSATION_REMINDER_INVALID_DATE:
      "A data está num formato inválido. Por favor, use o formato correto.",
    INBOX_CONVERSATION_REMINDER_NO_NOTE:
      "Você não escreveu nenhuma observação no lembrete. Por favor, escreva algo após a data.",
    INBOX_CONVERSATION_REMINDER_SCHEDULED:
      "O lembrete foi agendado. Você será lembrado na hora.",
    INBOX_CONVERSATION_TRANSLATE_LIMIT:
      "O LiveTranslate não pode traduzir esta mensagem. É possível que seja muito longa.",
    SETTINGS_HELPDESK_DOMAIN_CUSTOM_CHECKING:
      "Os registos DNS do domínio estão a ser verificados. Isto levará alguns segundos.",
    SETTINGS_HELPDESK_DOMAIN_CUSTOM_CHANGED:
      "O domínio da Central de Ajuda foi alterado. Aguarde alguns minutos para que o certificado SSL seja publicado.",
    SETTINGS_HELPDESK_DOMAIN_CUSTOM_NOT_CHANGED:
      "O domínio da Central de Ajuda não foi atualizado. Os registros DNS não estão respondendo. Faça a correção e aguarde algumas horas para a propagação do DNS.",
    SETTINGS_HELPDESK_DOMAIN_SAVED:
      "As alterações no domínio da Central de Ajuda foram salvas. Aguarde alguns minutos para que o certificado SSL seja publicado.",
    SETTINGS_HELPDESK_SETTINGS_SAVED:
      "As configurações da Central de Ajuda foram salvas. Aguarde alguns minutos para que as alterações sejam publicadas.",
    SETTINGS_HELPDESK_INITIALIZED:
      "A Central de Ajuda foi ativada e agora está no ar.",
    SETTINGS_STATUS_DOMAIN_CUSTOM_CHECKING:
      "Os registros DNS do domínio estão sendo verificados. Aguarde alguns segundos.",
    SETTINGS_STATUS_DOMAIN_CUSTOM_CHANGED:
      "O domínio da Página de Status foi alterado. Aguarde alguns minutos para que o certificado SSL seja publicado.",
    SETTINGS_STATUS_DOMAIN_CUSTOM_NOT_CHANGED:
      "O domínio da Página de Status não foi atualizado. Os registros DNS não estão respondendo. Faça a correção e aguarde algumas horas para a propagação do DNS.",
    SETTINGS_STATUS_DOMAIN_SAVED:
      "O domínio da Página de Status foi alterado. Aguarde alguns minutos para que o certificado SSL seja publicado.",
    SETTINGS_STATUS_SETTINGS_SAVED:
      "As configurações da Página de Status foram salvas. Aguarde alguns minutos para que as alterações sejam publicadas.",
    SETTINGS_STATUS_INITIALIZED:
      "A Página de Status foi ativada e agora está no ar.",
    SETTINGS_STATUS_SERVICE_ADDED: "O serviço foi adicionado.",
    SETTINGS_STATUS_SERVICE_EDITED: "O serviço foi editado.",
    SETTINGS_STATUS_SERVICE_REMOVED: "O serviço foi removido.",
    SETTINGS_STATUS_SERVICE_NODE_ADDED:
      "O nó foi adicionado. Agora você pode configurá-lo.",
    SETTINGS_STATUS_SERVICE_NODE_EDITED: "O nó foi editado.",
    SETTINGS_STATUS_SERVICE_NODE_REMOVED: "O nó foi removido.",
    SETTINGS_STATUS_SERVICE_NODE_REPLICAS_PURGED:
      "As réplicas do nó foram limpas.",
    SETTINGS_STATUS_SERVICE_NODE_REPLICA_TCP_PORT_MISSING:
      'Defina a porta para as URLs de réplica de TCP (o formato deve ser: "{"pattern"}").',
    SETTINGS_STATUS_SERVICE_NODE_REPLICA_INVALID_FORMAT:
      "Alguma URL de réplica está incorreta (HTTP, ICMP ou TCP).",
    SETTINGS_STATUS_SERVICE_NODE_HTTP_INCOMPLETE:
      "As configurações de HTTP estão incompletas. Você deve preencher ambos os status HTTP, ou então nenhum deles.",
    SETTINGS_STATUS_ANNOUNCEMENT_EXPIRE_INVALID:
      "A data de término é inválida.",
    SETTINGS_STATUS_ANNOUNCEMENT_SAVED:
      "O aviso foi salvo. Agora está visível na Página de Status.",
    SETTINGS_STATUS_ANNOUNCEMENT_REMOVED:
      "O aviso foi removido da Página de Status.",
    SETTINGS_STATUS_REPORTER_TOKEN_ROLLED:
      "A sua chave de token de relatórios foi gerada. Não esqueça de atualizar os nós com essa nova chave.",
    HELPDESK_LOCALE_ADDED: "O idioma foi adicionado à Central de Ajuda.",
    HELPDESK_LOCALE_DESTROYED: "O idioma foi removido da Central de Ajuda.",
    HELPDESK_LOCALE_DONT_MATCH: "O idioma não foi encontrado. Tente novamente.",
    HELPDESK_ARTICLES_EXPORTING:
      "Os artigos da Central de Ajuda estão sendo exportados.",
    HELPDESK_ARTICLE_ADDED: "O artigo foi criado na Central de Ajuda.",
    HELPDESK_CATEGORY_ADDED: "A categoria foi criada na Central de Ajuda.",
    HELPDESK_CATEGORY_EDITED: "As alterações na categoria foram salvas.",
    HELPDESK_SECTION_EDITED:
      "As mudanças de seção foram salvas para a categoria.",
    HELPDESK_SECTION_ADDED: "A seção foi criada em seu helpdesk na categoria.",
    HELPDESK_ARTICLE_CATEGORY_CHANGED: "A categoria do artigo foi alterada.",
    HELPDESK_ARTICLE_PUBLISHED:
      "O artigo foi publicado. Agora, está disponível na Central de Ajuda.",
    HELPDESK_ARTICLE_REPUBLISHED:
      "O artigo foi republicado. Agora, está visível novamente.",
    HELPDESK_ARTICLE_UNPUBLISHED:
      "O artigo foi despublicado. Agora, não está mais visível na Central de Ajuda.",
    HELPDESK_ARTICLE_SAVED: "As alterações no artigo foram salvas.",
    HELPDESK_ARTICLE_ALTERNATE_LOCALES_SAVED:
      "Os idiomas alternativos das postagens foram salvos.",
    ANALYTICS_GENERATE_REPORT_GENERATED:
      "Seu relatório de análise foi gerado e agora será feito o download.",
    ANALYTICS_GENERATE_REPORT_NO_METRIC:
      "Não havia dados métricos para gerar o relatório.",
    INBOX_CONVERSATION_ASSIGNED:
      "O usuário designado passará a receber notificações da conversa.",
    INBOX_CONVERSATION_UNASSIGNED:
      "Ninguém foi designado para a conversa. Todos os usuários receberão notificações.",
    SIMULATED_NETWORK_ERROR:
      "Ocorreu um erro de simulação de redes, que é usado para testes de resiliência de falha aleatória. Verifique se a aplicação lidou com isso corretamente.",
    CONFIG_LOCAL_TOKENS_MISSING:
      "Não é possível continuar a ação solicitada, porque falta uma configuração de token local (injetada no momento da construção). Verifique a documentação da aplicação.",
    INVALID_CARD_NUMBER: "O número do cartão é inválido. Por favor, verifique.",
    INVALID_EXPIRATION_DATE:
      "Verifique se a data de validade está correta. Pode ser que o cartão tenha expirado ou que os números estejam invertidos.",
    INVALID_SECURITY_CODE:
      "O código de segurança do cartão é inválido. Por favor, verifique.",
    BILLING_EXISTS:
      "Este método de pagamento já existe na sua conta. Já tentou removê-lo e adicioná-lo novamente?",
    DIRECTIVES_SELECT_COPIED:
      "O texto selecionado foi copiado para a sua área de transferência.",
    DIRECTIVES_AUDIO_ERROR:
      "Esta faixa de áudio não pode ser reproduzida no momento. Ela pode ter expirado.",
    POPUPS_ADD_CARD_UNAUTHORIZED:
      "O método de pagamento não foi autorizado a ser usado na Reinvent. Tente novamente.",
    SPOTLIGHT_FORGET_HISTORY_FAIL:
      "Falha ao esquecer a entrada do histórico. Tente novamente.",
    CALL_ERROR:
      "Erro ao iniciar a ligação. Verifique seu microfone e sua conexão à internet.",
    CALL_STOPPED: "A ligação foi encerrada.",
    CALL_REJECTED: "A ligação foi rejeitada pelo usuário.",
    ALERT_FEED_DISCARDED_ENTRY_FAILED:
      "Ups, não foi possível descartar essa entrada de alerta. Pode tentar novamente?",
    ALERT_FEED_DISCARDED_ALL: "Todos os alertas do feed foram descartados.",
    INITIATE_LOGIN_INVALID_CREDENTIALS:
      "As suas credenciais são inválidas. Por favor tente de novo.",
    INITIATE_LOGIN_SERVICE_UNAVAILABLE:
      "O Reinvent está fora do ar. Tente mais tarde.",
    INITIATE_REGISTER_ACCOUNT_EXISTS:
      "Ups. Já existe uma conta para esse e-mail.",
    INITIATE_REGISTER_ACCOUNT_UNAVAILABLE: "Ups. Ocorreu um problema.",
    INITIATE_REGISTER_ACCOUNT_EMAIL_NOT_VALID: "Ups. Este e-mail não é válido.",
    INITIATE_REGISTER_ACCOUNT_OVER_QUOTA:
      "Opa. Parece que você criou muitas contas nesta rede.",
    INITIATE_REGISTER_WEBSITE_UNAVAILABLE: "Opa. Não pudemos criar o seu site.",
    INITIATE_REGISTER_WEBSITE_OVER_QUOTA:
      "Opa. Parece que você criou muitos sites desta rede.",
    INITIATE_REGISTER_WEBSITE_DOMAIN_NOT_VALID:
      "Opa. Este domínio de site não é válido.",
    INITIATE_RECOVER_SENT:
      "Email de recuperação enviado. Verifique sua caixa de entrada.",
    INITIATE_RECOVER_CONFIRM_SUCCESS:
      "Alteração de senha realizada. Faça login agora mesmo.",
    INITIATE_RECOVER_REFUSED:
      "Alteração de senha não autorizada - este link não é mais válido. Solicite uma nova recuperação.",
    INITIATE_RECOVER_NOT_FOUND:
      "Não foi possível encontrar seu email. Cometeu um erro de digitação?",
    INITIATE_OPERATORS_INVALID_PHONE:
      "Esse número de telefone é inválido. Tente de novo.",
    INITIATE_OPERATORS_SERVICE_UNAVAILABLE:
      "O Reinvent está fora do ar. Tente mais tarde.",
    INBOX_CONVERSATION_SEGMENT_DUPLICATED:
      "Tag já cadastrada para este atendimento",
    CONTACTS_PROFILE_ERROR: "Este contato não pode ser mostrado. Foi removido?",
    POPUPS_EDITOR_INSERT_REPLACEMENT_FALLBACK_INVALID:
      "O valor substituto inserido é inválido. Provavelmente contém caracteres especiais proibidos.",
    HELPDESK_ARTICLE_NOT_PUBLISHED:
      "Você não pode adicionar este artigo, porque ainda não foi publicado.",
    SETTINGS_BILLING_DEFAULT_METHOD_EDITED: "O pagamento foi editado",
    FILTER_IS_TOO_COMPLEX:
      "Seu filtro é muito complexo para buscar no tempo. Tente excluir elementos.",
    UNITS_TOTAL_BELOW_USAGE:
      "Você solicitou muito poucas unidades, pois seu uso atual está bem acima disso. Por favor, aumente a quantidade de unidades.",
    UNITS_TOTAL_TOO_LARGE:
      "Você já solicitou muitas unidades. Por favor, diminua a quantidade de unidades.",
    TOO_MANY_MESSAGES:
      "Demasiadas mensagens foram enviadas nesta conversa. Por favor, crie uma nova conversa com o usuário.",
    TOO_MANY_NODES:
      "Você atingiu o limite da quantidade de nós que você pode monitorar. Você pode aumentar este limite a qualquer momento em suas configurações website.",
    TOO_MANY_PEOPLE:
      "Você atingiu o limite da quantidade de perfis que você pode armazenar em seu CRM. Você pode aumentar este limite a qualquer momento em suas configurações website.",
    POPUPS_SET_REMINDER_DATE_TIME_DATE_INVALID:
      "O lembrete de data definido é inválido.",
    WIDGET_ACTION_SUCCESS: "A acção do widget foi bem sucedida.",
    WIDGET_ACTION_ERROR:
      "Oops, a acção do widget falhou. Por favor tente novamente, ou entre em contato com o desenvolvedor deste widget.",
    POPUPS_MANAGE_LIMIT_ACTIVATED: "Seu novo limite foi ativado com sucesso.",
    POPUPS_MANAGE_LIMIT_NO_BILLING_CARD:
      "Nenhuma forma de pagamento está configurada em seu website. Por favor, configure um para aumentar os limites.",
  },
  TOOLTIPS: {
    INBOX_CONVERSATION_VIEWING: "está visível.",
    INBOX_CONVERSATION_COMPOSING: "está compondo.",
    NO_COUNTRY: "País desconhecido",
    IS_ONLINE: "está online",
    IS_AWAY: "está ausente",
    REMOVE: "Remover",
    PRIMARY: "Primário",
    SECONDARY: "Secundário",
    INBOX_MESSAGE_COMPOSING_PREVIEW: "está compondo esta mensagem",
    INBOX_MESSAGE_COMPOSING_STANDARD: "está compondo (MagicType indisponível)",
    INBOX_CONVERSATION_MENTIONED: "Você foi mencionado lá.",
    INBOX_CONVERSATION_LOCALE_TOGGLE: "Alternar idioma",
    INBOX_CONVERSATION_ADD_EMOTE: "Inserir um emoji",
    INBOX_CONVERSATION_SHARE: "Partilhar…",
    INBOX_USERINFO_DATA_ORIGIN_MAIN: "Dados do perfil fornecidos pelo EnrichTM",
    INBOX_USERINFO_DATA_ORIGIN_SUB: "Dados sugeridos (podem não ser precisos)",
    INBOX_USERINFO_EMAIL_VERIFIED: "E-mail do contato foi verificado",
    INBOX_USERINFO_EMAIL_UNVERIFIED: "E-mail do contato não foi verificado",
    INBOX_USERINFO_DEVICE_SYSTEM: "Navegador e sistema dos visitantes",
    INBOX_USERINFO_DEVICE_IP: "Endereço IP do visitante",
    INBOX_USERINFO_LOCATION_CITY: "Cidade e país do visitante",
    INBOX_USERINFO_LOCATION_TIME: "Hora local do visitante",
    INBOX_USERINFO_PAGE_LAST_REFERRER: "Referenciador da página atual navegada",
    INBOX_USERINFO_PARTICIPANTS_MASTER: "Dono da conversa",
    INBOX_USERINFO_PARTICIPANTS_TYPE_DEFAULT: "Participante adicional",
    INBOX_USERINFO_PARTICIPANTS_TYPE_EMAIL: "Participante de email",
    INBOX_WIDGET_EXPAND: "Expandir",
    SETTINGS_WEBSITE_OPERATORS_EMPTY_LAST_ACTIVE_MAIN:
      "Limpar a lista dos últimos operadores ativos na janela de chat",
    SETTINGS_WEBSITE_OPERATORS_EMPTY_LAST_ACTIVE_SUB:
      "Uma nova lista será criada quando os operadores enviarem respostas",
    SETTINGS_WEBSITE_OPERATORS_ADD_OPERATOR_MAIN:
      "Convide um novo operador para participar deste site",
    SETTINGS_WEBSITE_OPERATORS_ADD_OPERATOR_SUB:
      "Será enviado um e-mail de convite para participar deste site",
    SETTINGS_WEBSITE_OPERATORS_HAS_TOKEN:
      "A Autenticação de Dois Fatores está ativada.",
    SETTINGS_WEBSITE_OPERATORS_HASNT_TOKEN:
      "A Autenticação de Dois Fatores está desativada",
    SETTINGS_SHORTCUTS_DELETE: "Excluir atalho",
    INBOX_USERINFO_CALL_UNAVAILABLE:
      "Chamadas não suportadas (HTTPS necessário)",
    INBOX_USERINFO_CALL_VIDEO: "Iniciar uma vídeo chamada",
    INBOX_USERINFO_CALL_AUDIO: "Iniciar uma chamada apenas de áudio",
    PLUGINS_ITEM_INSTALLED: "A extensão foi instalada",
    SETTINGS_WEBSITE_HOOKS_STATUS_INACTIVE: "Este gancho ainda não foi chamado",
    SETTINGS_WEBSITE_HOOKS_STATUS_ACTIVE:
      "A última chamada para o servidor do gancho foi bem-sucedida",
    SETTINGS_WEBSITE_HOOKS_STATUS_FAILED:
      "A última chamada para o servidor do gancho falhou",
    SETTINGS_WEBSITE_HOOKS_DELETE: "Excluir gancho",
    SETTINGS_STATUS_SERVICES_NODE: "Excluir nó",
    CONTACTS_DEFAULT_COMPANY: "Onde este contato trabalha atualmente",
    CONTACTS_DEFAULT_SEGMENTS:
      "Segmentos dos contatos, úteis para agrupar contatos em categorias",
    CONTACTS_DEFAULT_ACTIVE:
      "Quando este usuário esteve conectado pela última vez",
    CONTACTS_DEFAULT_SCORE:
      "Pontuação dos contatos, conforme as avaliações de qualidade do suporte",
    CONTACTS_PROFILE_CONTACT: "Informações de contato com o usuário",
    CONTACTS_PROFILE_CONVERSATIONS:
      "Todas as conversas anteriores e em andamento com o contato",
    CONTACTS_PROFILE_COMPANY: "Empresa na qual o contato trabalha",
    CONTACTS_PROFILE_DEVICES: "Todos os dispositivos usados pelo contato",
    CONTACTS_PROFILE_DEVICES_IP: "Endereço IP do dispositivo",
    CONTACTS_PROFILE_LOCATION:
      "O último local em que o dispositivo do contato esteve",
    CONTACTS_PROFILE_NOTEPAD:
      "Bloco de notas particular no qual as notas podem ser deixadas.",
    CONTACTS_PROFILE_DATA: "Os dados do contato que foram definidos",
    CONTACTS_PROFILE_RATINGS: "As avaliações enviadas pelo contato",
    CONTACTS_PROFILE_SEGMENTS: "Os segmentos do contato que foram definidos",
    CONTACTS_PROFILE_PAGES: "Todas as páginas visitadas pelo contato",
    CONTACTS_PROFILE_EVENTS: "Todos os eventos enviados para o contato",
    CONTACTS_PROFILE_CAMPAIGNS: "Todas as campanhas recebidas pelo contato",
    ANALYTICS_HELPDESK_SEARCH_WORD_COUNT: "Pesquisas:",
    PAGE_HISTORY_LAST: "Última página visitada",
    PAGE_HISTORY_OPEN: "Abrir página (nova aba)",
    PROFILE_CAMPAIGN_OPEN: "Ir para a campanha",
    FIELD_LABEL_REQUIRED: "Este campo é obrigatório",
    FIELD_IMAGE_UPLOAD: "Carregar novo",
    FIELD_IMAGE_CLEAR: "Limpar existente",
    SEGMENTATION_FILTERS_REMOVE: "Remover todos os critérios",
    SEGMENTATION_FILTER_REMOVE: "Remover critério",
    SIDEBAR_WEBSITE_OFFLINE: "Site fora do ar ",
    SPOTLIGHT_FORM_ACTIONS_CLOSE: "Fechar pesquisa",
    EDITOR_BOLD: "Negrito",
    EDITOR_ITALIC: "Itálico",
    EDITOR_UNDERLINE: "Sublinhado",
    EDITOR_INSERT_COLOR: "Texto colorido",
    EDITOR_TITLE_1: "Título (grande)",
    EDITOR_TITLE_2: "Título (médio)",
    EDITOR_TITLE_3: "Título (pequeno)",
    EDITOR_ALIGN_LEFT: "Alinhar à esquerda",
    EDITOR_ALIGN_CENTER: "Alinhar ao centro",
    EDITOR_ALIGN_RIGHT: "Alinhar à direita",
    EDITOR_ALIGN_JUSTIFY: "Justificar conteúdo",
    EDITOR_LIST: "Lista",
    EDITOR_INSERT_CODE: "Inserir código",
    EDITOR_INSERT_LINK: "Inserir uma hiperligação",
    EDITOR_INSERT_IMAGE: "Inserir uma imagem",
    EDITOR_INSERT_GIF: "Inserir um GIF",
    EDITOR_INSERT_FILE: "Inserir um arquivo",
    EDITOR_INSERT_REPLACEMENT: "Insira uma etiqueta de substituição",
    EDITOR_BLOCKQUOTE: "Frase",
    EDITOR_LINE: "Line separador",
    EDITOR_TABLE: "Tabela",
    EDITOR_EMPHASIS_TIP: "Caixa de dicas",
    EDITOR_EMPHASIS_INFO: "Caixa de informação",
    EDITOR_EMPHASIS_WARNING: "Caixa de avisos",
    EDITOR_INSERT_VIDEO: "Inserir um vídeo",
    INBOX_CONVERSATION_ACTIVATE_EDITOR: "Ativar editor",
    INBOX_CONVERSATION_DEACTIVATE_EDITOR: "Desativar editor",
    INBOX_USERINFO_COPY: "Copiar",
    INBOX_USERINFO_COPIED: "Copiado!",
    INBOX_USERINFO_EDIT: "Editar",
    INBOX_USERINFO_REMOVE: "Remover",
    INBOX_USERINFO_REFRESH: "Atualizar",
    INBOX_USERINFO_PHONE: "Número do celular do visitante",
    INBOX_USERINFO_CONTACT_DATA: "Dados do perfil",
    CONTACTS_PROFILE_CARD_MAP_OPEN: "Abrir este item",
    CONTACTS_PROFILE_DEVICES_BROWSING_OFFLINE:
      "MagicBrowse indisponível (aparelho desligado)",
    CONTACTS_PROFILE_DATA_REMOVE: "Remover dados",
    SETTINGS_WEBSITE_OPERATOR_EDIT: "Editar adesão",
    SETTINGS_WEBSITE_ROUTING_MOVE_UP: "Aumentar prioridade da regra",
    SETTINGS_WEBSITE_ROUTING_MOVE_DOWN: "Diminuir prioridade da regra",
    SETTINGS_WEBSITE_ROUTING_REMOVE: "Remover regra",
    SETTINGS_BILLING_INVOICES_UNAVAILABLE: "Fatura não disponível",
    SETTINGS_PLANS_COUPON_REDEEMED: "Já foi aplicado um cupão",
    SETTINGS_PLANS_COUPON_UPGRADE:
      "Um cupom não pode ser aplicado a este plano",
    CAMPAIGNS_TEMPLATE_UPDATE: "Atualizar com o código atual",
  },
  VALIDATION: {
    FIELDS: {
      BANG: "explosão",
      CONTENT: "conteúdo",
      COUPON: "cupão",
      CUSTOM_KEY: "chave personalizada",
      DESCRIPTION: "descrição",
      DOMAIN: "domínio",
      EMAIL: "e-mail",
      EXPIRE: "expirar",
      FIRST_NAME: "primeiro nome",
      HEIGHT: "altura",
      LABEL: "rótulo",
      LAST_NAME: "sobrenome",
      NAME: "nome",
      NICKNAME: "apelido",
      PASSWORD: "senha",
      PHONE: "telefone",
      TARGET: "alvo",
      TITLE: "título",
      TOKEN: "símbolo",
      URL: "url",
      WIDTH: "largura",
      ADDRESS: "endereço",
      CARD_EXPIRE: "cartão expira",
    },
    RULES: {
      DOMAIN: "Este não é um domínio válido",
      URL: "Este não é URL válido",
      YOUTUBE: "Esta não é uma hiperligação para um vídeo no Youtube válida",
    },
  },
  INITIATE: {
    COMMON: {
      NEED_HELP: "Precisa de ajuda?",
      TWO_FACTOR_TITLE: "Autenticação de Dois Fatores",
      TWO_FACTOR_SUBTITLE: "Por favor, digite seu token para continuar.",
      CONFIRM_TITLE: "Esse login precisa ser confirmado",
      CONFIRM_SUBTITLE:
        "Você está acessando de um local novo. Enviamos uma mensagem para seu e-mail para confirmar se é você mesmo.",
      BACK: "Voltar",
    },
    SETUP: {
      GUIDE_RESOURCE:
        "Seguindo este guia, você se conectará a Reinvent para {name} armazenar e se beneficiar de múltiplos recursos.",
    },
    LOGIN: {
      TITLE_DEFAULT: "Entrar",
      SUBTITLE_DEFAULT:
        "Entre na sua conta do Reinvent para acessar sua Caixa de Entrada.",
      EMAIL_LABEL: "Entre com seu e-mail",
      EMAIL_PLACEHOLDER: "Digite seu e-mail…",
      PASSWORD_LABEL: "Senha",
      PASSWORD_PLACEHOLDER: "Digite sua senha…",
      REMEMBER_LABEL: "Lembre-me",
      BUTTON_CREDENTIALS: "Autentique-se no Painel",
      TOKEN_LABEL: "Token de autenticação (Autenticação de Dois Fatores)",
      BUTTON_TOKEN: "Autenticar com token",
      BUTTON_CONFIRM: "Autenticar novamente (Uma vez confirmado)",
      LOST_PASSWORD: "Esqueceu a senha?",
      LOST_TOKEN_SEND: "Perdeu o token?",
      LOST_TOKEN_RESEND: "Enviado via SMS. Reenviar?",
      CANCEL_TOKEN: "Cancelar a autenticação do token.",
      REGISTER: "Ainda não tem uma conta? Cadastre-se.",
      FEATURES: {
        LEARN_MORE: "Saiba mais",
        INSTAGRAM: {
          TITLE: "Receba mensagens do Instagram",
          SUBTITLE:
            "Conecte o Instagram da sua empresa na Reinvent e receba mensagens de direct dentro do painel de atendimento.",
        },
        CALLS: {
          TITLE:
            "Chamadas de vídeo e áudio diretamente de sua caixa de entrada Reinvent ",
          SUBTITLE:
            "Chamadas de vídeo e áudio fora da caixa, em tempo real. Não plugin ou software para instalar, apenas uma experiência alegre e humana.",
        },
        TRIGGERS: {
          TITLE: "Obtenha 2x mais vendas usando Triggers",
          SUBTITLE:
            "Enviar mensagens automáticas que criam um serviço proativo ao cliente que converte os visitantes em oportunidades.",
        },
        FAQ: {
          TITLE:
            "Otimize sua experiência de cliente com a Base de Conhecimento Reinvent ",
          SUBTITLE:
            "Torne seus clientes mais autônomos com uma base de conhecimento. Esqueça respostas repetitivas, conteúdos de ajuda ocultos ou não indexáveis, respostas lentas ou clientes não autônomos.",
        },
        CHATBOT: {
          TITLE:
            "Ofereça a melhor experiência de conversação com o Reinvent Chatbot",
          SUBTITLE:
            "Construa comportamentos automatizados em cada canal conectado à sua caixa de entrada sem nenhuma habilidade de codificação.",
        },
        EMAIL: {
          TITLE:
            "Gerencie todos os seus e-mails diretamente de sua caixa de entrada Reinvent ",
          SUBTITLE:
            "Supere seu suporte por e-mail em escala integrando tarefas, respostas enlatadas e obtenha todos os benefícios dos recursos Reinvent.",
        },
      },
    },
    RECOVER: {
      TITLE: "Recupere sua senha do Reinvent",
      SUBTITLE:
        "Enviaremos um link de recuperação para seu e-mail para que você possa redefinir sua senha e acessar sua conta.",
      EMAIL_LABEL: "Insira seu endereço de e-mail.",
      EMAIL_PLACEHOLDER: "Digite seu e-mail",
      BUTTON: "Recuperar Senha",
      LOGIN: "Retorne para página de login",
    },
    RECOVER_CHANGE_PASS: {
      TITLE: "Altere sua senha da Reinvent",
      SUBTITLE:
        "Insira a sua nova senha para atualizar a sua conta imediatamente",
      PASSWORD_LABEL: "Nova senha",
      PASSWORD_PLACEHOLDER: "Digite sua nova senha",
      BUTTON: "Salvar Senha",
      LOGIN: "Retorne para página de login",
    },
    REGISTER: {
      DEFAULT_TITLE: "Vamos Lá",
      DEFAULT_SUBTITLE: "Para continuar, crie grátis sua conta no Reinvent.",
      EMAIL_LABEL: "Digite seu endereço de e-mail",
      EMAIL_PLACEHOLDER: "Insira seu melhor e-mail",
      PASSWORD_LABEL: "Digite uma senha",
      PASSWORD_PLACEHOLDER: "Insira uma palavra-passe",
      PHONE_LABEL: "Insira seu telefone",
      PHONE_PLACEHOLDER: "Insira seu telefone pessoal",
      FIRSTNAME_LABEL: "O seu primeiro nome",
      FIRSTNAME_PLACEHOLDER: "Primeiro nome",
      LASTNAME_LABEL: "O seu sobrenome",
      LASTNAME_PLACEHOLDER: "Sobrenome",
      WEBSITE_TITLE: "Instale o Reinvent no seu site",
      WEBSITE_SUBTITLE:
        "Preencha as informações de sua empresa (você poderá editar depois). Se seu site ainda não está no ar, você pode informar um endereço temporário.",
      NAME_LABEL: "Qual é o nome do seu site?",
      NAME_PLACEHOLDER: "Nome do meu site",
      DOMAIN_LABEL: "Qual é o domínio do seu site?",
      DOMAIN_PLACEHOLDER: "www.empresa.com",
      CHATBOX_TITLE: "Seu Chatbox",
      CHATBOX_SUBTITLE:
        "Personalize seu balão de chat com um avatar e um esquema de cores.",
      CHATBOX_COLOR: "Chatbox Cor",
      SETUP_TITLE: "Sucesso!",
      SETUP_SUBTITLE_DEFAULT:
        "Sua conta Reinvent foi criada! Adicione o balão de chat ao seu website colando este código como último elemento na seção <head> do seu HTML.",
      SETUP_SUBTITLE_DETECTED:
        "Sua conta Reinvent foi criada! Parece que seu website está usando um CMS. Utilize o guia abaixo para adicionar o balão de chat ao seu website.",
      DONE_TITLE: "Bem-vindo ao Reinvent",
      DONE_SUBTITLE: "Vá conversar com os seus clientes agora!",
      NEXT: "Continuar",
      DONE: "Acessar o painel",
      TERMS_LABEL: "Li e aceito os termos de uso da Reinvent:",
      TERMS_READ: "ler termos",
      LOGIN: "Já tem uma conta? Entre.",
    },
    OPERATOR: {
      LOGIN_SUBTITLE:
        "Entre na sua conta do Reinvent para participar da equipe do site.",
      BUTTON_LOGIN: "Entrar & Participar",
      FAILED: {
        TITLE: "Convite Inválido",
        SUBTITLE: "Este convite expirou. Será que já foi usado antes?",
      },
      ABORTED: {
        TITLE: "Não Foi Possível Participar do Site",
        SUBTITLE:
          "Este site atingiu o limite de operadores. Por favor, atualize o plano.",
      },
      WELCOME: {
        TITLE: "Você foi convidado para participar da equipe {website}",
        SUBTITLE:
          "Reinvent é um aplicativo de atendimento ao cliente usado por {website}.",
        SUBTITLE_EXISTING:
          "Como você já tem uma conta da Reinvent, clique em \\'Continuar\\' para entrar na Caixa de Entrada de {website}",
        SUBTITLE_NEW:
          "Vamos criar uma nova conta do Reinvent para que você possa entrar na Caixa de Entrada de {website}, clique em \\'Continuar\\' para criar sua nova conta.",
        BUTTON: "Continuar",
      },
      REGISTER: {
        TITLE: "Sua Conta",
        SUBTITLE:
          "Crie sua conta e escolha uma senha para entrar na Caixa de Entrada de {website}",
        ACCOUNT: "Conta",
      },
      SUCCESS: {
        TITLE: "Já está pronto!",
        SUBTITLE:
          "Você está na Caixa de Entrada de {website}. Você pode agora colaborar com seus colegas e conversar com os clientes.",
        BUTTON: "Ir para Caixa de Entrada de {website}",
      },
      AVAILABILITY_SELECT: {
        TITLE: "Disponibilidade",
        SUBTITLE:
          "Escolha o melhor modo de disponibilidade para você, assim não vamos notificá-lo quando você estiver ausente.",
        AVAILABLE_APP: {
          TITLE: "Disponível ao usar o aplicativo",
          SUBTITLE:
            "Você será visto como disponível apenas quando o aplicativo do Reinvent estiver aberto no seu computador ou no seu celular.",
        },
        SCHEDULE: {
          TITLE: "Predefinir horário de expediente",
          SUBTITLE:
            "Você será visto como disponível apenas durante o horário de trabalho que definir.",
        },
      },
      AVAILABILITY_SCHEDULE: {
        TITLE: "Disponibilidade",
        SUBTITLE:
          "Personalize sua agenda de dias e horários disponíveis (no seu fuso horário). Nos demais horários, você será marcado como ausente para sua equipe e seus clientes.",
        BUTTON: "Continuar",
      },
      NOTIFICATIONS: {
        TITLE: "Notificações",
        SUBTITLE:
          "Escolha como você receberá suas notificações. Não recomendamos desativá-las, pois você não será notificado quando chegarem novas mensagens.",
        ENABLE: {
          TITLE: "Ativar notificações",
          SUBTITLE: "Você será notificado de novas mensagens (via push)",
        },
        SKIP: "Ignorar por enquanto.",
        BUTTON: "Continuar",
      },
      UPDATE_AVATAR: {
        TITLE: "Perfil",
        SUBTITLE:
          "É hora de escolher sua foto de perfil! Recomendamos usar sua foto de verdade, pois isso estimulará os visitantes a conversar com você.",
        SKIP: "Ignorar por enquanto.",
        BUTTON: "Continuar",
      },
      TWO_FACTOR_PHONE: {
        TITLE: "Autenticação de Dois Fatores Obrigatória",
        SUBTITLE:
          "Por razões de segurança, este site exige a Autenticação de Dois Fatores. Por tanto, você precisa digitar um número válido de celular.",
        PHONE_LABEL: "Qual é o número de seu celular?",
        PHONE_PLACEHOLDER: "+5511987654321",
        BUTTON: "Continuar",
      },
      TWO_FACTOR_QRCODE: {
        TITLE: "Autenticação de Dois Fatores Obrigatória",
        SUBTITLE: "Fotografe este QR code com seu aplicativo de autenticação.",
        BUTTON: "Eu Fotografei o Código",
      },
      TWO_FACTOR_VERIFY: {
        TITLE: "Verificar o Código Fotografado",
        SUBTITLE:
          "Para confirmar a sua instalação, digite abaixo o código de 6 dígitos gerado por seu aplicativo de autenticação.",
        BUTTON: "Verificar",
      },
    },
    LOGOUT: {
      TITLE: "A sair…",
    },
    OFFLINE: {
      TITLE: "Huh. O Reinvent está sem ligação.",
      SUBTITLE: "Verifique se está conectado a uma rede.",
      PROBLEM: "Se não conseguir se reconectar, envie-nos um email para",
      COUNT: "Reconectará em {count} segundos",
      CHECKING: "O Reinvent está a reconectar-se…",
      RELOAD: "Reconectar agora",
      RELOADING: "Reconectando",
    },
    PLUGIN: {
      TITLE: "Associe a Reinvent ao {plugin_name}",
      NOTICE: "{plugin_name} deseja se conectar ao seu painel",
      PAID_TITLE: "Esta extensão é paga.",
      PAID_NOTICE: "Para continuar, assine a extensão {plugin_name}",
      SELECT_WEBSITE: "Selecione um site",
      CONTINUE: "Autorizar",
      NOT_AVAILABLE: "Plugin não disponível para sua conta",
      ERROR_OWNER_REQUIRED:
        "Você precisa de acesso de proprietário para esta operação.",
    },
  },
  INBOX: {
    COMMON: {
      FILTER_OPERATORS: "Operadores de filtro…",
      NO_OPERATOR_FOUND: "Nenhum operador encontrado",
    },
    DEFAULT: {
      ONBOARDING_HEAD_TITLE: "Preparar, treinar, atender!",
      ONBOARDING_HEAD_LABEL_1:
        "Tire o máximo proveito da sua conta Reinvent seguindo estas etapas de configuração.",
      ONBOARDING_HEAD_LABEL_2:
        "Seu trabalho fica mais fácil a cada etapa concluída.",
      ONBOARDING_STEPS: {
        APPS: {
          TITLE: "Obter as aplicações do Reinvent para telemóveis",
          LABEL: "Use o Reinvent em movimento",
        },
        CHATBOX: {
          TITLE: "Instalar a janela de chat no seu site",
          LABEL: "Receba mensagens dos seus usuários.",
        },
        MESSENGER: {
          TITLE: "Conecte-se ao Facebook Messenger",
          LABEL: "Responda às mensagens enviadas pelo Messenger.",
        },
        EMAIL: {
          TITLE: "Receba os seus e-mails no Reinvent",
          LABEL: "Gerenciar e-mails recebidos no Reinvent",
        },
        SLACK: {
          TITLE: "Conecte-se a sua equipa do Slack",
          LABEL: "Deixe sua equipa do Slack responder do Slack",
        },
        TRIGGERS: {
          TITLE: "Enviar mensagens automáticas",
          LABEL:
            "Automatize o seu marketing com ações automáticas na janela de chat.",
        },
        HELPDESK: {
          TITLE: "Construir sua Central de Ajuda",
          LABEL: "Escreva artigos para ajudar seus usuários.",
        },
        BOT: {
          TITLE: "Construa seu primeiro robô",
          LABEL: "Escreva roteiros para o robô e automatize suas respostas.",
        },
      },
      ONBOARDING_APPS: {
        DOWNLOAD_IOS: "iOS",
        DOWNLOAD_ANDROID: "Android",
      },
      ONBOARDING_TRIAL_DAY: "dia de teste",
      ONBOARDING_TRIAL_DAYS: "dias de teste",
      ONBOARDING_SETUP: "Configurar agora",
      ONBOARDING_ALREADY_CONFIGURED: "Já está configurado!",
      ONBOARDING_HELP: "Precisa de ajuda para configurar a Reinvent?",
      ONBOARDING_HIDE: "Ocultar isto",
      POPUPS_HIDE_SETUP_QUESTION: "Ocultar instruções de configuração?",
      POPUPS_HIDE_SETUP_LABEL:
        "As instruções de instalação ficarão ocultas para sempre. Você ainda pode configurar o Reinvent sem essa tela, mas não será guiado pelas etapas recomendadas.",
      POPUPS_HIDE_SETUP_PROCEED: "Ocultar instruções.",
    },
    CONVERSATION: {
      MARK_RESOLVED: "Marque esta conversa como resolvida.",
      MARK_UNRESOLVED: "Esta conversa foi resolvida.",
      PENDING:
        "Esta conversa está pendente. Você pode enviar a primeira resposta.",
      COMPOSE_SEND: "Envie a sua mensagem.",
      COMPOSE_SEND_TO: "Para",
      COMPOSE_SEND_CHAT: "no chat",
      COMPOSE_SEND_EMAIL: "no e-mail",
      COMPOSE_BLOCKED:
        "está bloqueado. Desbloqueie para enviar ou receber mensagens.",
      COMPOSE_NOTE: "Registre um lembrete privado nesta conversa",
      SHARE_FILE: "Compartilhar um arquivo com",
      SHARE_SHORTCUT_INSERT: "Escolha um atalho de mensagem",
      SHARE_SHORTCUT_ADD: "Adicionar mensagens aos atalhos",
      SEND: "Enviar mensagem",
      DROP_FILE: "Envie um arquivo",
      GIF_SEARCH: "Encontrar GIFs",
      SHORTCUT_TITLE: "Atalhos",
      SHORTCUT_NONE:
        "Nenhum atalho foi encontrado. Você pode adicionar mais atalhos",
      ALERT_UNREAD: "Há novas mensagens abaixo. Clique para vê-las.",
      ALERT_EMPTY:
        "Esta conversa ainda não foi iniciada. Envie uma mensagem para começar.",
      POPUPS_NEW_SHORTCUT_BANG_LABEL: "!pronto do atalho",
      POPUPS_NEW_SHORTCUT_BANG_PLACEHOLDER:
        "Digite o !pronto do atalho (ex. !olá)",
      POPUPS_NEW_SHORTCUT_PROCEED: "Adicionar atalho",
      POPUPS_PASTE_FILE_QUESTION:
        "Você quer mesmo enviar o arquivo que acabou de colar?",
      POPUPS_PASTE_FILE_LABEL: "O arquivo será enviado para a conversa.",
      POPUPS_PASTE_FILE_PROCEED: "Enviar Arquivo",
      POPUPS_SET_REMINDER_DATE_TIME_LABEL: "Data do lembrete",
      POPUPS_SET_REMINDER_DATE_TIME_PROCEED: "Definir Data",
      MENTION_TITLE: "Mencione um operador",
      SHARE_AUDIO_RECORD: "Mantenha pressionado para gravar um áudio.",
      MODE_TOGGLE_REPLY: "Resposta",
      MODE_TOGGLE_NOTE: "Lembrete",
      MODE_TOGGLE_EDIT: "Editar",
      MODE_TOGGLE_REMINDER: "Lembrete",
      POPUPS_SEND_CONFIRM_QUESTION:
        "Tem a certeza que deseja enviar esta mensagem?",
      POPUPS_SEND_CONFIRM_LABEL:
        "A mensagem será enviada ao usuário assim que você confirmar.",
      POPUPS_SEND_CONFIRM_REMEMBER: "Não mostrar mais este pop-up.",
      POPUPS_SEND_CONFIRM_PROCEED: "Enviar mensagem",
      POPUPS_SEND_SANDBOX_REMIND_QUESTION:
        "Enviar esta mensagem no modo sandbox?",
      POPUPS_SEND_SANDBOX_REMIND_LABEL:
        "Você pode não estar respondendo a um usuário humano real, pois seu site está atualmente no modo sandbox. Você deve usá-lo apenas para fins de desenvolvimento. Se você estiver usando o site errado, poderá mudar para o site de produção.",
      POPUPS_SEND_SANDBOX_REMIND_PROCEED: "Enviar mensagem mesmo assim",
      POPUPS_SEND_SANDBOX_REMIND_WAIT: "Aguente…",
      POPUPS_CHATBOX_CLEAR_SESSION_QUESTION:
        "Limpar sessão da janela de chat agora?",
      POPUPS_CHATBOX_CLEAR_SESSION_LABEL:
        "A sessão da janela de chat será removida remotamente. O navegador do usuário será desconectado desta conversa.",
      POPUPS_CHATBOX_CLEAR_SESSION_PROCEED: "Limpar sessão da janela de chat",
      TRANSLATE_INITIATE_STATE_1: "fala",
      TRANSLATE_INITIATE_STATE_2: "Veja as ultimas mensagens em",
      TRANSLATE_INITIATE_STATE_3: "?",
      TRANSLATE_INITIATE_STATE_4: "Nada para traduzir aqui.",
      TRANSLATE_INITIATE_ACTION: "Iniciar LiveTranslate",
      TRANSLATE_ONGOING_STATE_1:
        "O LiveTranslate está neste momento ativo em mensagens com",
      TRANSLATE_ONGOING_ACTION: "Parar",
      TRANSLATE_INITIATE_LOCALE_MORE_LIKELY: "(mais provável)",
      TRANSLATE_INITIATE_LOCALE_LESS_LIKELY: "(menos provável)",
      TRANSLATE_INITIATE_LOCALE_OTHERS: "Ver mais…",
      TRANSLATE_TRANSLATING: "Traduzindo mensagem…",
      SHARE_HELPDESK_INSERT: "Escolha um artigo da Central de Ajuda",
      HELPDESK_TITLE: "Central de Ajuda",
      HELPDESK_NONE:
        "Nenhum artigo da Central de Ajuda foi encontrado. Tente novamente.",
      HELPDESK_CATEGORY_NONE: "Nenhuma categoria.",
      HELPDESK_PREVIEW: "Visão geral",
      REMINDER_TITLE: "Agendar lembrete",
      REMINDER_LABEL: "Lembre-me:",
      REMINDER_PICKER: {
        PREDEFINED_TITLE: "Numa data predefinida…",
        PREDEFINED_ONE_HOUR: "Em 1 hora",
        PREDEFINED_TWO_HOURS: "Em 2 horas",
        PREDEFINED_ONE_DAY: "Amanhã, neste horário",
        PREDEFINED_TWO_DAYS: "Depois de amanhã, neste horário",
        PREDEFINED_ONE_WEEK: "Daqui a uma semana, neste horário",
        CUSTOM_TITLE: "Numa data personalizada…",
        CUSTOM_PROVIDED: "Na data e hora informadas",
      },
      MENTION_NO_MATCH: "Nenhum operador encontrado.",
      MODE_TOGGLE_SHORTCUTS: "Atalhos",
      MODE_TOGGLE_HELPDESK: "Central de Ajuda",
      GO_TO_FULL: "Abrir a conversa completa",
    },
    USER_INFO: {
      EMAIL_TRANSCRIPT_EMAIL: "Enviar para",
      EMAIL_TRANSCRIPT_BASE: "uma transcrição da conversa",
      EMAIL_TRANSCRIPT_OPERATOR: "mim",
      EMAIL_TRANSCRIPT_EXTERNAL: "alguém",
      CHATBOX_CLEAR_SESSION: "Limpar sessão da janela de chat",
      BLOCK_USER: "Bloquear usuário",
      UNBLOCK_USER: "Desbloquear usuário",
      EMAIL_DEFINE: "definir e-mail",
      PROFILE_VIEW: "Ver perfil de {name}",
      WIDGET_DEVICE_ON: "em",
      WIDGET_DEVICE_TITLE: "Dispositivo do visitante",
      WIDGET_CALL_AVAILABLE: "Disponível para ligar agora",
      WIDGET_CALL_BUSY: "Outra ligação está em andamento",
      WIDGET_CALL_ONGOING: "Ligação em andamento",
      WIDGET_CALL_PROCEED: "Ligue para o Reinvent",
      WIDGET_CALL_STOP: "Interromper ligação em andamento",
      WIDGET_DATA_TITLE: "Dados do visitante",
      WIDGET_DATA_KEY: "Chave de dados",
      WIDGET_DATA_VALUE: "Valor dos dados…",
      WIDGET_EVENTS_TITLE: "Últimos eventos do perfil",
      WIDGET_NOTEPAD_TITLE: "Bloco de notas privado",
      WIDGET_PAGE_HISTORY_TITLE: "Últimas paginas navegadas",
      WIDGET_ROUTING_TITLE: "Operador responsável",
      WIDGET_ROUTING_OPERATOR_NONE: "Nenhum",
      WIDGET_ROUTING_OPERATOR_REASSIGN: "Transferir",
      WIDGET_ROUTING_OPERATOR_ASSIGN: "Definir agora",
      WIDGET_RATING_TITLE: "Nota",
      WIDGET_RATING_COMMENT: "Comentário",
      WIDGET_RATING_COMMENT_EMPTY: "Sem comentários.",
      WIDGET_RATING_SEE_MORE: "Ver mais",
      WIDGET_RATING_SEE_LESS: "Ver menos",
      WIDGET_PARTICIPANTS_TITLE: "Participantes da conversa",
      WIDGET_PARTICIPANTS_ADD: "Adicionar",
      WIDGET_CUSTOM_ACTIONS_TITLE: "Ações personalizadas",
      WIDGET_MAIN_TITLE: "Informações principais",
      WIDGET_SEGMENTS_TITLE: "Tags do atendimento",
      WIDGET_SEGMENTS_EXPLAIN: "Adicionar Tag…",
      WIDGET_GENERIC_EMPTY: "Não há seções para exibir.",
      WIDGET_GENERIC_SECTION_EMPTY: "Não há itens para exibir.",
    },
    FLOW: {
      DOWNLOAD_FILE: "Baixar arquivo",
      PREVIEW_VIDEO: "Reproduzir vídeo",
      EVENT_STATE_RESOLVED: "Conversa resolvida",
      EVENT_USER_BLOCKED: "Usuário bloqueado",
      EVENT_REMINDER_SCHEDULED: "Deixou um lembrete",
      EVENT_THREAD_STARTED: "Thread inicializada",
      EVENT_THREAD_ENDED: "Thread finalizada",
      EVENT_PARTICIPANT_ADDED: "Participante adicionado",
      EVENT_PARTICIPANT_REMOVED: "Participante removido",
      EVENT_CALL_STARTED: "Iniciou uma chamada",
      EVENT_CALL_ENDED: "Encerrou a chamada",
      EVENT_DEFAULT: "Ocorreu um evento desconhecido",
      NOT_SENT: "Não enviado. Repetir?",
      TOOLTIP_PARTICIPANT: "Participante",
      TOOLTIP_AUTOMATED: "Automatizado",
      TOOLTIP_DATE_RECEIVED: "Recebido",
      TOOLTIP_DATE_SENT: "Enviado",
      TOOLTIP_DATE_EDITED: "Editado",
      TOOLTIP_DATE_TRANSLATED: "Traduzido",
      TOOLTIP_ORIGIN_SENT_FROM: "Enviado de",
      TOOLTIP_ORIGIN_ORIGINAL: "Ver original",
      TOOLTIP_ORIGIN_FULL: "Expandir tudo",
      LEFT_PRIVATE_NOTE: "deixou esta nota particular:",
      READ_IN: "Lida em",
      DELIVERED_TO: "Entregue a",
    },
    LIST: {
      CONTROLS_MORE_TOOLTIP: "Ações",
      CONTROLS_MORE_NEW_CONVERSATION: "Crie uma nova conversa",
      CONTROLS_MORE_REAL_ALL: "Marcar todas as mensagens como lidas",
      CONTROLS_MORE_RESOLVE_ALL: "Resolver todas as conversas",
      CONTROLS_MORE_KEYBOARD_SHORTCUTS: "Ver atalhos do teclado…",
      EMPTY_NO_CONVERSATION_DEFAULT_TITLE: "Você não tem conversas",
      EMPTY_NO_CONVERSATION_DEFAULT_LABEL:
        "Todas as mensagens dos contatos ficarão aqui.",
      POPUPS_NEW_CONVERSATION_NAME_LABEL: "Nome do Contato",
      POPUPS_NEW_CONVERSATION_NAME_PLACEHOLDER:
        "Digite o nome completo do contato…",
      POPUPS_NEW_CONVERSATION_EMAIL_LABEL: "E-mail do Contato",
      POPUPS_NEW_CONVERSATION_EMAIL_PLACEHOLDER: "Digite o e-mail do contato…",
      POPUPS_NEW_CONVERSATION_SUBJECT_LABEL: "Assunto do e-mail",
      POPUPS_NEW_CONVERSATION_SUBJECT_PLACEHOLDER:
        "Digite o assunto do e-mail..",
      POPUPS_NEW_CONVERSATION_PROCEED: "Criar Nova Conversa",
      POPUPS_REMOVE_CONVERSATION_QUESTION:
        "Tem a certeza que quer remover esta conversa?",
      POPUPS_REMOVE_CONVERSATION_LABEL:
        "As mensagens da conversa serão perdidas.",
      POPUPS_REMOVE_CONVERSATION_PROCEED: "Remover conversa",
      POPUPS_READ_ALL_CONVERSATIONS_QUESTION:
        "Quer marcar todas as mensagens como lidas?",
      POPUPS_READ_ALL_CONVERSATIONS_LABEL:
        "Todas as mensagens serão marcadas como lidas.",
      POPUPS_READ_ALL_CONVERSATIONS_PROCEED: "Ler Todas as Mensagens",
      POPUPS_RESOLVE_ALL_CONVERSATIONS_QUESTION:
        "Quer marcar todas as conversas como resolvidas?",
      POPUPS_RESOLVE_ALL_CONVERSATIONS_LABEL:
        "Todas as conversas serão marcadas como resolvidas.",
      POPUPS_RESOLVE_ALL_CONVERSATIONS_PROCEED: "Resolver Todas as Conversas",
      POPUPS_EDIT_CUSTOM_FILTER_LABEL_LABEL:
        "Rótulo para o filtro personalizado",
      POPUPS_EDIT_CUSTOM_FILTER_LABEL_PLACEHOLDER:
        "Digite um rótulo (por exemplo, ",
      POPUPS_EDIT_CUSTOM_FILTER_PROCEED: "Salvar filtro personalizado",
      FILTERS_ALL: "Tudo",
      FILTERS_UNREAD: "Não lida",
      FILTERS_UNRESOLVED: "Não resolvido",
      EMPTY_NO_CONVERSATION_FILTER_UNREAD_TITLE: "Nenhuma mensagem não lida",
      EMPTY_NO_CONVERSATION_FILTER_UNREAD_LABEL:
        "Apenas as mensagens não lidas aparecem aqui",
      EMPTY_NO_CONVERSATION_FILTER_NOT_RESOLVED_TITLE:
        "Nenhuma conversa não resolvida",
      EMPTY_NO_CONVERSATION_FILTER_NOT_RESOLVED_LABEL:
        "Apenas as conversas não resolvidas aparecem aqui",
      FILTERS_MORE: "Filtros",
      FILTERS_MORE_MENTIONS: "Menções",
      FILTERS_MORE_CUSTOM: "Novo filtro personalizado…",
      EMPTY_NO_CONVERSATION_FILTER_MENTION_TITLE: "Nenhuma conversa mencionada",
      EMPTY_NO_CONVERSATION_FILTER_MENTION_LABEL:
        "Apenas as conversas em que você foi mencionado aparecem aqui",
      EMPTY_NO_CONVERSATION_FILTER_CUSTOM_TITLE: "Nenhuma conversa filtrada",
      EMPTY_NO_CONVERSATION_FILTER_CUSTOM_LABEL:
        "Apenas as conversas que correspondem ao seu filtro aparecem aqui",
      POPUPS_NEW_CONVERSATION_PHONE_LABEL: "Telefone do Usuário",
      POPUPS_NEW_CONVERSATION_PHONE_PLACEHOLDER:
        "Digite o telefone do usuário…",
      POPUPS_NEW_CONVERSATION_WHATSAPP_LABEL: "WhatsApp do Usuário",
      POPUPS_NEW_CONVERSATION_WHATSAPP_PLACEHOLDER:
        "Digite o WhatsApp do usuário…",
      POPUPS_NEW_CONVERSATION_WHATSAPP_START_LABEL: "Mensagem de início",
      POPUPS_NEW_CONVERSATION_WHATSAPP_START_PLACEHOLDER:
        "Digite a mensagem de começo da conversa",
      SEARCH_ROUTING_ASSIGNED: "Atribuído a mim",
      SEARCH_ROUTING_ALL: "Todas as conversas",
      SEARCH_ROUTING_UNASSIGNED: "Não atribuído",
      SEARCH_ROUTING_OPERATORS: "Atribuído a…",
      FILTERS_MORE_MOST_RECENT: "Mais recente",
      EMPTY_NO_CONVERSATION_FILTER_RECENT_TITLE: "Nenhuma conversa recente",
      EMPTY_NO_CONVERSATION_FILTER_RECENT_LABEL:
        "As conversas mais recentes aparecem aqui.",
      FILTERS_MORE_RESOLVED: "Resolvido",
      EMPTY_NO_CONVERSATION_FILTER_RESOLVED_TITLE:
        "Nenhuma conversa resolvida.",
      EMPTY_NO_CONVERSATION_FILTER_RESOLVED_LABEL:
        "Somente conversas resolvidas aparecem aqui.",
      POPUPS_NEW_CONVERSATION_KEYS_EMAIL: "Iniciar uma conversa por e-mail",
      POPUPS_NEW_CONVERSATION_KEYS_PHONE: "Iniciar uma conversa por SMS",
      POPUPS_NEW_CONVERSATION_KEYS_WHATSAPPSECONDARY:
        "Iniciar uma conversa por WhatsApp Paralelo",
    },
  },
  VISITORS: {
    COMMON: {
      USERS_ONLINE_ONE: "usuário conectado",
      USERS_ONLINE_MULTIPLE: "usuários conectados",
      USERS_ACTIVE_ONE: "usuário ativo agora",
      USERS_ACTIVE_MULTIPLE: "usuários ativos agora",
      USERS_POWERED_BY: "Visualização ao vivo do MagicMap",
      USERS_PEOPLE_COUNT_SINGULAR: "usuário neste local",
      USERS_PEOPLE_COUNT_PLURAL: "usuários neste local",
      SEARCH_FILTER: "Filtrar por nome, cidade…",
      REALTIME_USERS: "Visitantes",
      ALERTS_SOCKET_TITLE: "Os visitantes podem não aparecer todos",
      ALERTS_SOCKET_LINE: "Seu site tem muito tráfego",
      ALERTS_SOCKET_LINE_WHY: "Porquê?",
      EMPTY_TITLE: "Não existem visitantes.",
      EMPTY_LABEL:
        "Ninguém está navegando agora no seu site. Os visitantes aparecerão aqui.",
      PAID_FEATURE:
        "O zoom do mapa está disponível apenas para planos pagos. Desbloqueie-o com o Reinvent Pro.",
      SHOW_POINT_DETAILS: "Mostrar pessoas ali.",
      REALTIME_USERS_LIVE: "Ao vivo",
    },
    BROWSING: {
      WAITING_FOR_TABS: "À procura de páginas abertas…",
      LOADING_CONNECTING: "Conectando com seu usuário…",
      LOADING_CONNECTION_LOST:
        "Opa, caiu a conexão com a janela de chat do usuário.",
      LOADING_DO_NOT_TRACK_1: "Este usuário ativou",
      LOADING_DO_NOT_TRACK_2: "Não monitorizar.",
      LOADING_DO_NOT_TRACK_3: "no navegador deles.",
      TAB_AVAILABLE_SOON:
        "Não será possível conectar se o usuário sair do site…",
      LOADING_READY_SOON: "Estará pronto em {remaining} segundos…",
      LOADING_LONGER: "Pode demorar um pouco mais.",
      LOADING_GONE_OFFLINE:
        "Possivelmente o usuário saiu do site ou fechou o navegador.",
      LOADING_REFUSED:
        "O Reinvent respeita as opções de privacidade do usuário, mas você pode alterá-las nas configurações do site.",
      LOADING_TRY_RECONNECT: "A tentar restabelecer a ligação",
      ACTIONS_LIVE_ASSIST_DEFAULT: "Iniciar LiveAssist",
      ACTIONS_LIVE_ASSIST_ACTIVE: "Parar LiveAssist",
      ACTIONS_LIVE_ASSIST_COMMENT:
        "Mova o mouse para dar assistência nesta página.",
      POPUPS_BROWSING_LOCALHOST_TITLE:
        "Parece que seu site está rodando no localhost",
      POPUPS_BROWSING_LOCALHOST_DESCRIPTION:
        "Por razões técnicas, o MagicBrowse requer acesso ao seu site pela internet. Experimente o MagicBrowse no seu site de produção. Se precisar de ajuda, entre em contato com o nosso suporte.",
    },
  },
  ANALYTICS: {
    TITLE: "Análises",
    TIMEZONE: "fuso horário",
    DOWNLOAD: "Transferir o relatório.",
    CONTROLS: {
      DATE_NAVIGATE_PREVIOUS: "Anterior",
      DATE_NAVIGATE_NEXT: "Próximo",
      DATE_RANGE_HOURLY: "Por hora",
      DATE_RANGE_HOUR: "Hora",
      DATE_RANGE_DAILY: "Diariamente",
      DATE_RANGE_DAY: "Dia",
      DATE_RANGE_WEEKLY: "Semanalmente",
      DATE_RANGE_WEEK: "Semana",
      DATE_RANGE_MONTHLY: "Mensalmente",
      DATE_RANGE_MONTH: "Mês",
      DATE_RANGE_YEAR: "Ano",
    },
    POPUPS: {
      GENERATE_REPORT_TITLE: "Gerar e transferir o relatório?",
      GENERATE_REPORT_NOTICE:
        "Os relatórios CSV do ecrã atual vão ser gerados. Vão ser guardados no seu computador e preparados para serem usados.",
      GENERATE_REPORT_LABEL: "Selecione uma métrica.",
      GENERATE_REPORT_NAMESPACES: {
        CONVERSATION_CREATED: "Atividade da conversa",
        CONVERSATION_RESPONSIVENESS: "Capacidade de resposta da equipe",
        CONVERSATION_SEGMENT: "Segmentos guardados ao longo do tempo",
        CONVERSATION_SHORTCUT: "Estatísticas do uso do atalho",
        CONVERSATION_ASSIGNED: "Conversas designadas por operador",
        VISITOR_TRIGGER: "Gatilho acionado",
        VISITOR_HEATMAP: "Mapa de calor da localização do visitante",
        VISITOR_CALENDAR: "Calendário de visitas ao site",
        PEOPLE_CREATED: "Contatos criados ao longo do tempo",
        PEOPLE_RATING: "Classificações e comentários gerais",
        PEOPLE_RATING_COMMENT: "Avaliações e comentários do usuário",
        CAMPAIGN_SENT: "E-mails da campanha enviados",
        CAMPAIGN_ACTIVITY: "Atividade do e-mail da campanha",
        HELPDESK_FEEDBACK: "Comentários nos artigos da Central de Ajuda",
        HELPDESK_READ: "Estatísticas dos artigos da Central de Ajuda",
        HELPDESK_SEARCH: "Buscas por artigos da Central de Ajuda",
        STATUS_DOWNTIME: "Estatísticas do tempo de inatividade do nó",
        STATUS_LATENCY: "Latência dos Nós Recebidos",
      },
      GENERATE_REPORT_FILE_NAME: "Relatório de análise do Reinvent",
      GENERATE_REPORT_PROCEED: "Relatório de Transferências",
    },
    GRAPHS: {
      CHART_NONE: "Ups! Parece que não temos dados neste momento.",
      INACTIVE_HELPDESK_ALERT_TITLE:
        "A Central de Ajuda está desativada. Não há estatísticas para exibir.",
      INACTIVE_STATUS_ALERT_TITLE:
        "Não há nenhuma Página de Status disponível. As análises nesta página não estarão disponíveis.",
      AVERAGE: "Média",
    },
    TOOLTIPS: {
      METAS: {
        CONVERSATION_CREATED: {
          NEW: "Novo",
          EXISTING: "Existente",
        },
        CAMPAIGN_SENT: {
          "ONE-SHOT": "Único",
          AUTOMATED: "Automatizado",
        },
        CAMPAIGN_ACTIVITY: {
          CLICKED: "Clicado",
          OPENED: "Aberto",
          UNSUBSCRIBED: "Não subscrito",
          BOUNCED: "Rejeitado",
          DELIVERED: "Entregue",
        },
        ACTIVITY_GRAPH: {
          BAD: "Mau",
          AVERAGE: "Médio",
          GREAT: "Ótimo",
        },
      },
    },
    BOXES: {
      MESSAGING: {
        CONVERSATION_ACTIVITY: {
          HEADER_TITLE:
            "Atividade da semana atual comparada com a da semana anterior",
          ACTIVITY_WEEK_BEFORE: "na semana anterior",
          ACTIVITY_CONVERSATIONS_TITLE: "Conversas",
          ACTIVITY_MEAN_RESPONSE_TIME_TITLE: "Tempo médio de resposta",
          ACTIVITY_WEBSITE_VISITORS_TITLE: "Visitantes do site",
        },
        SESSIONS_INITIALIZED: {
          HEADER_TITLE: "Atividade de atendimento",
          COUNTERS_NEW_LABEL: "Novas sessões",
          COUNTERS_EXISTING_LABEL: "Sessões existentes",
        },
        MESSAGES_OUTBOUND: {
          HEADER_TITLE: "Fluxo de mensagens",
          COUNTERS_NEW_LABEL: "Novas mensagens",
          COUNTERS_EXISTING_LABEL: "Mensagens existentes",
        },
        CONVERSATION_CREATED: {
          HEADER_TITLE: "Atividade da conversa",
          COUNTERS_NEW_LABEL: "Novas conversas",
          COUNTERS_EXISTING_LABEL: "Conversas existentes",
        },
        CONVERSATION_LEADERBOARD: {
          HEADER_TITLE: "Quadro de Liderança",
          LIST_CONVERSATIONS: "conversas",
        },
        CONVERSATION_RESPONSIVENESS: {
          HEADER_TITLE: "Capacidade de Resposta da Equipe",
          CALENDAR_LEGEND_MOST: "Maioria",
          CALENDAR_LEGEND_LEAST: "Minoria",
        },
        CONVERSATION_SEGMENT: {
          HEADER_TITLE: "Segmentos salvos ao longo do tempo",
          PANE_FILTER_TITLE: "Filtrar por segmento",
        },
        CONVERSATION_SHORTCUT: {
          HEADER_TITLE: "Estatísticas do Uso do Atalho",
          PANE_FILTER_TITLE: "Filtrar por melhores etiquetas",
        },
        CONVERSATION_ASSIGNED: {
          HEADER_TITLE: "Conversas Designadas Por Operador",
          PANE_FILTER_ALL: "Todos os operadores",
        },
        VISITOR_TRIGGER: {
          HEADER_TITLE: "Gatilhos Acionados",
          PANE_FILTER_TITLE: "Filtrar por gatilhos",
        },
      },
      CONTACTS: {
        VISITOR_HEATMAP: {
          HEADER_TITLE: "Mapa de Calor da Localização do Visitante",
          PANE_TOP_TITLE: "Principais países",
          PANE_TOP_VISITORS: "visitantes",
        },
        VISITOR_CALENDAR: {
          HEADER_TITLE: "Calendário de Visitas do Site (por dia e hora)",
        },
        PEOPLE_CREATED: {
          HEADER_TITLE: "Contatos Criados ao Longo do Tempo",
        },
      },
      RATING: {
        PEOPLE_RATING_ACTIVITY: {
          HEADER_TITLE: "Classificações Comparadas com as da Semana Passada",
          ACTIVITY_LAST_WEEK: "semana passada",
          ACTIVITY_NO_COMMENT: "algumas classificações não têm comentários",
          ACTIVITY_NO_RATINGS: "nenhuma avaliação ainda",
          ACTIVITY_MEAN_SCORE_TITLE: "pontuação média",
          ACTIVITY_COMMENTS_TITLE: "Classificações",
        },
        PEOPLE_RATING: {
          HEADER_TITLE: "Mudança Geral da Classificação ao Longo do Tempo",
        },
        PEOPLE_RATING_COMMENT: {
          HEADER_TITLE: "Comentários e Classificações mais Recentes",
          MEAN_LABEL: "Pontuação Média",
          DATATABLE_CONTROLS_SEARCH: "Procurar",
          DATATABLE_HEAD_FULL_NAME: "Nome Completo",
          DATATABLE_HEAD_SCORE: "Pontuação",
          DATATABLE_HEAD_COMMENT: "Comentário",
          DATATABLE_HEAD_ASSIGNED_OPERATOR: "Designado",
          DATATABLE_HEAD_REPORTED_ON: "Reportado Sobre",
          DATATABLE_ROW_NO_IDENTITY: "Usuário desconhecido",
          DATATABLE_ROW_NO_COMMENT: "Sem comentários",
          DATATABLE_ROW_NO_ASSIGNED: "Nenhum operador",
          DATATABLE_ROW_PREVIEW_CONVERSATION: "Pré-visualização",
        },
      },
      CAMPAIGNS: {
        CAMPAIGN_SENT: {
          HEADER_TITLE: "Enviar E-mails da Campanha",
          PANE_FILTER_TITLE: "Filtrar tipos de campanha",
          CHOICE_LABELS: {
            "ONE-SHOT": "Campanha única",
            AUTOMATED: "Campanha automatizada",
          },
        },
        CAMPAIGN_ACTIVITY: {
          HEADER_TITLE: "Atividade do E-mail da Campanha",
          PANE_FILTER_TITLE: "Filtrar por atividade",
          CHOICE_LABELS: {
            CLICKED: "Hiperligação clicada",
            OPENED: "E-mail Aberto",
            UNSUBSCRIBED: "Cancelar Subscrição do E-mail",
            BOUNCED: "E-mail Devolvido",
            DELIVERED: "E-mail Enviado",
          },
        },
      },
      HELPDESK: {
        HELPDESK_FEEDBACK: {
          HEADER_TITLE: "Comentários nos artigos da Central de Ajuda",
          SMILES_LABEL: "Feedback",
          SMILES_AGGREGATE_HELPFUL: "Útil",
          SMILES_AGGREGATE_UNHELPFUL: "Inútil",
          DATATABLE_HEAD_ARTICLE: "Artigo",
          DATATABLE_HEAD_RATING: "Avaliação",
          DATATABLE_HEAD_COMMENT: "Comentário",
          DATATABLE_HEAD_SUBMITTED_BY: "Submetido por",
          DATATABLE_ROW_NO_IDENTITY: "Usuário desconhecido",
          DATATABLE_ROW_NO_COMMENT: "Sem comentário",
          DATATABLE_ROW_PREVIEW_CONVERSATION: "Pré-visualização",
        },
        HELPDESK_READ: {
          HEADER_TITLE: "Estatísticas dos artigos da Central de Ajuda",
        },
        HELPDESK_SEARCH: {
          HEADER_TITLE: "Buscas de artigos da Central de Ajuda",
        },
      },
      STATUS: {
        STATUS_DOWNTIME: {
          HEADER_TITLE: "Estatísticas do Tempo de Inatividade do Nó",
          PANE_FILTER_ALL: "Todos os nós",
        },
        STATUS_LATENCY: {
          HEADER_TITLE: "Latência dos Nós Enviados",
          PANE_FILTER_ALL: "Todos os nós",
        },
      },
    },
  },
  SETTINGS: {
    COMMON: {
      SETUP: {
        RECORDS_TITLE: "Instruções personalizadas de configuração de domínio:",
        RECORDS_STEP_FIRST: "Entre no seu administrador de DNS para",
        RECORDS_STEP_RECORD_1: "Adicionar",
        RECORDS_STEP_RECORD_2: "entrada DNS para",
        RECORDS_STEP_RECORD_3: "com valor",
        RECORDS_STEP_LAST_1:
          "Aguarde a propagação do DNS (isto pode demorar algumas horas). Use o",
        RECORDS_STEP_LAST_2: "botão abaixo.",
        NONE_TITLE:
          "Este domínio não requer nenhuma configuração (domínio padrão fornecido pela Reinvent).",
        CONTROLS_STATUS_PENDING:
          "Este domínio ainda não foi configurado. Veja as instruções de configuração.",
        CONTROLS_STATUS_LIVE: "Este domínio está sendo usado no seu site.",
        CONTROLS_STATUS_UPDATE:
          "Você mudou o domínio. Clique em usar para ver as instruções de configuração.",
        CONTROLS_ACTION_VERIFY: "Verificar as configurações do domínio",
        CONTROLS_ACTION_VIEW: "Ver conectado",
        CONTROLS_ACTION_AUTOCONFIGURE: "Configurar automaticamente",
        CONTROLS_ACTION_USE: "Usar este domínio",
        RECORDS_STEP_RECORD_MX_PRIORITY: "com prioridade",
        RECORDS_STEP_RECORD_MX_VALUE: "e valor",
      },
      CREDIT_CARD: {
        VALID: "Válido até",
        EXPIRED: "Expirou em",
        INVALID: "Cartão Inválido",
        UNLINK: "Desassociar cartão",
      },
    },
    NAVIGATION: {
      SECTIONS: {
        ACCOUNT_TITLE: "Conta",
        ACCOUNT_LABEL: "Avatar, nome, e-mail, senha",
        NOTIFICATIONS_TITLE: "Notificações",
        NOTIFICATIONS_LABEL: "E-mail, computador, celular",
        AVAILABILITY_TITLE: "Disponibilidade",
        AVAILABILITY_LABEL:
          "Quando os visitantes podem ver que você está conectado",
        BILLING_TITLE: "Pagamento e Faturas",
        BILLING_LABEL: "Assinatura, cartões e boletos",
        PLANS_TITLE: "Planos",
        PLANS_LABEL: "Ter mais funcionalidades do Reinvent",
        WEBSITES_TITLE: "Configurações do Site",
        WEBSITES_LABEL: "Administrar seu site",
        SHORTCUTS_TITLE: "Atalhos",
        SHORTCUTS_LABEL: "Salve mensagens rápidas",
        HELPDESK_TITLE: "Central de Ajuda",
        HELPDESK_LABEL: "Gerenciar Central de Ajuda",
        STATUS_TITLE: "Página de Status",
        STATUS_LABEL: "Gerenciar sua página de status",
      },
      EXTRAS: {
        HELP_TRANSLATE_CLIENT: "Ajude a traduzir a janela de chat",
        GET_HELP: "Falar com suporte",
        STATUS_PAGE: "Status do sistema",
      },
    },
    CONTENT: {
      HEAD: {
        SAVE: "Salvo automaticamente",
        SAVING: "A salvar as alterações…",
      },
      BOX: {
        FOOTER_HELP: "Ajuda",
      },
    },
    ACCOUNT: {
      TITLE: "Configurações da Conta",
      DESCRIPTION_1_1:
        "Apenas seu primeiro nome é visível para os usuários. O sobrenome nunca é exibido.",
      DESCRIPTION_1_2:
        "O seu email é usado para enviar notificações. O seus visitantes não o verão.",
      DESCRIPTION_1_3:
        "Um número de telefone pode ser usado para fins de recuperação de conta. É necessário para o 2-Step.",
      FIRST_NAME_LABEL: "Primeiro Nome",
      FIRST_NAME_PLACEHOLDER: "Insira o seu primeiro nome",
      LAST_NAME_LABEL: "Sobrenome",
      LAST_NAME_PLACEHOLDER: "Digite seu sobrenome",
      EMAIL_LABEL: "E-mail",
      EMAIL_PLACEHOLDER: "Insira o seu e-mail",
      PHONE_LABEL: "Celular",
      PHONE_PLACEHOLDER: "Insira um número (ex. +16281334567)",
      AVATAR_LABEL: "Avatar",
      AVATAR_INSTRUCTIONS: "Imagem, max. 5MB",
      PASSWORD_LABEL: "Senha",
      PASSWORD_PLACEHOLDER: "Digite sua nova senha",
      CHANGE_PASSWORD: "Mudar a senha",
      TOKEN_LABEL: "2 etapas",
      ENABLE_TOKEN: "Ativar a Autenticação de Dois Fatores",
      DISABLE_TOKEN: "Desativar a Autenticação de Dois Fatores",
      PHONE_REQUIRED_LABEL: "É necessário um número de telefone para o 2-Step.",
      DELETE_1: "A tentar remover a sua conta da Reinvent?",
      DELETE_2: "Remova a sua conta",
      DELETE_3: "aqui.",
      HELP_1:
        "# Os meus utilizadores vêem o meu nome completo?\nOs seus utilizadores **apenas vêem o seu primeiro nome**, quer recebam uma resposta sua através do chat ou do e-mail. O seu último nome é **mantido em privado** e só pode ser visto por outros operadores na sua equipa.\n----\n# Para que é usado o meu e-mail?\nO seu endereço de e-mail é usado para lhe mandar **notificações de mensagens  não lidas** dos visitantes. Você pode desativar as notificações para o e-mail a qualquer momento em _Notifications_settings.\n----\n# Para que é usado o meu avatar?\nA foto do seu avatar é **vista pelos seus visitantes**, assim como por outros operadores. É usada para identificá-lo visualmente e colocar uma cara humana na sua janela de chat e nos e-mails.\n----\n# Porque é que ainda vejo o meu avatar depois de o ter removido?\nSe nenhum avatar for definido, **o Reinvent usa o seu Gravatar**, baseado no seu e-mail. Caso já use o Gravatar e tenha ligado uma imagem ao seu e-mail no Gravatar, o Reinvent vai usar esta como padrão.\n----\n# Notas sobre a segurança da palavra-passe\nNós aconselhamo-lo a **usar uma palavra-passe forte**. Tenha em consideração que o Reinvent leva muito a sério a segurança, e os nossos sistemas são resistentes contra ataques externos a palavras-passe. As palavras-passe são armazenadas criptografadas.",
      SUBTITLE_2: "Segurança da Conta",
      DESCRIPTION_2:
        "Gerenciar aplicações conectadas à sua conta da Reinvent. Rever o histórico da sessão.",
      SECURITY_COMMON_EMPTY: "Nenhuma sessão encontrada",
      SECURITY_COMMON_FROM: ", de",
      SECURITY_COMMON_LOCATION_UNKNOWN: "Localização desconhecida",
      SECURITY_APPS_TITLE: "Aplicações autorizadas",
      SECURITY_APPS_CLOSE: "Fechar sessão",
      SECURITY_HISTORY_TITLE: "Histórico de acessos recentes",
      SECURITY_HISTORY_STATE_AUTHORIZED: "Autorizado",
      SECURITY_HISTORY_STATE_CLOSED: "Fechado",
      HELP_2:
        "# O que devo fazer se vir uma aplicação que não reconheço?\n**Se você vir uma aplicação de que não se lembra de se ter autenticado, feche a sessão imediatamente**. De seguida, altere a palavra-passe da sua contada Reinvent; pois a sua conta pode ter sido comprometida (por exemplo, alguém sabia a sua senha). Além disso, certifique-se que configura a Autenticação de Dois Fatores para obter mais segurança.",
      POPUPS_CHANGE_EMAIL_QUESTION: "Mudar o seu e-mail agora?",
      POPUPS_CHANGE_EMAIL_LABEL: "O seu e-mail será mudado para:",
      POPUPS_CHANGE_EMAIL_PASSWORD_LABEL: "Por favor identifique-se",
      POPUPS_CHANGE_EMAIL_PASSWORD_PLACEHOLDER:
        "Para mudar o e-mail, digite sua senha…",
      POPUPS_CHANGE_EMAIL_PROCEED: "Mudar o e-mail",
      POPUPS_CHANGE_PASSWORD_QUESTION: "Mudar sua senha agora?",
      POPUPS_CHANGE_PASSWORD_PASSWORD_LABEL: "Por favor identifique-se",
      POPUPS_CHANGE_PASSWORD_PASSWORD_PLACEHOLDER: "Digite sua senha antiga…",
      POPUPS_CHANGE_PASSWORD_LABEL:
        "Você será desconectado para entrar com a nova senha.",
      POPUPS_CHANGE_PASSWORD_PROCEED: "Mudar Senha",
      POPUPS_DISABLE_TOKEN_QUESTION:
        "Desativar a Autenticação de Dois Fatores?",
      POPUPS_DISABLE_TOKEN_LABEL:
        "O código de autenticação de dois fatores não será mais solicitado. Com isso, sua conta estará mais vulnerável ao roubo de senhas.",
      POPUPS_DISABLE_TOKEN_PASSWORD_LABEL: "Por favor identifique-se",
      POPUPS_DISABLE_TOKEN_PASSWORD_PLACEHOLDER:
        "Para desativar seu token, digite sua senha…",
      POPUPS_DISABLE_TOKEN_PROCEED: "Desativar Token",
      POPUPS_DELETE_ACCOUNT_CONFIRM_LABEL: "Confirmar a exclusão de conta",
      POPUPS_DELETE_ACCOUNT_CONFIRM_PLACEHOLDER:
        "Insira o seu endereço de e-mail…",
      POPUPS_DELETE_ACCOUNT_EMPHASIS_1:
        "Todas as informações da conta serão permanentemente apagadas e não poderão ser recuperadas.",
      POPUPS_DELETE_ACCOUNT_EMPHASIS_2:
        "Não há retorno depois de clicar em eliminar.",
      POPUPS_DELETE_ACCOUNT_PASSWORD_LABEL: "Por favor identifique-se",
      POPUPS_DELETE_ACCOUNT_PASSWORD_PLACEHOLDER:
        "Para excluir sua conta, digite sua senha…",
      POPUPS_DELETE_ACCOUNT_PROCEED: "Eliminar a Minha Conta Reinvent",
      POPUPS_CLOSE_SESSION_QUESTION: "Fechar agora esta sessão?",
      POPUPS_CLOSE_SESSION_LABEL:
        "A aplicação conectada a esta sessão não vai continuar a ter acesso à sua contada Reinvent, e vai ter de voltar a iniciar sessão para usar a Reinvent.",
      POPUPS_CLOSE_SESSION_PROCEED: "Fechar Sessão",
      LANGUAGE_LABEL: "Idioma",
      LANGUAGE_DEFAULT: "Auto-Deteção",
    },
    NOTIFICATIONS: {
      TITLE: "Configurações das Notificações",
      DESCRIPTION_1: "Escolha como quer administrar as suas notificações.",
      DESCRIPTION_2:
        "Não é recomendado desativá-las, dado que não vai receber notificações quando receber novas mensagens.",
      DISABLED: "Desativar todas as notificações",
      MESSAGES_ONLINE:
        "Notificar mensagens quando eu estiver conectado (notificações push)",
      ON_SESSION_QUEUED: "Notificar novos atendimentos na fila",
      MESSAGES_OFFLINE:
        "Notificar-me das mensagens quando estiver desligado (notificações push)",
      MESSAGES_TRANSCRIPT: "Enviar transcrições das conversas para meu e-mail",
      MESSAGES_RATING: "Enviar as avaliações dos usuários para meu e-mail",
      MESSAGES_SOUNDS: "Reproduzir os sons das notificações",
      ALLOW_SOUNDS: "Reproduzir os sons das notificações",
      HELP: "# O que são transcrições?\nAs transcrições permitem que os **registros completos das conversas fiquem arquivadas em seu e-mail**. Isso pode ser útil se precisar de reencaminhar o histórico da conversa para alguém que não está autorizado a usar o seu painel de controleda Reinvent.\n----\n# Para qual endereço de e-mail as notificações são enviadas ?\nO Reinvent envia as notificações para o e-mail configurado na sua conta.",
      BILLING_INVOICE:
        "Enviar faturas pagas para meu e-mail (apenas nos planos pagos)",
      VISITOR_BROWSING:
        "Avisar quando alguém navegar no meu site (notificação push)",
      MESSAGES_UNREAD: "Enviar-me por e-mail mensagens não lidas",
      ALERT_DENIED_MESSAGE:
        "Parece que as notificações estão desativadas no seu navegador.",
      ALERT_DENIED_ACTION: "Como reativar notificações?",
    },
    AVAILABILITY: {
      TITLE: "Configurações de Disponibilidade",
      DESCRIPTION_1:
        "Defina-se como disponível no horário, configurando dias e horários (no seu fuso horário). Os visitantes vão vê-lo como ausente fora do horário programado, mas ainda poderão enviar-lhe mensagens.",
      DESCRIPTION_2:
        "Se seu site tem vários operadores, o chat será visto como conectado se houver pelo menos um operador disponível e ausente se todos estiverem indisponíveis.",
      CURRENTLY_SEEN: "Atualmente é visto como:",
      ONLINE: "Conectado",
      OFFLINE: "Desligado",
      STEALTH: "Oculto (modo invisível)",
      PRESENCE: "Defir-me disponível ao usar a aplicação",
      ENABLED: "Ativar horário de disponibilidade",
      DAYS: "Dias",
      DAYS_LONG: {
        MONDAY: "Segunda",
        TUESDAY: "Terça",
        WEDNESDAY: "Quarta",
        THURSDAY: "Quinta",
        FRIDAY: "Sexta",
        SATURDAY: "Sábado",
        SUNDAY: "Domingo",
      },
      DAYS_SHORT: {
        MONDAY: "Seg",
        TUESDAY: "Ter",
        WEDNESDAY: "Qua",
        THURSDAY: "Qui",
        FRIDAY: "Sex",
        SATURDAY: "Sab",
        SUNDAY: "Dom",
      },
      HOURS: "Horas",
      ADD_INTERVAL: "Adicionar um intervalo",
      HELP: "# O que é o \\'modo invisível\\'?\nO modo invisível permite que **force uma disponibilidade desligada** indefinidamente, até que a desative. É útil se for passar férias prolongadas e não desejar alterar as configurações de programação.\n\nVocê pode alternar rapidamente o modo invisível na barra lateral, clicando no ícone do site e selecionando a opção do modo invisível. O Reinvent mostrará um lembrete de que você está desligado, para não se esquecer.\n-----\n# O que é \"definir-me disponível ao usar a aplicação\"?\nEssa opção permite que **apareça ligado quando estiver a usar ativamente uma aplicaçãoda Reinvent**, mesmo se estiver fora do horário.\n-----\n# Como funciona o horário?\nA programação permite definir **dias e horários automáticos ligados e desligados**. É útil se você tiver horários fixos de trabalho e não usar ativamente aplicações do Reinvent o dia todo. Por exemplo, se desejar aparecer ligado e responder pelo telefone. A configuração é salva e aplicada em relação ao seu **fuso horário local**.\n-----\n# Quando é que os meus websites aparecerão como ligados?\nOs websites dos quais você é membro aparecerão ligados se **pelo menos** um operador membro estiver ligado.\nPor outras palavras, se estiver ligado de acordo com as configurações de disponibilidade, os websites dos quais você é membro aparecerão ligados. Se não deseja aparecer ligado temporariamente, pode ativar o modo invisível.",
      TIMEZONE: "Fuso horário",
    },
    BILLING: {
      TITLE: "Pagamento e Faturas",
      DEFAULT: {
        ALERT_CARD_INVALID:
          "Seu método de pagamento é inválido ou expirou. Por favor atualize-o para manter as assinaturas ativas.",
        ALERT_CARD_NONE:
          "Não há nenhum método de pagamento. Adicione um método de pagamento para começar a adicionar assinaturas aos seus sites!",
        PAID_TITLE: "Este é o valor que você pagará na próx. renovação",
        PAID_DESCRIPTION:
          "O regime de pagamento é pré-pago e renovado automaticamente todo mês",
        DESCRIPTION_1:
          "Você pode cancelar ou assinar quando quiser. Sem compromisso.",
        DESCRIPTION_2:
          "Você também pode cadastrar vários métodos de pagamento, cobraremos apenas no que estiver definido como padrão.",
        CARD_PAYMENT_METHOD: "Cartão",
        CARD_IDENTITY_VALUE_OWNER: "Titular:",
        CARD_IDENTITY_VALUE_ADDRESS: "Endereço:",
        CARD_IDENTITY_VALUE_VAT: "CPF/CNPJ:",
        CARD_IDENTITY_VALUE_EMPTY: "Não informado",
        CARD_ACTIONS_SEE_INVOICES: "Ver faturas",
        CARD_ACTIONS_REMOVE_CARDS: "Remover cartão",
        CARD_LINKS_LINKED_TO_NONE:
          "Este cartão não está vinculado a nenhum site",
        CARD_LINKS_LINKED_TO_1: "Este cartão está vinculado a",
        CARD_LINKS_LINKED_TO_2: "sites",
        CARD_LINKS_SEE: "Ver sites vinculados",
        CARD_ADD_NEW_METHOD: "Adicionar método de pagamento",
        POPUPS_REMOVE_CARD_QUESTION: "Remover este método de pagamento?",
        POPUPS_REMOVE_CARD_LABEL:
          "Antes de prosseguir, verifique se o cartão está vinculado a algum site.",
        POPUPS_REMOVE_CARD_PROCEED: "Remover Método de Pagamento",
        HELP: "# Com que frequência é feito o pagamento?\nOs pagamentos são feitos no ** nício de cada mês** para os planos nos quais está subscrito.\nOs pagamentos atempados garantem um serviço ininterrupto.\n-----\n# Posso cancelar um período avaliação e não ser cobrado?\nSim! Ao não cadastrar um método de pagamento você não será cobrado quando a avaliação expirar.\n-----\n# E se um pagamento falhar?\nSe um pagamento falhar, você será **notificado por email**. ** Repetiremos várias vezes ao longo de uma semana** e enviaremos mais e-mails se esses também falharem. Se todas as novas tentativas falharem, as assinaturas pagas com o método de pagamento com falha serão suspensas após uma semana.\n\nObviamente, você pode **reativar os planos mais tarde** quando atualizar as suas informações de pagamento.",
        CARD_ACTIONS_EDIT: "Editar",
        SEE_ALL_INVOICES: "Ver todas as faturas",
      },
      INVOICES: {
        TITLE: "Faturas",
        DESCRIPTION_1: "Estas são as suas faturas para a forma de pagamento",
        DESCRIPTION_2:
          "Você pode baixar estas faturas, para manter em seu arquivo.",
        NO_INVOICES_TITLE: "Não há faturas para este método de pagamento.",
        NO_INVOICES_HINT:
          "Procurando as faturas do site? Vá nas configurações do site e verifique a seção Cobranças. Lá aparece o e-mail do responsável. Autentique-se nessa conta Reinvent para recuperar todas as suas faturas.",
        INVOICE_REFUNDED: "Reembolsado",
        INVOICE_CANCELED: "Cancelada",
        INVOICE_PAID: "Pago",
        INVOICE_UNPAID: "Não Pago",
        INVOICE_STATISTICS_EMPTY: "Fatura vazia",
        INVOICE_STATISTICS_WEBSITE_SINGULAR: "website",
        INVOICE_STATISTICS_WEBSITE_PLURAL: "websites",
        INVOICE_STATISTICS_PLUGIN_SINGULAR: "plugin",
        INVOICE_STATISTICS_PLUGIN_PLURAL: "plugins",
        INVOICE_STATISTICS_LIMIT_SINGULAR: "limite",
        INVOICE_STATISTICS_LIMIT_PLURAL: "limites",
        INVOICE_TOTAL: "Valor:",
        INVOICE_DOWNLOAD: "Comprovante",
        INVOICE_OLDER: "Mostrar faturas anteriores…",
        HELP: "# Quando são geradas as faturas?\nAs faturas são geradas **antes do pagamento**. Se o pagamento for bem-sucedido, o comprovante de pagamento ficará disponível.\n-----\n# Por quanto tempo estão disponíveis as faturas?\n**As faturas são mantidas para sempre **,você ainda poderá recuperá-las posteriormente.",
      },
    },
    PLANS: {
      TITLE: "Planos",
      ALERT_PAYMENT_GO_TO_BILLING: "Vá para configurações de cobrança",
      ALERT_PAYMENT_NO_CARD:
        "Você não possui nenhum método de pagamento. Adicione um para subscrever aos recursos pagos.",
      SUBTITLE_1: "Gerenciar todos os planos do site",
      DESCRIPTION_1: "Gerenciar todas as assinaturas de planos.",
      HELP_1:
        '# Posso atualizar uma subscrição em andamento?\nSim! **Você pode atualizar a sua subscrição em andamento a qualquer momento**. Você será cobrado apenas no início do próximo mês.\n-----\n# O que acontece quando o meu teste terminar?\nQuando a sua avaliação expirar, **o plano é convertido automaticamente para um plano regular**. O seu método de pagamento é cobrado no início de cada mês.\n\nSe você não quiser manter esse plano, cancele a inscrição antes do final do teste (você não será cobrado).\n-----\n# Eu tenho um cupão. Como faço para aplicá-lo?\nVocê pode **aplicar o seu cupão a qualquer momento num website à sua escolha**, clicando no botão "Cupão". Se ele não aparecer, significa que você já aplicou um cupão para o website (os cupões não podem ser acumulados num website).',
      SUBTITLE_2: "Assinaturas",
      DESCRIPTION_2:
        "Veja o resumo de todas as suas assinaturas, e a discriminação de quanto você paga.",
      HELP_2:
        "# O que são assinaturas?\nAs assinaturas lhe fornecem **extra recursos para aproveitar ao máxima Reinvent**. Elas são pagas mensalmente ou anualmente. Podem ser planos, plugins, ou limites aumentados.",
      REDEEM_COUPON: "Cupão",
      FREE: "Grátis",
      PER_MONTH: "/mês",
      TRIAL_TO: "Teste para",
      ACTIVE: "Ativo",
      INACTIVE: "Inativo",
      TRIAL: "Teste",
      UPDATE_PAYMENT: "Atualizar Pagamento",
      POPUPS_REDEEM_COUPON_CODE_LABEL: "Código do cupão",
      POPUPS_REDEEM_COUPON_CODE_PLACEHOLDER: "Inisra o código do cupão…",
      POPUPS_REDEEM_COUPON_PROCEED: "Aplicar o Cupão",
      RENEWS_ON: "Renova em",
      SANDBOX_MODE: "Caixa de areia",
      PAID_MONTHLY: "pago mensalmente",
      PAID_YEARLY: "pagos anualmente",
      PERIOD_MONTHLY: "Mensalmente",
      PERIOD_YEARLY: "Anualmente",
      PER_YEAR: "/ano",
      DETAILS_PLANS: "Planos",
      DETAILS_PLUGINS: "Módulo de Extensão",
      DETAILS_LIMITS: "Limites",
      MANAGE_PLANS: "Plano de mudança",
      MANAGE_PLUGINS: "Gerenciar extensão",
      MANAGE_LIMITS: "Gerenciar limites",
      MANAGES_PLAN: "administra este plano",
      CHANGE_BILLING_OWNER: "Alterar responsável pelo pagamento",
      POPUPS_BILL_PERIOD_QUESTION: "O período de cobrança será mudado para:",
      POPUPS_BILL_PERIOD_CONFIRM:
        "Por favor digite a seguinte palavra para confirmar que quer alterar o período de cobrança:",
      POPUPS_BILL_PERIOD_PROCEED: "Mudar o período de cobrança para",
      CHANGE_PLAN: "Mudar o Pano",
    },
    WEBSITES: {
      TITLE: "Configurações do Site",
      SUBTITLE: "Participações em sites",
      DESCRIPTION_PART_1: "Estes são os sites dos quais você é membro.",
      DESCRIPTION_PART_2: "Selecione um site para configurar.",
      INTEGRATIONS: "Integrações",
      ADD_NEW_WEBSITE: "Adicionar novo site",
      POPUPS_NEW_WEBSITE_NAME_LABEL: "Nome do site",
      POPUPS_NEW_WEBSITE_NAME_PLACEHOLDER:
        "Insira o nome do site (ex. Site da Empresa)",
      POPUPS_NEW_WEBSITE_DOMAIN_LABEL: "Domínio do site",
      POPUPS_NEW_WEBSITE_DOMAIN_PLACEHOLDER:
        "Insira o domínio do site (ex. empresa.com)",
      POPUPS_NEW_WEBSITE_PROCEED: "Criar Site",
      POPUPS_NEW_WEBSITE_CONFIRM_QUESTION:
        "O domínio deste site já existe no Reinvent",
      POPUPS_NEW_WEBSITE_CONFIRM_NOTICE:
        "Pode estar a duplicar um site que já existe na Reinvent, o que pode criar confusão sobre qual dos sites é o correto. O site pode existir na sua própria conta Reinvent ou noutra conta Reinvent.",
      POPUPS_NEW_WEBSITE_CONFIRM_PROCEED: "Sim, Criar Site",
      HELP: "# O que é um site?\nUm site é um grupo **Reinvent para uma equipa de operadores**. Cada site tem as suas integrações específicas (por exemplo, código da janela de chat). Pode gerir vários websites na mesma contada Reinvent.\n-----\n# Quantos sites posso criar?\nNão há limite para a quantidade de sites que pode criar e gerir numa única contada Reinvent.\n-----\n# Como funcionam os planos com sites?\n**Uma assinatura do plano está vinculada a um siteda Reinvent**. Isto significa que pode possuir sites com um plano pago, bem como websites gratuitos, na mesma contada Reinvent.",
      HELP_1:
        "# Oque são motivos de encerramento?\nMotivos de encerramento **são assuntos de atendimentos**, eles são utilizados por operadores para classificar cada tipo de atendimento antes de encerrar, isso é muito útil e importante para métricas de equipes de atendimento.\n----\n# Como encaminhar atendimentos automaticamente?\nPara **distribuir conversas automaticamente entre os operadores** você pode criar regras de atribuição automática, estas regras são executadas todas as vezes que um cliente solicitar contato com a sua equipe de atendimento humano, as regras tem prioridades e definem como as conversas devem ser encaminhadas, utilize tags para distribuir atendimentos.",
      SETTINGS: "Configurações",
    },
    WEBSITE: {
      TITLE: "Configurações do Site",
      ALERT_NOT_OWNER:
        "Você não é proprietário deste site. Não é possível alterar as configurações.",
      ALERT_CARD_INVALID:
        "O método de pagamento deste site é inválido ou está vencido. Favor conferir.",
      ALERT_CARD_NONE:
        "Nenhum método de pagamento está associado a este site. Cadastre um método para manter sua assinatura.",
      ALERT_EMAIL_DOMAIN_INVALID:
        "Houve uma falha na última verificação do seu domínio de e-mail personalizado. Verifique se os registros DNS do domínio estão corretos. A próxima verificação será feita amanhã. Uma vez validado novamente, isso desaparecerá.",
      ALERT_HIDE_VACATION:
        "A janela de chat está oculta, porque sua equipe está de folga.",
      SUBTITLE_1: "Informações do Site",
      DESCRIPTION_1:
        "Configure as informações do seu site. Isso define a forma de apresentação do site aos seus usuários.",
      INFORMATION_WEBSITE_DOMAIN_LABEL: "Domínio do site",
      INFORMATION_WEBSITE_DOMAIN_PLACEHOLDER: "empresa.com",
      INFORMATION_WEBSITE_NAME_LABEL: "Nome do site",
      INFORMATION_WEBSITE_NAME_PLACEHOLDER: "Empresa Ltda.",
      INFORMATION_WEBSITE_LOGO_LABEL: "Ícone do site",
      INFORMATION_WEBSITE_LOGO_INSTRUCTIONS: "Imagem, max. 5MB",
      HELP_1:
        "# Para que é usado o nome do site?\nO nome do site é **visível na janela de chat**, assim como **nos e-mails** enviados para os seus usuários. Pode utilizar o nome completo do seu site (ex. Acme).\n----\n# Para que é usado o domínio do site?\nO site é usado em **hiperligações enviadas para os seus utilizadores** em e-mails, para que eles possam aceder ao seu website e retomar a sua sessão de conversa. É também usado para **obter o logotipo de seu site**.\n\nO domínio não é utilizado para restringir a janela de conversa ao seu website por padrão, mas pode ativar essa opção nas preferências abaixo.\nUma vez ativas, o domínio do website será usado para restringir a janela de conversa aos seus website, por razões de segurança. O Reinvent cruza todos os subdomínios do seu domínio, por isso assegure-se de que introduz aqui o domínio base (ex. acme.com, não www.acme.com).\n---\n# Porque é que ainda vejo um logotipo quando removi o anterior?\nSe nenhum logótipo for definido, **o Reinvent usa o website Favicon**.",
      SUBTITLE_2: "Instruções de configuração",
      DESCRIPTION_2:
        "Encontrar instruções sobre como configurar a janela de chat ou o redirecionamento do e-mail.",
      WEBSITE_ID_LABEL: "ID do site",
      WEBSITE_ID_COPY_BUTTON: "Copiar",
      SETUP_CHATBOX_LABEL: "Janela de chat",
      SETUP_CHATBOX_BUTTON: "Instruções para a configuração da janela de chat",
      HELP_2:
        "# Com qual CMS é a janela de chat do Reinvent compatível?\n**O Reinvent é CMS-agnóstico**, o que significa que pode instalar a janela de chat do Reinvent em qualquer lado e irá funcionar, desde que insira o nosso JavaScript nos seus modelos.",
      SUBTITLE_3: "Operadores na equipe",
      DESCRIPTION_3: "Adicione as pessoas da equipe do site.",
      OPERATORS_AVAILABILITY_SEEN_1: "Suporte é",
      OPERATORS_AVAILABILITY_SEEN_2_ONLINE:
        "quando pelo menos um operador estiver conectado.",
      OPERATORS_AVAILABILITY_SEEN_2_OFFLINE:
        "quando todos os operadores estiverem desconectados.",
      OPERATORS_AVAILABILITY_ONLINE: "Conectado",
      OPERATORS_AVAILABILITY_OFFLINE: "Desconectado",
      OPERATORS_AVAILABILITY_EMPTY_LAST_ACTIVE: "Limpar Último Ativo",
      OPERATORS_AVAILABILITY_ADD_OPERATOR: "Convidar Operador",
      OPERATORS_PENDING_INVITE: "Convite pendente",
      OPERATORS_SANDBOX_DEVELOPER: "Desenvolvedor de módulos de extensão",
      OPERATORS_INVITED: "Convidado",
      OPERATORS_MARKETPLACE: "Marketplace",
      OPERATORS_OWNER: "Proprietário",
      OPERATORS_MEMBER: "Membro",
      POPUPS_EMPTY_LAST_ACTIVE_QUESTION:
        "Quer mesmo limpar a lista dos últimos operadores ativos?",
      POPUPS_EMPTY_LAST_ACTIVE_LABEL:
        "A lista dos últimos operadores na janela de chat será limpa.",
      POPUPS_EMPTY_LAST_ACTIVE_PROCEED: "Limpar Últimos Ativos",
      POPUPS_ADD_OPERATOR_ROLE_LABEL: "Cargo do operador",
      POPUPS_ADD_OPERATOR_ROLE_OWNER: "Proprietário",
      POPUPS_ADD_OPERATOR_ROLE_MEMBER: "Membro",
      POPUPS_ADD_OPERATOR_EMAIL_LABEL: "E-mail do operador",
      POPUPS_ADD_OPERATOR_EMAIL_PLACEHOLDER:
        "Digite o e-mail do operador que você quer convidar…",
      POPUPS_ADD_OPERATOR_PASSWORD_LABEL: "Por favor identifique-se",
      POPUPS_ADD_OPERATOR_PASSWORD_PLACEHOLDER:
        "Digite sua senha para continuar…",
      POPUPS_ADD_OPERATOR_PROCEED: "Convidar um Operador",
      POPUPS_ADD_OPERATOR_UPGRADE_PLAN_TITLE: "Por favor, atualize seu plano",
      POPUPS_ADD_OPERATOR_UPGRADE_PLAN_DESCRIPTION:
        "Você atingiu seu limite atual de operadores do plano. Por favor, siga nossas instruções para atualizar para um plano superior.",
      POPUPS_ADD_OPERATOR_UPGRADE_PLAN_PROCEED: "Ver Opções de planos",
      POPUPS_ADD_OPERATOR_UPGRADE_LIMIT_DESCRIPTION:
        "Você atingiu seus limites de operador. Você pode adicionar mais operadores a qualquer momento, aumentando seus limites. Por favor, siga nossas instruções para atualizar para limites mais altos.",
      POPUPS_ADD_OPERATOR_UPGRADE_LIMIT_PROCEED:
        "Aumentar os limites do operador",
      POPUPS_EDIT_OPERATOR_TITLE_LABEL: "Cargo do operador (opcional)",
      POPUPS_EDIT_OPERATOR_TITLE_PLACEHOLDER:
        "Insira um cargo … (ex. \\'Vendas\\')",
      POPUPS_EDIT_OPERATOR_PROCEED: "Salvar associação de operador",
      POPUPS_REMOVE_OPERATOR_QUESTION: "Quer mesmo remover este operador?",
      POPUPS_REMOVE_OPERATOR_LABEL:
        "Este operador não poderá mais acessar o site.",
      POPUPS_REMOVE_OPERATOR_PROCEED: "Remover Operador",
      HELP_3:
        "# O que é que \\'Esvaziar Últimos Ativos\\' quer dizer?\nO botão de operadores Esvaziar Últimos Ativos permite-lhe **limpar a lista dos últimos operadores ativos** que os seus utilizadores podem ver na **janela de chatda Reinvent**. Em alguns casos (ex. um operador que você não quer ver nesta lista), pode querer reiniciar a lista. Use este botão para esvaziar a lista. \n\nEsta lista será preenchida com operadores ativos, assim que alguém da equipa mandar uma mensagem para um utilizador.\n----\n#Quantos operadores posso adicionar por website?\nO Reinvent restringe o número de operadores que pode adicionar por plano. **Adira ao Reinvent Pro para obter mais vagas para operadores**, e obtenha um número ilimitado de vagas com o Reinvent Unlimited. \n----\n# Como é que posso convidar novos operadores?\nVocê pode convidar novos operadores **usando o e-mail deles**. O Reinvent irá usar este e-mail para enviar um convite com uma hiperligação, cujo operador pode utilizar, quer com uma conta Reinvent já existente, quer um uma nova conta Reinvent.",
      SUBTITLE_4: "Informações de contato",
      DESCRIPTION_4:
        "Defina suas informações públicas de contato para que os visitantes possam encontrá-lo diretamente.",
      CONTACT_WEBSITE_EMAIL_LABEL: "E-mail do site",
      CONTACT_WEBSITE_EMAIL_PLACEHOLDER: "support@acme.com",
      CONTACT_WEBSITE_PHONE_LABEL: "Telefone do site",
      CONTACT_WEBSITE_PHONE_PLACEHOLDER: "+55 (91) 98765-4321",
      HELP_4:
        "# Onde os usuários podem ver as informações de contato?\nA informação de contato pode ser vista nos **e-mails das transcrições da conversa** enviados para os seus usuários, quando uma conversa estiver terminada. Isto permite que o seu usuário possa encontrá-lo diretamente.\n\nEsta informação não é obrigatória. Se não estiver definida, não aparecerá nos e-mails.",
      SUBTITLE_5: "Configurações de Chat e E-mail",
      DESCRIPTION_5: "Configure comportamento e aparência de chat e e-mails.",
      OPEN_OPTIONS: "Abrir opções",
      HIDE_OPTIONS: "Ocultar opções",
      CHATBOX_BEHAVIOR: "Comportamento da Janela de Chat",
      CHATBOX_BEHAVIOR_LAST_OPERATOR_FACE:
        "Mostrar a cara do último operador ativo no botão da janela de chat quando não houver conversas em andamento",
      CHATBOX_BEHAVIOR_ONGOING_OPERATOR_FACE:
        "Mostrar a cara do operador na janela de chat quando a conversa estiver em andamento",
      CHATBOX_BEHAVIOR_ACTIVITY_METRICS:
        "Mostrar estatísticas de atividade na janela de chat (ex: tempo médio de resposta)",
      CHATBOX_BEHAVIOR_AVAILABILITY_TOOLTIP:
        "Mostrar estatística de disponibilidade quando a janela de chat estiver fechada",
      CHATBOX_BEHAVIOR_HIDE_ON_AWAY:
        "Ocultar janela de chat se todos os operadores estiverem ausentes",
      CHATBOX_BEHAVIOR_HIDE_ON_MOBILE:
        "Ocultar janela de chat em dispositivos móveis",
      CHATBOX_BEHAVIOR_EMAIL_VISITORS: "Perguntar o e-mail dos visitantes",
      CHATBOX_APPEARANCE: "Aparência da Janela de Chat",
      CHATBOX_APPEARANCE_POSITION_REVERSE:
        "Inverter lado da janela de chat (à esquerda)",
      CHATBOX_SECURITY: "Segurança da Janela de Chat",
      CHATBOX_APPEARANCE_COLOR_THEME: "Tema das cores (da janela de chat)",
      CHATBOX_APPEARANCE_COLOR_THEME_CHOICES: {
        DEFAULT: "Padrão (Azul)",
        AMBER: "Âmbar",
        BLACK: "Preto",
        BLUE: "Azul",
        BLUE_GREY: "Azul-cinza",
        LIGHT_BLUE: "Azul-claro",
        BROWN: "Castanho",
        CYAN: "Ciano",
        GREEN: "Verde",
        LIGHT_GREEN: "Verde-claro",
        GREY: "Cinzento",
        INDIGO: "Anil",
        ORANGE: "Laranja",
        DEEP_ORANGE: "Laranja (Escuro)",
        PINK: "Rosa",
        PURPLE: "Roxo",
        DEEP_PURPLE: "Roxo (Escuro)",
        RED: "Vermelho",
        TEAL: "Esverdeado",
      },
      CHATBOX_APPEARANCE_TEXT_THEME:
        "Tema do texto (no topo da janela de chat)",
      CHATBOX_APPEARANCE_TEXT_THEME_CHOICES: {
        1: "Dúvidas? Converse comigo!",
        2: "Envie suas dúvidas",
        3: "Envie-me suas dúvidas",
        4: "Fale com o Suporte",
        DEFAULT: "Dúvidas? Fale conosco!",
      },
      CHATBOX_APPEARANCE_WELCOME_MESSAGE:
        "Mensagem de boas-vindas (primeira mensagem vista pelos usuários)",
      CHATBOX_APPEARANCE_WELCOME_MESSAGE_CHOICES: {
        1: "Olá, quer conversar conosco?",
        2: "Quer perguntar alguma coisa?",
        3: "Olá, pergunte qualquer coisa sobre nosso site.",
        4: "Olá! Como podemos te ajudar? :)",
        5: "Alguma dúvida sobre nosso site?",
        DEFAULT: "Podemos ajudá-lo a usar nosso site?",
      },
      CHATBOX_APPEARANCE_TILE:
        "Papel de parede do chat (textura das mensagens)",
      CHATBOX_APPEARANCE_TILE_CHOICES: {
        DEFAULT: "Padrão (sem papel de parede)",
      },
      CHATBOX_APPEARANCE_LOCALE:
        "Idioma da janela de chat (automático ou selecionado)",
      CHATBOX_APPEARANCE_LOCALE_AUTODETECT: "Detectar país do usuário",
      CHATBOX_APPEARANCE_CUSTOMIZATION:
        "Personalização avançada da janela de chat",
      CHATBOX_APPEARANCE_CUSTOMIZATION_GO: "Abrir extensão de personalização",
      CHATBOX_SECURITY_CHECK_DOMAIN:
        "Exibir janela de chat somente no domínio (e subdomínios) do site",
      CHATBOX_RESTRICTIONS: "Restrições da Janela de Chat",
      CHATBOX_RESTRICTIONS_HIDE_VACATION:
        "Dar folga para os operadores (ocultar janela de chat)",
      CHATBOX_RESTRICTIONS_ALLOWED_PAGES:
        "Mostrar janela de chat apenas nas páginas",
      CHATBOX_RESTRICTIONS_ALLOWED_PAGES_ADD: "Adicionar uma página permitida",
      CHATBOX_RESTRICTIONS_BLOCKED_PAGES: "Ocultar janela de chat nas páginas",
      CHATBOX_RESTRICTIONS_BLOCKED_PAGES_ADD: "Adicionar uma página bloqueada",
      CHATBOX_RESTRICTIONS_BLOCKED_COUNTRIES:
        "Ocultar janela de chat para países",
      CHATBOX_RESTRICTIONS_BLOCKED_COUNTRIES_ADD: "Adicionar um país bloqueado",
      CHATBOX_RESTRICTIONS_BLOCKED_LOCALES:
        "Ocultar janela de chat para localidades",
      CHATBOX_RESTRICTIONS_BLOCKED_LOCALES_ADD:
        "Adicionar uma localidade bloqueada",
      CHATBOX_RESTRICTIONS_BLOCKED_IPS: "Ocultar janela de chat para IPs",
      CHATBOX_RESTRICTIONS_BLOCKED_IPS_ADD: "Adicionar um IP bloqueado",
      CHATBOX_RESTRICTIONS_VISITORS_BLOCKED: "Visitantes bloqueados",
      CHATBOX_RESTRICTIONS_VISITORS_BLOCKED_CLEAR:
        "Limpar todas as regras de bloqueio (IP e e-mail)",
      EMAILS_GENERAL: "Configurações de E-mail",
      EMAILS_GENERAL_TRANSCRIPT:
        "Enviar transcrições das conversas para os usuários",
      EMAILS_GENERAL_RATING:
        "Pedir aos usuários para avaliar o site nos e-mails de transcrições",
      EMAILS_GENERAL_ENRICH:
        "Completar perfis dos contatos a partir do e-mail (ex. nome e avatar)",
      EMAILS_GENERAL_JUNK_FILTER: "Bloquear e-mails indesejados (spam)",
      INBOX_GENERAL: "Configurações da Caixa de Entrada",
      INBOX_GENERAL_LOCK_REMOVAL:
        "Restringir remoção de dados (conversas e contatos) somente aos proprietários do site",
      INBOX_GENERAL_FORCE_OPERATOR_TOKEN:
        "Exigir a ativação da Autenticação de Dois Fatores pelos operadores",
      INBOX_GENERAL_HIDE_SEND_CONFIRM_POPUP:
        'Não mostrar o pop-up de "Enviar confirmação"',
      POPUPS_ADD_ALLOWED_PAGE_URL_LABEL:
        "Permitir uma página por URL (use * para qualquer subpágina, ** para qualquer coisa)",
      POPUPS_ADD_ALLOWED_PAGE_URL_PLACEHOLDER: "empresa.com/faleconosco/*",
      POPUPS_ADD_ALLOWED_PAGE_PROCEED: "Adicionar Página Permitida",
      POPUPS_ADD_BLOCKED_PAGE_URL_LABEL:
        "Bloquear uma página por URL (use * para qualquer subpágina, ** para qualquer coisa)",
      POPUPS_ADD_BLOCKED_PAGE_URL_PLACEHOLDER:
        "empresa.com/blog/*/categoria/**",
      POPUPS_ADD_BLOCKED_PAGE_PROCEED: "Adicionar Página Bloqueada",
      POPUPS_ADD_BLOCKED_COUNTRY_CODE_LABEL: "Bloquear usuários do país",
      POPUPS_ADD_BLOCKED_COUNTRY_PROCEED: "Adicionar País Bloqueado",
      POPUPS_ADD_BLOCKED_LOCALE_CODE_LABEL: "Bloquear usuários com localidade",
      POPUPS_ADD_BLOCKED_LOCALE_PROCEED: "Adicionar Localidade Bloqueada",
      POPUPS_ADD_BLOCKED_IP_LABEL:
        "Bloquear a janela de chat para os seguintes IPs (compatível com notação CIDR)",
      POPUPS_ADD_BLOCKED_IP_PLACEHOLDER: "168.1.2.5",
      POPUPS_ADD_BLOCKED_IP_PROCEED: "Adicionar IP Bloqueado",
      HELP_5:
        "# Como testo estas configurações?\nDepois de atualizar estas configurações, **abra uma página no seu website** e você verá sua janela de chat com as configurações atualizadas.\n-----\n# Porque é que recebi um erro de código de caixa de correio inválido?\nNa maioria das vezes, esse erro ocorre quando você habilita a opção **Bloquear janela de chat no domínio do website **nas configurações de **Segurança da janela de chat** e há uma incompatibilidade entre o domínio do website configurado no Reinvent e o domínio do website em que você está a usar no seu navegador.\n\nPode **desativar esta opção** ou ajustar o domínio do seu site na Reinvent.\n-----\n# Que formato é que o bloqueador de URL reconhece?\nSe usar o recurso para ocultar a janela de chat em determinadas páginas, poderá perguntar-se qual formato de URL é reconhecido.\n\nVeja abaixo alguns formatos reconhecidos:\n\n* __acme.com__\n* __www.acme.com__\n* __/blog/artigo/__\n* __acme.com/blog/article/__\n\nObserve que você também pode **usar curingas para URLs de correspondência difusa **.",
      SUBTITLE_6: "Configurações de cobrança",
      DESCRIPTION_6:
        "Selecione o método que este site usa para pagamento de assinaturas.",
      BILLING_CARD_ADD: "Nenhum cartão disponível, cadastre pelo menos um.",
      BILLING_ACTIONS_WANT_TO:
        "Deseja adicionar ou remover cartões de pagamento?",
      BILLING_ACTIONS_GO: "Ir para Cobranças e Cartões…",
      BILLING_ACTIONS_OWNER_INVOICES: "Procurando as faturas do site?",
      BILLING_ACTIONS_OWNER_EMAIL: "Usar conta:",
      POPUPS_UNLINK_BILLING_QUESTION:
        "Tem a certeza que deseja desvincular este cartão?",
      POPUPS_UNLINK_BILLING_LABEL:
        "Você poderá substituir este cartão para manter as assinaturas ativas.",
      POPUPS_UNLINK_BILLING_PROCEED: "Desvincular Cartão",
      HELP_6:
        "# Porque é que devo atribuir um cartão de pagamento por website?\nO Reinvent permite que possua vários websites e pague subscrições (se um plano pago for assinado) usando diferentes métodos de pagamento ou o mesmo método de pagamento (por exemplo, tem um único método de pagamento).\n\nÉ por isso que deve selecionar o método de pagamento a ser usado no website aqui.",
      SUBTITLE_7: "Configurações de Conversas",
      DESCRIPTION_7:
        "Configure ajustes para o sistema de conversas como encaminhamento automático",
      DANGER_WEBSITE_REMOVAL_LABEL: "Exclusão do site",
      DANGER_WEBSITE_REMOVAL_CONFIRM: "Confirmar exclusão do site",
      POPUPS_DELETE_WEBSITE_DOMAIN_LABEL: "Confirmar Exclusão do Site",
      POPUPS_DELETE_WEBSITE_DOMAIN_PLACEHOLDER:
        "Digite o domínio do site (ex. empresa.com)",
      POPUPS_DELETE_WEBSITE_EMPHASIS_1:
        "Todos os dados do site (conversas, usuários) serão permanentemente excluídos e não poderão mais ser utilizados.",
      POPUPS_DELETE_WEBSITE_EMPHASIS_2:
        "Não há retorno depois de pressionar remover.",
      POPUPS_DELETE_WEBSITE_PASSWORD_LABEL: "Por favor identifique-se",
      POPUPS_DELETE_WEBSITE_PASSWORD_PLACEHOLDER:
        "Digite sua senha para eliminar o site…",
      POPUPS_DELETE_WEBSITE_PROCEED: "Remover o site",
      HELP_7:
        "# Como funciona o encaminhamento de mensagens?\nO encaminhamento de mensagens pode ser usado para atribuir conversas automaticamente a operadores especificados, com base nas condições. Por exemplo, você pode encaminhar todas as conversas segmentadas como \\'técnicos\\' para os seus agentes técnicos. O encaminhamento é útil para grandes equipas que recebem muitas conversas. Com o encaminhamento, apenas o operador atribuído é notificado.",
      CHATBOX_SECURITY_IGNORE_PRIVACY:
        "Ignorar opções de privacidade do usuário (MagicType, MagicBrowse)",
      POPUPS_CONFIRM_IGNORE_PRIVACY_LABEL:
        "Quer mesmo ignorar as opções de privacidade do usuário?",
      POPUPS_CONFIRM_IGNORE_PRIVACY_EMPHASIS_1:
        "Ao fazer isto, você concorda em ajustar os seus próprios termos de uso para concordar com a sua escolha. Isto permitirá que o MagicType e o MagicBrowse funcionem para usuários com o recurso Não Rastrear ativo.",
      POPUPS_CONFIRM_IGNORE_PRIVACY_EMPHASIS_2:
        "O Reinvent não pode ser responsabilizado por nenhuma reclamação do usuário ou qualquer violação de privacidade.",
      POPUPS_CONFIRM_IGNORE_PRIVACY_PROCEED: "Somos ninjas",
      POPUPS_CONFIRM_IGNORE_PRIVACY_CANCEL: "Eu respeito os meus usuários",
      POPUPS_CONFIRM_TOGGLE_ROLE_QUESTION:
        "Tem certeza de que deseja alternar a função deste operador?",
      POPUPS_CONFIRM_TOGGLE_ROLE_LABEL:
        "O acesso deste operador a alguns recursos será modificado.",
      POPUPS_CONFIRM_TOGGLE_ROLE_PROCEED: "Alternar papel",
      POPUPS_CONFIRM_TOGGLE_ROLE_CANCEL: "Cancelar",
      POPUPS_CLEAR_VISITORS_BLOCKED_LABEL:
        "Limpar todos os visitantes bloqueados? (IP e e-mail)",
      POPUPS_CLEAR_VISITORS_BLOCKED_EMPHASIS_1:
        "Isto limpará suas regras de bloqueio (ex. os usuários que foram bloqueados no chat).",
      POPUPS_CLEAR_VISITORS_BLOCKED_EMPHASIS_2:
        "Quantidade de visitantes bloqueados:",
      POPUPS_CLEAR_VISITORS_BLOCKED_PROCEED: "Limpar Visitantes Bloqueados",
      CONTACT_WEBSITE_MESSENGER_LABEL: "Messenger do site",
      CONTACT_WEBSITE_MESSENGER_PLACEHOLDER: "Usuário do Messenger",
      CONTACT_WEBSITE_TELEGRAM_LABEL: "Telegram do site",
      CONTACT_WEBSITE_TELEGRAM_PLACEHOLDER: "Usuário do Telegram",
      CONTACT_WEBSITE_TWITTER_LABEL: "twitter do site",
      CONTACT_WEBSITE_TWITTER_PLACEHOLDER: "Usuário do twitter",
      CHATBOX_BEHAVIOR_WAIT_GAME:
        "Sugerir um jogo de espera quando os operadores demorarem a responder",
      CHATBOX_BEHAVIOR_PHONE_VISITORS: "Pedir o telefone dos visitantes",
      CHATBOX_BEHAVIOR_FORCE_IDENTIFY:
        "Exigir identificação dos visitantes (e-mail ou telefone)",
      CHATBOX_BEHAVIOR_FILE_TRANSFER:
        "Permitir envio de arquivos na janela de chat",
      CHATBOX_BEHAVIOR_HELPDESK_LINK:
        "Mostrar link para a Central de Ajuda na janela de chat (se estiver configurada)",
      CHATBOX_BEHAVIOR_STATUS_HEALTH_DEAD:
        "Mostrar alerta na janela de chat quando o site estiver fora do ar (se a Página de Status estiver configurada)",
      CUSTOM_EMAIL_DOMAIN: "Domínio de e-mail personalizado",
      CUSTOM_EMAIL_DOMAIN_BASIC_DOMAIN_LABEL: "Domínio Básico",
      CUSTOM_EMAIL_DOMAIN_BASIC_DOMAIN_PLACEHOLDER: "empresa",
      CUSTOM_EMAIL_DOMAIN_CUSTOM_DOMAIN_LABEL: "Domínio personalizado",
      CUSTOM_EMAIL_DOMAIN_CUSTOM_DOMAIN_PLACEHOLDER: "emails.empresa.com",
      CUSTOM_EMAIL_SMTP: "SMTP de e-mail personalizado",
      CUSTOM_EMAIL_SMTP_BADGE_ACTIVE: "ativo",
      CUSTOM_EMAIL_SMTP_BADGE_FAILED: "falhou",
      CUSTOM_EMAIL_SMTP_BADGE_INACTIVE: "inativo",
      CUSTOM_EMAIL_SMTP_ENABLED:
        "Enviar todos os e-mails usando meu servidor SMTP personalizado",
      CUSTOM_EMAIL_SMTP_DOMAIN_LABEL: "Domínio de e-mail",
      CUSTOM_EMAIL_SMTP_DOMAIN_PLACEHOLDER: "emails.empresa.com",
      CUSTOM_EMAIL_SMTP_USERNAME_LABEL: "Nome de usuário do SMTP",
      CUSTOM_EMAIL_SMTP_USERNAME_PLACEHOLDER: "Nome de usuário (se houver)",
      CUSTOM_EMAIL_SMTP_PASSWORD_LABEL: "Senha SMTP",
      CUSTOM_EMAIL_SMTP_PASSWORD_PLACEHOLDER: "Senha (se houver)",
      CUSTOM_EMAIL_SMTP_SERVER_LABEL: "Servidor SMTP",
      CUSTOM_EMAIL_SMTP_SERVER_PLACEHOLDER: "smtp.empresa.com",
      CUSTOM_EMAIL_SMTP_CONTROLS_STATUS_PENDING:
        "O servidor SMTP personalizado ainda foi não configurado. Por favor confira.",
      CUSTOM_EMAIL_SMTP_CONTROLS_STATUS_LIVE:
        "Este servidor SMTP personalizado já está em uso.",
      CUSTOM_EMAIL_SMTP_CONTROLS_ACTION_VERIFY: "Verificar credenciais SMTP",
      CUSTOM_EMAIL_SIGNATURE: "Assinatura de e-mail personalizada",
      CUSTOM_EMAIL_SIGNATURE_BADGE_ENABLED: "ativo",
      CUSTOM_EMAIL_SIGNATURE_BADGE_DISABLED: "inativo",
      CUSTOM_EMAIL_SIGNATURE_ENABLED:
        "Incluir assinatura de texto no rodapé dos e-mails enviados",
      CUSTOM_EMAIL_SIGNATURE_FIELD_LABEL: "Assinatura de e-mail",
      CUSTOM_EMAIL_SIGNATURE_FIELD_PLACEHOLDER:
        "Digite uma assinatura de e-mail personalizada…",
      CUSTOM_EMAIL_SIGNATURE_TAG_OPERATOR_TITLE_LABEL: "Cargo do operador",
      CUSTOM_EMAIL_SIGNATURE_TAG_OPERATOR_NAME_FULL:
        "Nome completo do operador",
      CUSTOM_EMAIL_SIGNATURE_TAG_OPERATOR_NAME_FIRST: "Nome do operador",
      CUSTOM_EMAIL_SIGNATURE_TAG_OPERATOR_NAME_LAST: "Sobrenome do operador",
      CUSTOM_EMAIL_SIGNATURE_TAG_OPERATOR_EMAIL: "E-mail do operador",
      OUTBOUND_EMAIL_IPS: "IPs de envio de e-mail",
      OUTBOUND_EMAIL_IPS_NOTICE_TITLE: "O que são IPs de envio de e-mail?",
      OUTBOUND_EMAIL_IPS_NOTICE_LINE_1:
        "Quando os e-mails são enviados aos usuários para as suas mensagens e campanhasda Reinvent, são enviados usando um endereço IP com uma reputação anexada a ele. A probabilidade dos seus e-mails serem spam é baseada na reputação do IP.",
      OUTBOUND_EMAIL_IPS_NOTICE_LINE_2:
        "Você pode usar um grupo de IPs compartilhados padrão ou um endereço IP dedicado para melhorar a capacidade de entrega de email.",
      OUTBOUND_EMAIL_IPS_REPUTATION_TITLE: "Reputação atual do IP do e-mail",
      OUTBOUND_EMAIL_IPS_REPUTATION_TEXT_TITLE_BAD:
        "Cuidado! Seus e-mails podem estar sendo recebidos como spam.",
      OUTBOUND_EMAIL_IPS_REPUTATION_TEXT_TITLE_OKAY:
        "A reputação dos e-mails que você envia pode melhorar.",
      OUTBOUND_EMAIL_IPS_REPUTATION_TEXT_TITLE_GOOD:
        "A reputação dos e-mails que você envia está boa.",
      OUTBOUND_EMAIL_IPS_REPUTATION_DESCRIPTION_1: "Conseguimos",
      OUTBOUND_EMAIL_IPS_REPUTATION_DESCRIPTION_2: "cancelamentos de inscrição",
      OUTBOUND_EMAIL_IPS_REPUTATION_DESCRIPTION_3: "mais que",
      OUTBOUND_EMAIL_IPS_REPUTATION_DESCRIPTION_4: "e-mails",
      OUTBOUND_EMAIL_IPS_REPUTATION_DESCRIPTION_5: "você enviou de",
      OUTBOUND_EMAIL_IPS_REPUTATION_DESCRIPTION_6: "para",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_TITLE:
        "A reputação do IP de envio de seus e-mails",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_ACTIVE_SUBSCRIBED_LABEL:
        "Você está assinando um IP dedicado para e-mails",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_ACTIVE_SUBSCRIBED_CANCEL:
        "Cancelar assinatura de IP dedicado para e-mails",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_ACTIVE_NOT_SUBSCRIBED_LABEL:
        "Você está compartilhando o IP de envio de e-mails",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_ACTIVE_NOT_SUBSCRIBED_UPGRADE:
        "Assinar o IP dedicado para envio de e-mails",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_ACTIVE_NOT_SUBSCRIBED_ASSIGN:
        "Solicitar agora um IP dedicado (a extensão já está instalada)",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_POOL_TYPE_HIGH: "Alta",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_POOL_TYPE_MEDIUM: "Média",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_POOL_TYPE_LOW: "Baixa",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_POOL_VALUE_HIGH:
        "Usando IPs compartilhados de alta reputação",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_POOL_VALUE_MEDIUM:
        "Usando IPs compartilhados de média reputação",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_POOL_VALUE_LOW:
        "Usando IPs compartilhados de baixa reputação",
      OUTBOUND_EMAIL_IPS_REPUTATION_STATUS_POOL_VALUE_WHY: "Por que?",
      OUTBOUND_EMAIL_IPS_REPUTATION_LOGS_TITLE:
        "Últimos erros no envio de e-mails (registros do SMTP)",
      OUTBOUND_EMAIL_IPS_REPUTATION_LOGS_EMPTY:
        "Ótimo! Não houve nenhum erro recente no envio de e-mails.",
      WEB_HOOKS: "Ganchos de Rede",
      WEB_HOOKS_BADGE_HOOK: "gancho",
      WEB_HOOKS_BADGE_HOOKS: "ganchos",
      WEB_HOOKS_BADGE_FAILED: "falhou",
      WEB_HOOKS_ADD_LABEL: "Monitorar recebimento de ganchos de eventos",
      WEB_HOOKS_ADD_ACTION: "Adicionar Novo Gancho de Rede",
      IDENTITY_VERIFICATION: "Verificação de Identidade",
      IDENTITY_VERIFICATION_BADGE_ENABLED: "ativada",
      IDENTITY_VERIFICATION_BADGE_DISABLED: "desativada",
      IDENTITY_VERIFICATION_ENABLED_LABEL:
        "Verifique os e-mails dos usuários com assinaturas eletrônicas",
      IDENTITY_VERIFICATION_SECRET_LABEL: "Chave Secreta",
      IDENTITY_VERIFICATION_SECRET_PLACEHOLDER: "Gerando chave secreta…",
      IDENTITY_VERIFICATION_SECRET_ROLL: "Gerar segredo",
      CONTRACT_AGREEMENTS: "Contratos",
      CONTRACT_AGREEMENTS_BUTTON_CANCEL: "Cancelar",
      CONTRACT_AGREEMENTS_BUTTON_DOWNLOAD_AGREEMENT: "Ver contrato",
      CONTRACT_AGREEMENTS_BUTTON_UPLOAD: "Assinar e enviar",
      CONTRACT_AGREEMENTS_BUTTON_DOWNLOAD_CONTRACT: "Ver contrato",
      USAGE: "Limite utilizado",
      USAGE_PROGRESS_INFINITE: "(sem limite)",
      USAGE_PROGRESS_TIME: "A cada {time}",
      USAGE_PROGRESS_DATA: "(dados totais)",
      USAGE_PROGRESS_INCREASE: "Aumentar",
      POPUPS_EMAIL_IPS_ASSIGN_QUESTION:
        "Atribuir a este site um IP de e-mail dedicado?",
      POPUPS_EMAIL_IPS_ASSIGN_LABEL:
        "Seus e-mails serão entregues por um IP dedicado apenas ao seu website. Seu IP não é mais compartilhado com outros sites, o que ajuda a melhorar a capacidade de entrega.",
      POPUPS_EMAIL_IPS_ASSIGN_PROCEED: "Atribuir IP Dedicado para E-mails",
      POPUPS_EMAIL_IPS_UPGRADE_QUESTION:
        "Assinar módulo de extensão IP Dedicado para E-mails?",
      POPUPS_EMAIL_IPS_UPGRADE_LABEL:
        "Você será redirecionado para a instalação do módulo de extensão. Depois, volte aqui para ativar seus IPs dedicados.",
      POPUPS_EMAIL_IPS_UPGRADE_PROCEED: "Assinar o módulo de extensão",
      POPUPS_EMAIL_IPS_DOWNGRADE_QUESTION:
        "Cancelar a atribuição dos meus endereços de IP nos e-mails privados?",
      POPUPS_EMAIL_IPS_DOWNGRADE_LABEL:
        "Você será rebaixado para endereços IP de grupo. O módulo de extensão IP de Email Dedicado será desinstalado (você deixará de pagar por isso).",
      POPUPS_EMAIL_IPS_DOWNGRADE_PROCEED:
        "Cancelar a atribuição de IPs e desinstalar módulos de extensão",
      POPUPS_ADD_HOOK_LABEL_LABEL: "Nome para o gancho",
      POPUPS_ADD_HOOK_LABEL_PLACEHOLDER:
        "Insira um nome para o seu gancho (ex. Enviar mensagens)",
      POPUPS_ADD_HOOK_TARGET_LABEL: "URL a Ser Chamado",
      POPUPS_ADD_HOOK_TARGET_PLACEHOLDER:
        "Digite o URL do gancho de destino (ex. https://in.empresa.com/Reinvent/messages)",
      POPUPS_ADD_HOOK_EVENTS_LABEL: "Filtrar eventos",
      POPUPS_ADD_HOOK_PROCEED: "Adicionar Alvo do Gancho",
      POPUPS_VERIFY_KEY_ROLL_QUESTION: "Gerar chave secreta?",
      POPUPS_VERIFY_KEY_ROLL_LABEL:
        "Confirme que deseja gerar a sua chave secreta.",
      POPUPS_VERIFY_KEY_ROLL_EMPHASIS:
        "Cuidado! A sua chave secreta anterior será invalidada. Você deverá atualizar sua chave no back-end.",
      POPUPS_VERIFY_KEY_ROLL_PROCEED: "Gerar Chave Secreta",
      SUBTITLE_8: "Configurações avançadas",
      DESCRIPTION_8:
        "Configure Ganchos de Rede, personalize seu domínio de e-mail etc.",
      HELP_8:
        "# Como é que os domínios de e-mail personalizados funcionam?\nPor padrão, todos os e-mails para os seus utilizadores são enviados e recebidos pelo nome de domínioda Reinvent. Os seus utilizadores podem vê-lo nos endereços de e-mail usados.\n\nCaso deseje usar o seu próprio nome de domínio, pode seguir o guia de configuração de domínio de e-mail personalizado e receber e-mails enviados e recebidos através do seu próprio domínio privado.\n\n**A documentação do domínio de e-mail personalizado está disponível [aqui](https://help.talqui.chat/en/article/ndklh7/).**\n-----\n# Para que é que os Ganchos de Rede são úteis?\nOs Ganchos de Rede são usados ​​para enviar eventos em tempo real do seu website no Reinvent para um terminal HTTP. Pode especificar um URL HTTP que receberá uma lista de eventos em tempo real permitidos.\n\nOs ganchos de Rede são úteis quando você precisa de um sistema interno para ser notificado sobre eventos no seu website Reinvent.\n\n**A documentação sobre ganchos de Rede está disponível [aqui](https://help.talqui.chat/en/article/itsagz/).**\n-----\n# Como configurar a verificação de identidade?\nA verificação de identidade é usada para mostrar os e-mails dos utilizadores como verificados ou não verificados nas conversas. Isto permite o reconhecimento da falsificação e valida a propriedade da conta facilmente. A configuração requer que você adicione algum código ao seu back-end.\n\n**A documentação sobre verificação de identidade está disponível [aqui](https://help.talqui.chat/en/article/166sl01/).**",
      CHATBOX_BEHAVIOR_OPERATOR_PRIVACY:
        "Modo de privacidade do operador (ex. desativar marcadores de leitura no chat)",
      CHATBOX_BEHAVIOR_VISITOR_COMPOSE:
        "Os operadores podem ver o que os visitantes escrevem em tempo real (MagicType)",
      ROUTING_GENERAL: "Opções Gerais",
      ROUTING_GENERAL_RESPONDER: "Assumir atendimento ao responder",
      ROUTING_GENERAL_ASSIGN: "Habilitar regras de encaminhamento automático",
      ROUTING_GENERAL_SESSION_TIMEOUT: "Tempo de encerramento automático",
      ROUTING_RULES: "Atribuição automática de operator",
      ROUTING_RULES_COUNT: "regras",
      ROUTING_RULES_ADD_LABEL: "Atribuir operador às conversas",
      ROUTING_RULES_ADD_ACTION: "Adicionar regra de encaminhamento",
      SUBTITLE_9: "Regras e Termos de Uso",
      DESCRIPTION_9: "Gerencie a conformidade legal, revise o uso de dados.",
      HELP_9:
        "# Preciso de assinar todos os contratos?\nOs contratos disponíveis nesta secção podem ser assinados se você precisar deles para o seu negócio (por exemplo, para fins de conformidade legal). Não precisa de assiná-los para usar a Reinvent.",
      SUBTITLE_10: "Zona de Perigo™",
      DESCRIPTION_10:
        "Realizar operações perigosas neste site. Esta seção é exclusiva para proprietários.",
      HELP_10:
        "# Se eu remover este website, perderei todas as mensagens e dados dos utilizadores?\nSim, após a remoção do website, também removemos permanentemente todas as mensagens e dados dos utilizadores.\n-----\n# Posso recuperar um site excluído?\nTemos cópias no curto espaço de tempo de 1 mês. No entanto, **não recuperamos os dados do website requisitado**.\nCertifique-se de que é mesmo o que quer fazer antes de confirmar a remoção de um website.\n-----\n# Os utilizadores membros têm permissão para remover este website?\nNão, **apenas proprietários** podem fazê-lo e, portanto, os membros nem vêem esta secção.",
      ROUTING_GENERAL_REPROCESS_RULES:
        "Redesignar conversas um dia depois da última mensagem do operador",
      ROUTING_GENERAL_REPROCESS_OFFLINE:
        "Redesignar conversas se o operador estiver desconectado",
      CHATBOX_SECURITY_IGNORE_PRIVACY_ENABLE:
        "Opções de privacidade respeitadas",
      CHATBOX_SECURITY_IGNORE_PRIVACY_DISABLE:
        "Opções de privacidade ignoradas",
    },
    SHORTCUTS: {
      TITLE: "Atalhos de Mensagens",
      SUBTITLE_1: "Adicionar novo atalho",
      DESCRIPTION_1:
        "Crie um novo atalho. Crie um nome !pronto, escolha uma tag e escreva a mensagem. Depois, é só digitar o nome do !pronto nas conversas para responder rapidamente aos visitantes.",
      SHORTCUT_LABEL: "Atalho",
      SHORTCUT_PLACEHOLDER: "!pronto",
      IN_TAG: "Na etiqueta",
      ADD_TAG: "Adicionar uma etiqueta",
      MESSAGE_LABEL: "Mensagem",
      MESSAGE_PLACEHOLDER: "Insira uma mensagem para este atalho !pronto…",
      MESSAGE_TAG_EMAIL_LABEL: "Email do visitante",
      MESSAGE_TAG_NAME_FULL_LABEL: "Nome completo do visitante",
      MESSAGE_TAG_NAME_FIRST_LABEL: "Primeiro nome do visitante",
      MESSAGE_TAG_NAME_LAST_LABEL: "Sobrenome do visitante",
      MESSAGE_TAG_COUNTRY_LABEL: "País do visitante",
      MESSAGE_TAG_CITY_LABEL: "Cidade do visitante",
      MESSAGE_TAG_WEBSITE_LABEL: "URL do site do visitante",
      MESSAGE_TAG_COMPANY_NAME_LABEL: "Nome da empresa visitante",
      MESSAGE_TAG_DATA_LABEL: "Dados de conversa (na subchave para configurar)",
      ADD_SHORTCUT: "Adicionar atalho",
      HELP_1:
        "# O que é um atalho !pronto?\nCom o atalho !pronto, você pode pesquisar em seus modelos e **expandir o !pronto para mostrar a resposta**.\n-----\n# O que é uma tag de atalho?\nAs **tags são categorias** para agrupar os seus atalhos. \nPor exemplo, você pode categorizar !olá e !oi no grupo Saudações; !produto e !novo no grupo Produto. Você escolhe!\n-----\n# Posso usar macros para substituir o texto?\nSim! Macros podem ser usadas para **inserir texto dinâmico nos atalhos**. \nPor exemplo, o atalho 'Olá, {'{{'} name.first {'}}'}.' será expandido para mostrar o nome do destinatário 'Olá, João.'.",
      SUBTITLE_2: "Gerenciar todos os atalhos",
      DESCRIPTION_2:
        "Gerencie seus atalhos. Eles estão agrupados por etiqueta para o ajudar a organizá-los.",
      BADGE_SHORTCUTS: "atalhos",
      POPUPS_NEW_TAG_NAME_LABEL: "Valor para a etiqueta",
      POPUPS_NEW_TAG_NAME_PLACEHOLDER: "Inserir o valor para a etiqueta…",
      POPUPS_NEW_TAG_PROCEED: "Usar Esta Etiqueta",
      POPUPS_EDIT_SHORTCUT_BANG_LABEL: "Atalho !pronto",
      POPUPS_EDIT_SHORTCUT_BANG_PLACEHOLDER: "Digite um !pronto…",
      POPUPS_EDIT_SHORTCUT_TAG_LABEL: "Etiqueta do atalho",
      POPUPS_EDIT_SHORTCUT_TEXT_LABEL: "Mensagem do atalho",
      POPUPS_EDIT_SHORTCUT_TEXT_PLACEHOLDER: "Inserir uma mensagem…",
      POPUPS_EDIT_SHORTCUT_PROCEED: "Guardar atalho",
      POPUPS_REMOVE_SHORTCUT_TITLE: "O atalho selecionado será removido",
      POPUPS_REMOVE_SHORTCUT_NOTICE:
        "Este atalho será excluído permanentemente.",
      POPUPS_REMOVE_SHORTCUT_PROCEED: "Remover atalho",
      HELP_2:
        "# Como usar um atalho?\nPara usar atalhos numa conversa, basta **digitar um atalho !pronto no mesmo lugar em que você envia mensagens**.\nAssim que começar a digitar o nome do !pronto, você receberá sugestões para encontrar rapidamente o seu modelo !pronto.\n-----\n# Existe algum limite para a quantidade de atalhos?\nNão, não há nenhum limite. Você pode criar e usar **quantos atalhos quiser**.",
    },
    HELPDESK: {
      TITLE: "Central de Ajuda",
      ALERT_NOT_OWNER:
        "Você não é proprietário do site. Não é possível alterar as configurações da Central de Ajuda.",
      ALERT_NOT_SUBSCRIBED:
        "Você ainda não assinou a extensão Central de Ajuda. Para usá-la, atualize seu plano.",
      ALERT_NOT_INITIALIZED:
        "Você precisa inicializar sua Central de Ajuda antes de usá-la.",
      ALERT_NOT_INITIALIZED_GO_INITIALIZE: "Inicializar Central de Ajuda",
      ALERT_DOMAIN_INVALID:
        "Falha na verificação do domínio da sua Central de Ajuda. Confira se os registros DNS estão corretos. A próxima verificação será feita amanhã. Esta mensagem desaparecerá quando o domínio for revalidado.",
      ALERT_INDEXING_FORBIDDEN:
        "Atenção. As configurações atuais não permitem que as ferramentas de busca rastreiem sua Central de Ajuda.",
      ALERT_PASSWORD_DEFINED:
        "Atenção. O acesso à sua Central de Ajuda está protegido por senha.",
      POPUPS_INITIALIZE_QUESTION: "Inicializar sua Central de Ajuda",
      POPUPS_INITIALIZE_LABEL:
        "Crie um nome e atribua um subdomínio para sua Central de Ajuda:",
      POPUPS_INITIALIZE_NAME_LABEL: "Nome da Central de Ajuda",
      POPUPS_INITIALIZE_NAME_PLACEHOLDER: "Central de Ajuda da Empresa",
      POPUPS_INITIALIZE_DOMAIN_BASIC_LABEL: "Subdomínio da Central de Ajuda",
      POPUPS_INITIALIZE_DOMAIN_BASIC_PLACEHOLDER: "empresa",
      POPUPS_INITIALIZE_DOMAIN_BASIC_ENTICE:
        "(Você poderá configurar depois um domínio como ajuda.empresa.com)",
      POPUPS_INITIALIZE_PROCEED: "Inicializar Central de Ajuda",
      SUBTITLE_1: "Configurar sua Central de Ajuda",
      DESCRIPTION_1:
        "Escolha um domínio ou configure um domínio personalizado para sua Central de Ajuda.",
      SETTINGS_NAME_LABEL: "Nome da Central de Ajuda",
      SETTINGS_NAME_PLACEHOLDER: "Central de Ajuda do Site da Empresa",
      SETTINGS_INSTRUCTIONS: "Imagem, max. 5MB",
      SETTINGS_APPEARANCE_LOGOS_HEADER_LABEL: "Logótipo do cabeçalho",
      SETTINGS_APPEARANCE_LOGOS_FOOTER_LABEL: "Logótipo do rodapé",
      SETTINGS_APPEARANCE_BANNER_LABEL: "Bandeira",
      SETTINGS_BEHAVIOR_FREQUENTLY_READ_LABEL:
        "Mostrar uma lista dos artigos mais lidos na página inicial",
      SETTINGS_BEHAVIOR_SHOW_CHATBOX_LABEL:
        "Mostrar janela de chat na Central de Ajuda",
      SETTINGS_BEHAVIOR_ASK_FEEDBACK_LABEL:
        "Pedir comentários dos usuários no final dos artigos",
      SETTINGS_BEHAVIOR_LOCALE_PICKER_LABEL:
        "Mostrar seletor de idioma no topo da Central de Ajuda",
      SETTINGS_BEHAVIOR_REFER_LINK_LABEL:
        "Mostrar link para o Reinvent no rodapé da Central de Ajuda",
      SETTINGS_BEHAVIOR_FORBID_INDEXING_LABEL:
        "Proibir a indexação do mecanismo de pesquisa de todas as páginas",
      SETTINGS_BEHAVIOR_STATUS_HEALTH_DEAD_LABEL:
        "Mostrar alerta quando o site estiver fora do ar (se o Reinvent Status estiver configurado)",
      DOMAIN_BASIC_LABEL: "Domínio básico",
      DOMAIN_BASIC_PLACEHOLDER: "empresa",
      DOMAIN_CUSTOM_LABEL: "Domínio personalizado",
      DOMAIN_CUSTOM_PLACEHOLDER: "ajuda.empresa.com",
      HELP_1:
        "# Como é que o domínio básico funciona?Por padrão, o Reinvent fornece um domínio para o seu centro de ajuda. Qualquer centro de ajuda precisa de ter um domínio básico configurado. Precisa de escolher um subdomínia Reinvent.help, que o Reinvent fornece.\n\nPor exemplo, dada a empresa _Acme, Inc._, um subdomínio do suporte técnico que pode ser escolhido é _acme_. O suporte técnico estará disponível em acme.Reinvent.help.\n-----\n# Como é que o domínio personalizado funciona?\nCaso você precise que  o seu centro de ajuda esteja disponível no seu próprio nome de domínio, por exemplo. help.acme.com em vez de acme.Reinvent.help, você pode configurar um domínio personalizado. Quando um domínio personalizado é configurado, todos os pedidos ao domínio básico do centro de ajuda fornecidos pelo Reinvent são redirecionados para seu novo domínio personalizado.\n\n**Para configurar o seu domínio personalizado do centro de ajuda, preencha o seu domínio e siga o guia de configuração.** Observe que o centro de ajuda será servido por HTTPS seguro. O Reinvent administra o seu certificado SSL do centro de ajuda.",
      SUBTITLE_2: "Personalizar sua Central de Ajuda",
      DESCRIPTION_2: "Ajuste o visual e as ações da sua Central de Ajuda.",
      HELP_2:
        "# Onde posso ver os logotipos?\nOs logotipos podem ser vistos no cabeçalho (logotipo branco) e no rodapé (logotipo escuro). É recomendável carregar o logotipo da sua empresa para substituir os logotipos padrãoda Reinvent.\n-----\n# Onde posso ver o banner?\nO banner é a imagem grande visível no cabeçalho do centro de ajuda. Por padrão, é usada a cor que você escolheu para a sua janela de chat, assim com o o padrão. No entanto, você pode enviar uma imagem personalizada (por exemplo, uma imagem).",
      SETTINGS_BEHAVIOR_SHOW_CATEGORY_IMAGES_LABEL:
        "Mostrar imagens da categoria na página inicial",
      POPUPS_SETTINGS_INCLUDE_HTML_NOTICE_TITLE:
        "Como pode usar o código HTML?",
      POPUPS_SETTINGS_INCLUDE_HTML_NOTICE_TEXT:
        "O código HTML pode ser usado para alterar o estilo do seu centro de ajuda com CSS personalizado ou inserir scripts nas páginas, por exemplo. Google Analytics.",
      POPUPS_SETTINGS_INCLUDE_HTML_INVALID_TITLE:
        "Opa! Parece que não isso é um código HTML válido.",
      POPUPS_SETTINGS_INCLUDE_HTML_INVALID_TEXT:
        " Você esqueceu as tags <style></style> ou <script></script>?",
      POPUPS_SETTINGS_INCLUDE_HTML_VALUE_LABEL: "Código HTML a incluir",
      POPUPS_SETTINGS_INCLUDE_HTML_VALUE_PLACEHOLDER:
        "Insira o código HTML a incluir no centro de ajuda…",
      POPUPS_SETTINGS_INCLUDE_HTML_PROCEED: "Guardar código HTML",
      POPUPS_SETTINGS_ACCESS_PASSWORD_ENABLE_PASSWORD_LABEL:
        "Palavra-passe de acesso ao Centro de Apoio (torne-o privado)",
      POPUPS_SETTINGS_ACCESS_PASSWORD_ENABLE_PASSWORD_PLACEHOLDER:
        "Inserir uma palavra-passe…",
      POPUPS_SETTINGS_ACCESS_PASSWORD_ENABLE_PROCEED:
        "Ativar a palavra-passe para o Centro de Ajuda",
      POPUPS_SETTINGS_ACCESS_PASSWORD_DISABLE_QUESTION:
        "Desativar a palavra-passe para o Centro de Ajuda?",
      POPUPS_SETTINGS_ACCESS_PASSWORD_DISABLE_NOTICE:
        "As suas páginas do Centro de Ajuda serão publicamente visíveis para toda a gente.",
      POPUPS_SETTINGS_ACCESS_PASSWORD_DISABLE_PROCEED:
        "Desativar a Palavra-passe Para o Centro de Ajuda",
      SETTINGS_INCLUDE_HTML_LABEL: "Incluir código HTML personalizado",
      SETTINGS_INCLUDE_HTML_ACTION: "Editar código HTML personalizado",
      SETTINGS_ACCESS_PASSWORD_LABEL: "Restringir acesso à Central de Ajuda",
      SETTINGS_ACCESS_PASSWORD_ACTION_CONFIGURE:
        "Ativar senha para a Central de Ajuda",
      SETTINGS_ACCESS_PASSWORD_ACTION_DISABLE:
        "Desativar senha para a Central de Ajuda",
    },
    STATUS: {
      TITLE: "Página de Status",
      ALERT_NOT_OWNER:
        "Você não é proprietário do site. Não é possível alterar as configurações da Página de Status.",
      ALERT_NOT_SUBSCRIBED:
        "Você não assinou a extensão Página de Status. Para usá-la, atualize seu plano.",
      ALERT_NOT_INITIALIZED:
        "A página de status precisa de ser inicializada antes de ser usada.",
      ALERT_NOT_INITIALIZED_GO_INITIALIZE:
        "Inicializar a minha página de status",
      ALERT_DOMAIN_INVALID:
        "A última verificação do domínio da página de status falhou. Verifique se não alterou os registos DNS do domínio. A próxima verificação será feita amanhã. Uma vez validado novamente, isto desaparecerá.",
      ALERT_NO_NOTIFY:
        "Nenhum canal de notificações está configurado. Isso é necessário para ser notificado quando o site estiver fora do ar.",
      ALERT_HAS_ANNOUNCEMENT:
        "Um aviso está ativo na sua Página de Status. Não esqueça de removê-lo quando não for mais necessário.",
      POPUPS_INITIALIZE_QUESTION: "Inicializar a sua página de estados",
      POPUPS_INITIALIZE_LABEL:
        "Dê um nome à sua página de estados e atribua-lhe um subdomínio:",
      POPUPS_INITIALIZE_NAME_LABEL: "Nome da Página de Estados",
      POPUPS_INITIALIZE_NAME_PLACEHOLDER: "Status do Site da Empresa",
      POPUPS_INITIALIZE_DOMAIN_BASIC_LABEL: "Subdomínio da Página de Estados",
      POPUPS_INITIALIZE_DOMAIN_BASIC_PLACEHOLDER: "empresa",
      POPUPS_INITIALIZE_PROCEED: "Inicializar a Página de Estados",
      SUBTITLE_1: "Configurar sua Página de Status",
      DESCRIPTION_1:
        "Obtenha um domínio para a sua página de status ou configure um domínio personalizado.",
      SETTINGS_NAME_LABEL: "Nome da página de status",
      SETTINGS_NAME_PLACEHOLDER: "Status da Empresa",
      SETTINGS_INSTRUCTIONS: "Imagem, máx. 5MB",
      SETTINGS_APPEARANCE_LOGOS_HEADER_LABEL: "Logótipo do cabeçalho",
      SETTINGS_APPEARANCE_LOGOS_FOOTER_LABEL: "Logótipo do rodapé",
      SETTINGS_APPEARANCE_BANNER_LABEL: "Bandeira",
      SETTINGS_BEHAVIOR_SHOW_CHATBOX_LABEL:
        "Mostrar janela de chat na Página de Status",
      SETTINGS_BEHAVIOR_LOCALE_PICKER_LABEL:
        "Mostrar seletor de idioma no topo da Página de Status",
      SETTINGS_BEHAVIOR_REFER_LINK_LABEL:
        "Mostrar link do Reinvent no rodapé da página de status",
      SETTINGS_BEHAVIOR_FORBID_INDEXING_LABEL:
        "Proibir indexação de páginas por mecanismos de pesquisa",
      SETTINGS_METRICS_POLL_RETRY_LABEL:
        "Pesquisa: número de vezes a repetir uma verificação com falha",
      SETTINGS_METRICS_POLL_DELAY_DEAD_LABEL:
        "Poll: número de segundos para considerar o nó fora do ar",
      SETTINGS_METRICS_POLL_DELAY_SICK_LABEL:
        "Pesquisa: segundos após o qual o nó está doente",
      SETTINGS_METRICS_PUSH_DELAY_DEAD_LABEL:
        "Push: número de segundos para considerar o nó fora do ar",
      SETTINGS_METRICS_PUSH_SYSTEM_CPU_SICK_ABOVE_LABEL:
        "Envio: taxa de carga acima da qual o CPU do nó está doente",
      SETTINGS_METRICS_PUSH_SYSTEM_RAM_SICK_ABOVE_LABEL:
        "Envio: taxa de carregamento acima da qual a RAM do nó está doente",
      SETTINGS_METRICS_LOCAL_RETRY_LABEL:
        "Local: número de vezes a repetir uma verificação com falha",
      SETTINGS_METRICS_LOCAL_DELAY_DEAD_LABEL:
        "Local: número de segundos para considerar o nó fora do ar",
      SETTINGS_METRICS_LOCAL_DELAY_SICK_LABEL:
        "Local: segundos após o qual o nó está doente",
      DOMAIN_BASIC_LABEL: "Domínio básico",
      DOMAIN_BASIC_PLACEHOLDER: "empresa",
      DOMAIN_CUSTOM_LABEL: "Domínio personalizado",
      DOMAIN_CUSTOM_PLACEHOLDER: "status.empresa.com",
      HELP_1:
        "# Como é que o domínio básico funciona?\nPor padrão, o Reinvent fornece um domínio para sua página de estados. Qualquer página de estados precisa ter um domínio básico configurado. Precisa de escolher um subdomínia Reinvent.watch, que o Reinvent fornece.\n\nPor exemplo, dada a empresa _Acme, Inc._, um subdomínio da página de estados que pode ser escolhido é _acme_. A página de estados estará disponível em acme.Reinvent.watch.\n-----\n# Como é que o domínio personalizado funciona?\nCaso você precise que a sua página de estados esteja disponível com o seu próprio nome de domínio, por exemplo. status.acme.com em vez de acme.Reinvent.watch, pode configurar um domínio personalizado. Depois de configurar um domínio personalizado, todas os pedidos ao domínio básico da página de estados fornecidos pelo Reinvent são redirecionados para o seu novo domínio personalizado.\n\n**Para configurar o seu domínio de página de estados personalizado, preencha o seu domínio e continue para o guia de configuração. **Observe que sua página de estados será associada com HTTPS seguro. O Reinvent gere o seu certificado SSL da página de estados.",
      SUBTITLE_2: "Gerenciar serviços monitorizados",
      DESCRIPTION_2: "Configurar os serviços e os nós para monitoramento.",
      SERVICES_OPEN_NODES: "Abrir os nós",
      SERVICES_HIDE_NODES: "Esconder os nós",
      SERVICES_BADGE_NODES: "nós",
      SERVICES_ACTION_EDIT: "Editar",
      SERVICES_ACTION_DELETE: "Eliminar serviço",
      SERVICES_ADD_LABEL: "Agrupar os nós em um serviço",
      SERVICES_ADD_ACTION: "Adicionar nós em um serviço",
      NODES_BADGE_MODE_PUSH: "Nó de envio",
      NODES_BADGE_MODE_POLL: "Nó de Pesquisa",
      NODES_BADGE_MODE_LOCAL: "Nó local",
      NODES_ACTION_SHOW_DETAILS: "Mostrar detalhes",
      NODES_ACTION_PURGE_REPLICAS: "Eliminar réplicas",
      NODES_ADD_LABEL: "Monitorizar um nó",
      NODES_ADD_ACTION: "Adicionar um Novo Nó",
      HELP_2:
        "# O que é um serviço?\nUm serviço é um grupo de nós. Pense nisto como uma categoria.\n-----\n# O que é um nó?\nUm nó pode ser um site, uma aplicação.\n-----\n# O que é uma réplica?\nUma réplica é um fragmento de um nó, caso tenha um nó com balanceamento de carga. Na maioria das vezes, terá uma única réplica.",
      SUBTITLE_3: "Gerenciar notificações dos canais",
      DESCRIPTION_3:
        "Configurar a forma como quer ser notificado (E-mail, Slack, Push).",
      NOTIFY_ACTIVE: "Ativo",
      NOTIFY_EMAIL_TITLE: "E-mail (para endereço)",
      NOTIFY_EMAIL_CONTROL:
        "Enviar alertas de tempo de inatividade para o meu endereço de e-mail",
      NOTIFY_EMAIL_TO_LABEL: "Enviar e-mail para",
      NOTIFY_EMAIL_TO_PLACEHOLDER: "Insira o endereço de e-mail",
      NOTIFY_SLACK_CONTROL:
        "Enviar alertas de tempo de inatividade para o meu canal do Slack",
      NOTIFY_SLACK_HOOK_URL_LABEL: "URL do Gacnho da Slack",
      NOTIFY_SLACK_HOOK_URL_PLACEHOLDER: "Insira URL do ganho da Slack…",
      NOTIFY_SLACK_MENTION_CHANNEL_LABEL: "Mencionar canal",
      NOTIFY_SLACK_TITLE: "Slack (para canal)",
      NOTIFY_APP_TITLE: "Push (para operadores)",
      NOTIFY_APP_CONTROL:
        "Enviar alertas de tempo de inatividade para as minhas aplicações no Reinvent (push)",
      NOTIFY_APP_PICKER_LABEL: "Notificar operadores",
      NOTIFY_APP_PICKER_ADD: "Editar operadores",
      NOTIFY_PUSHOVER_TITLE: "Pushover (para aplicação)",
      NOTIFY_PUSHOVER_CONTROL:
        "Enviar alertas de tempo de inatividade para meu aplicativo Pushover",
      NOTIFY_PUSHOVER_TOKEN_LABEL: "Token da API de pushover",
      NOTIFY_PUSHOVER_TOKEN_PLACEHOLDER: "Digite o token da API Pushover…",
      NOTIFY_PUSHOVER_USERS_LABEL: "Chaves de usuários de pushover",
      NOTIFY_PUSHOVER_USERS_PLACEHOLDER:
        "Digite as chaves dos usuários do Pushover…",
      HELP_3:
        "# Quando receberei notificações?\nVocê será notificado quando a sua página de status mudar de Normal para Fora do Ar, ou vice-versa.",
      SUBTITLE_4: "Personalizar a Sua Página de Estados",
      DESCRIPTION_4:
        "Mudar a aparência e o comportamento da sua Página de Estados",
      HELP_4:
        "# Onde posso ver os logótipos?\nOs logótipos podem ser vistos no cabeçalho (logótipo branco) e no rodapé (logótipo escuro). É recomendável carregar o logótipo da sua empresa para substituir os logótipos padrãoda Reinvent.\n-----\n# Onde posso ver o banner?\nO banner é a imagem grande visível no cabeçalho da página de estados. Por padrão, é usada a cor que você escolheu para sua janela de chat, assim como padrão. No entanto, você pode carregar uma imagem personalizada (por exemplo, uma imagem).",
      SUBTITLE_5: "Configurar anúncio de serviço",
      DESCRIPTION_5: "Configure um novo anúncio de serviço ou remova um ativo.",
      ANNOUNCEMENT_LABEL: "Anúncio",
      ANNOUNCEMENT_ADD: "Adicionar um anúncio",
      ANNOUNCEMENT_DATE_PUBLISHED: "Publicado em:",
      ANNOUNCEMENT_DATE_EXPIRE: "Expira em:",
      ANNOUNCEMENT_ACTION_REMOVE: "Remover",
      ANNOUNCEMENT_ACTION_EDIT: "Editar texto",
      HELP_5:
        "# Para que são usados os anúncios?\nAnúncios são mensagens mostradas aos seus utilizadores na sua Página de Estados. É útil avisar os seus utilizadores sobre o andamento da manutenção ou da atenuação do tempo de inatividade.",
      SUBTITLE_6: "Configure o bot de monitorização",
      DESCRIPTION_6:
        "Ajuste como é que o bot de monitorização se comporta com os seus serviços.",
      HELP_6:
        "# O que é uma monitorização \\'de pesquisa\\'?\nUma monitorização de \\'pesquisa\\' envia um pedido para as réplicas configuradas (HTTP ou TCP) para verificar se cada relatório está ativo ou inativo.\n-----\n# O que é uma monitorização \\'de envio\\'?\nUma monitorização \\'de envio\\' aguarda que uma aplicação que usa uma biblioteca do Reinvent Status Reporter envie o seu estado.\n-----\n# O que é uma monitorização \\'local\\'?\nUma monitorização \\'local\\' é semelhante a uma monitorização \\'de pesquisa\\', mas usa o utilitário Reinvent Status Local para monitorizar os pontos de extremidade da LAN.",
      SUBTITLE_7: "Configure o Seu Repórter de Estados",
      DESCRIPTION_7: "Recupere o seu token do repórter ou gere um novo.",
      REPORTER_TOKEN_LABEL: "Token secreto",
      REPORTER_TOKEN_PLACEHOLDER: "A gerar um token do repórter ",
      REPORTER_TOKEN_ROLL: "Lançar token",
      REPORTER_DESCRIPTION_1: "O token está a ser usado em",
      REPORTER_DESCRIPTION_2: "bibliotecas do repórter",
      REPORTER_DESCRIPTION_3: "e",
      REPORTER_DESCRIPTION_4: "daemons do repórter",
      REPORTER_DESCRIPTION_5: ". É exclusivo do seu site.",
      HELP_7:
        "# Para que é usado o token?\nO token é usado na biblioteca Reinvent Status Reporter nas suas aplicações. ",
      POPUPS_SETTINGS_INCLUDE_HTML_NOTICE_TITLE:
        "Como é que pode usar o código HTML?",
      POPUPS_SETTINGS_INCLUDE_HTML_NOTICE_TEXT:
        "O código HTML pode ser usado para alterar o estilo da sua página de estados com CSS personalizado ou utilizando scripts na página de estados, por exemplo, Google Analytics.",
      POPUPS_SETTINGS_INCLUDE_HTML_INVALID_TITLE:
        "Opa! Parece que isso não é um código HTML válido.",
      POPUPS_SETTINGS_INCLUDE_HTML_INVALID_TEXT:
        "Esqueceu das tags <style>ou <script>?",
      POPUPS_SETTINGS_INCLUDE_HTML_VALUE_LABEL: "Código HTML a incluir",
      POPUPS_SETTINGS_INCLUDE_HTML_VALUE_PLACEHOLDER:
        "Digite o código HTML a incluir na página de estados…",
      POPUPS_SETTINGS_INCLUDE_HTML_PROCEED: "Guardar código HTML",
      POPUPS_NOTIFY_NOTIFY_APP_OPERATOR_LABEL: "Notificar operadores",
      POPUPS_NOTIFY_NOTIFY_APP_OPERATOR_PROCEED: "Guardar operadores",
      POPUPS_SERVICES_ADD_SERVICE_NOTICE_TITLE: "O que é um serviço",
      POPUPS_SERVICES_ADD_SERVICE_NOTICE_TEXT:
        "Um serviço é um grupo de nós. Por exemplo, você pode criar um serviço \\'Sites\\', com um nó para cada site executado.",
      POPUPS_SERVICES_ADD_SERVICE_NAME_LABEL: "Nome do Serviço",
      POPUPS_SERVICES_ADD_SERVICE_NAME_PLACEHOLDER:
        "Nome do Serviço (ex. \\'Sites\\')",
      POPUPS_SERVICES_ADD_SERVICE_PROCEED: "Adicionar Serviço",
      POPUPS_SERVICES_EDIT_SERVICE_NAME_LABEL: "Nome do Serviço",
      POPUPS_SERVICES_EDIT_SERVICE_NAME_PLACEHOLDER:
        "Nome do Serviço (ex. \\'Sites\\')",
      POPUPS_SERVICES_EDIT_SERVICE_ORDER_LABEL: "Ordenação dos Serviços",
      POPUPS_SERVICES_EDIT_SERVICE_ORDER_PLACEHOLDER:
        "Sequência (ex. 1, 2 etc.)",
      POPUPS_SERVICES_EDIT_SERVICE_PROCEED: "Guardar Serviço",
      POPUPS_SERVICES_REMOVE_SERVICE_QUESTION: "Remover este serviço?",
      POPUPS_SERVICES_REMOVE_SERVICE_LABEL:
        "O serviço e todos os seus nós serão removidos. Todos os nós filhos do serviço deixarão de ser monitorizados e serão perdidos.",
      POPUPS_SERVICES_REMOVE_SERVICE_PROCEED: "Remover Serviço",
      POPUPS_SERVICES_ADD_SERVICE_NODE_NOTICE_TITLE: "O que é um nó?",
      POPUPS_SERVICES_ADD_SERVICE_NODE_NOTICE_TEXT:
        "Um nó pode ser um site, uma API HTTP, um servidor de banco de dados etc.",
      POPUPS_SERVICES_ADD_SERVICE_NODE_NOTICE_POLL_TITLE:
        "Como posso monitorizar este nó de pesquisa?",
      POPUPS_SERVICES_ADD_SERVICE_NODE_NOTICE_POLL_TEXT:
        "No modo poll, o Reinvent verifica os URLs fornecidos (ex. verificação HTTP de um website). Configure URLs com URLs ICMP, TCP ou HTTP.",
      POPUPS_SERVICES_ADD_SERVICE_NODE_NOTICE_PUSH_TITLE:
        "Como posso monitorizar um nó de envio?",
      POPUPS_SERVICES_ADD_SERVICE_NODE_NOTICE_PUSH_TEXT:
        "No modo de envio, você precisa de instalar a biblioteca Reinvent Status Reporter no seu código. Obtenha a biblioteca para a sua plataforma:",
      POPUPS_SERVICES_ADD_SERVICE_NODE_NOTICE_LOCAL_TITLE:
        "Como posso configurar este nó local?",
      POPUPS_SERVICES_ADD_SERVICE_NODE_NOTICE_LOCAL_TEXT:
        "No modo local, você configura os URLs monitorizados como no modo de pesquisa. Precisa de instalar o daemon Reinvent Status Local na sua LAN:",
      POPUPS_SERVICES_ADD_SERVICE_NODE_LABEL_LABEL: "Rótulo do Nó",
      POPUPS_SERVICES_ADD_SERVICE_NODE_LABEL_PLACEHOLDER:
        "Rótulo do Nó (ex. \\' Página de Início\\')",
      POPUPS_SERVICES_ADD_SERVICE_NODE_MODE_LABEL:
        "Modo de monitorização do nó",
      POPUPS_SERVICES_ADD_SERVICE_NODE_PROCEED: "Adicionar Nó",
      POPUPS_SERVICES_ADD_SERVICE_NODE_UPGRADE_DESCRIPTION:
        "Você atingiu seu limite de nós monitorados, incluído gratuitamente em seu plano Unlimited.\n*você pode adicionar mais nós a qualquer momento, aumentando seus limites***.\n\nPor favor, siga nossas instruções para subscrever limites mais altos.",
      POPUPS_SERVICES_ADD_SERVICE_NODE_UPGRADE_PROCEED:
        "Aumentar os limites de monitoramento",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_LABEL_LABEL: "Rótulo do nó",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_LABEL_PLACEHOLDER:
        "Rótulo do Nó (ex. \\' Página de Início\\')",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_ORDER_LABEL: "Ordenação dos Nós",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_ORDER_PLACEHOLDER:
        "Sequência (ex. 1, 2 etc.)",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_REPLICAS_LABEL:
        "URLs monitorizados (se existirem)",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_REPLICAS_PICKER_PLACEHOLDER:
        "URL (ex. \\'https://empresa.com/\\', \\'tcp://empresa.com:443\\')",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_REPLICAS_PICKER_ADD:
        "Adicionar um URL monitorizado (HTTP / TCP / ICMP)",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_HTTP_STATUS_HEALTHY_ABOVE_LABEL:
        "Normal: status HTTP acima",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_HTTP_STATUS_HEALTHY_ABOVE_PLACEHOLDER:
        "Código de status (ex. 200)",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_HTTP_STATUS_HEALTHY_BELOW_LABEL:
        "Normal: status HTTP abaixo",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_HTTP_STATUS_HEALTHY_BELOW_PLACEHOLDER:
        "Código de status (ex. 299)",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_HTTP_BODY_HEALTHY_MATCH_LABEL:
        "Normal: o corpo do HTTP contém",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_HTTP_BODY_HEALTHY_MATCH_PLACEHOLDER:
        "Digite a expressão a ser encontrada no corpo (ex. \\'OK\\')",
      POPUPS_SERVICES_EDIT_SERVICE_NODE_PROCEED: "Guardar nó",
      POPUPS_SERVICES_REMOVE_SERVICE_NODE_QUESTION: "Remover este nó?",
      POPUPS_SERVICES_REMOVE_SERVICE_NODE_LABEL:
        "O nó vai ser removido. Todas as suas réplicas vão parar de ser monitorizadas.",
      POPUPS_SERVICES_REMOVE_SERVICE_NODE_PROCEED: "Remover Nó",
      POPUPS_SERVICES_SHOW_DETAILS_NODE_QUESTION: "Obter detalhes do nó",
      POPUPS_SERVICES_SHOW_DETAILS_NODE_LABEL:
        "Estes identificadores podem ser usados ​​numa biblioteca do Reinvent Status Reporter o para seu nó de envio. Eles são exclusivos para este nó.",
      POPUPS_SERVICES_SHOW_DETAILS_NODE_SERVICE_ID_LABEL:
        "Identificador de serviço",
      POPUPS_SERVICES_SHOW_DETAILS_NODE_NODE_ID_LABEL: "Identificador de nó",
      POPUPS_SERVICES_SHOW_DETAILS_NODE_PROCEED: "Fechar detalhes",
      POPUPS_SERVICES_PURGE_SERVICE_NODE_REPLICAS_QUESTION:
        "Limpar réplicas de nós?",
      POPUPS_SERVICES_PURGE_SERVICE_NODE_REPLICAS_LABEL:
        "Isto redefinirá o estado salvo para todas as réplicas de nós. Você pode limpar as réplicas dos nós ao desligar uma réplica (por exemplo, aplicação no modo \\'push\\' monitorada com uma biblioteca do Reinvent Status Reporter).",
      POPUPS_SERVICES_PURGE_SERVICE_NODE_REPLICAS_PROCEED: "Permitir Réplicas",
      POPUPS_ADD_ANNOUNCEMENT_NOTICE_TITLE: "Como funcionam os anúncios?",
      POPUPS_ADD_ANNOUNCEMENT_NOTICE_TEXT:
        "Os avisos aparecem numa caixa de texto na sua Página de Status para informar os usuários sobre algum problema em andamento (ou uma manutenção programada).",
      POPUPS_ADD_ANNOUNCEMENT_TITLE_LABEL: "Título do anúncio",
      POPUPS_ADD_ANNOUNCEMENT_TITLE_PLACEHOLDER: "Insira o título do anúncio…",
      POPUPS_ADD_ANNOUNCEMENT_MESSAGE_LABEL: "Mensagem do anúncio",
      POPUPS_ADD_ANNOUNCEMENT_MESSAGE_PLACEHOLDER:
        "Escreva uma mensagem para os eu anúncio…",
      POPUPS_ADD_ANNOUNCEMENT_EXPIRE_LABEL:
        "Data de validade do anúncio (pode ser deixado em branco)",
      POPUPS_ADD_ANNOUNCEMENT_PROCEED: "Guardar anúncio",
      POPUPS_REMOVE_ANNOUNCEMENT_TITLE: "Remover anúncio?",
      POPUPS_REMOVE_ANNOUNCEMENT_NOTICE:
        "O anúncio não vai voltar a ser mostrado na sua págian de estados.",
      POPUPS_REMOVE_ANNOUNCEMENT_PROCEED: "Remover Anúncio",
      POPUPS_REPORTER_TOKEN_ROLL_LABEL:
        "Por favor confirme se quer lançar o seu token de repórter",
      POPUPS_REPORTER_TOKEN_ROLL_EMPHASIS:
        "Cuidado! O seu último token do repórter estará inválido. Precisa de atualizar o token nas suas bibliotecas.",
      POPUPS_REPORTER_TOKEN_ROLL_PROCEED: "Gerar Token do Repórter",
      SETTINGS_INCLUDE_HTML_LABEL: "Incluir código HTML personalizado",
      SETTINGS_INCLUDE_HTML_ACTION: "Editar código HTML incuído",
      POPUPS_SERVICES_ADD_SERVICE_NODE_MODE_KEY_POLL:
        "serviço HTTP / TCP / ICMP (modo de pesquisa)",
      POPUPS_SERVICES_ADD_SERVICE_NODE_MODE_KEY_PUSH:
        "A aplicação está a usar a biblioteca do repórter (modo push)",
      POPUPS_SERVICES_ADD_SERVICE_NODE_MODE_KEY_LOCAL:
        "Serviço por trás de uma barreira de proteção (modo local)",
    },
  },
  POPUPS: {
    UPGRADE: {
      TITLE: "{plugin_name} está disponível no Reinvent {plan_name}",
      QUICKTOUR: "Visita Rápida",
      STEP_PLUGIN_TOUR: "Apresentação do Plugin",
      TOUR_TITLE_1: "O {plugin_name} está disponível em",
      TOUR_TITLE_2: "plano",
      GO_FROM_TOUR: "Atualizar para {plan_name}",
      TOUR_LABEL_DEFAULT: "Atualizar agora para obter esta funcionalidade",
      TOUR_LABEL_VIDEO: "Quer ver o que faz? Veja o vídeo abaixo.",
      FEATURE: "Funcionalidade",
    },
    HELP: {
      TITLE: "Como podemos ajudar?",
      HELPDESK: {
        TITLE: "Visite nosso Central de Ajuda",
        DESCRIPTION: "Mais de 200 artigos para aprender a usar Reinvent",
      },
      UNLEASH: {
        TITLE: "Encontrar cursos de vídeo",
        DESCRIPTION:
          "Vídeos para aprender as melhores práticas de utilização Reinvent",
      },
      DOCS: {
        TITLE: "Navegue em nosso Hub de Desenvolvedores",
        DESCRIPTION:
          "Programe na Plataforma do Reinvent em uma questão de minutos.",
      },
      CHAT: {
        TITLE: "Converse conosco",
        DESCRIPTION: "Mais difícil que você pensava? Fale com nossa equipe!",
      },
    },
    LIMIT_REACHED: {
      UPGRADE_TITLE: "Por favor, atualize seus limites",
    },
    ADD_CARD: {
      TITLE: "Adicionar Novo Método De Pagamento",
      STEP_METHOD: "Forma de pagamento",
      STEP_DETAILS: "Detalhes",
      GO: "Adicionar este cartão",
      NAME_ON_CARD: "Nome no cartão",
      ENTER_NAME_ON_CARD: "Insira o nome do cartão",
      ADDRESS: "Endereço Completo",
      ENTER_ADDRESS: "Avenida Copacabana, n233 - Brasília",
      CARD_NUMBER: "Número do cartão",
      EXPIRATION_DATE: "Data de validade",
      SECURITY_CODE: "Código de segurança",
      CVV: "CVV",
      INVOICE_EMAIL: "E-mail do financeiro, para enviar faturas",
      ENTER_INVOICE_EMAIL: "financeiro{'@'}empresa.com",
      INVOICE_PHONE: "Telefone Financeiro",
      ENTER_INVOICE_PHONE: "+55 11 99999-9999",
      INFORMATION: "Informações para faturas",
      ENTER_INFORMATION: "Nome da empresa: Reinvent LTDA",
      COUNTRY: "País",
      TAX_IDENTIFIER: "CPF/CNPJ",
      ENTER_TAX_IDENTIFIER: "XXX.XXX.XXX-XX",
      DISCLAIMER_HEAD:
        "O método de pagamento que você adicionar será automaticamente associado a assinatura das empresas onde você for responsável.",
      DISCLAIMER_FOOT:
        "Se você estiver em período de avaliação grátis, após o término do período o método cadastrado será utilizado para cobrança da assinatura",
      CARD_TITLE: "Adicionar um cartão de crédito",
      CARD_ENTICE: "Todos os fornecedores de cartão de crédito são suportados.",
      PAYPAL_TITLE: "Adicionar PayPal",
      PAYPAL_ENTICE: "Suporta cartões de crédito e contas bancárias.",
      SAVE_FINISH: "Salvar e Terminar",
      WEBSITES_BILLING_OWNER_WARNING:
        "Alguns de seus sites têm o pagamento gerenciado por outros responsáveis",
      WEBSITES_MANAGES_SUBSCRIPTION: "gerencia esta assinatura",
      USE_NEW_PAYMENT_METHOD: "Usar minha nova forma de pagamento",
      SKIP: "Verificar",
    },
    ONBOARDING: {
      HEADER_TITLE: "Configure o seu Reinvent",
      TOP_CHANNELS: "Canais de atendimento",
      INTEGRATE:
        "Integre a Reinvent nestes canais para começar a se comunicar com os seus clientes.",
      SEE_ALL: "Ver todas as integrações",
      VIDEO_TUTORIAL: "Vídeo do tutorial",
      HTML: "# 1.Copie o seguinte código\n```{javascript_code}```\n\n# 2. Adicione o código à sua página\n** Cole o código da janela de chat acima na sua página. ** Você pode adicioná-lo na seção de cabeçalho do HTML.\n\nSe a janela de chat não aparecer, tente apagar o seu cache. Também pode contactar a nossa equipa de apoio.\n\n# 3. Extra\n** Caso ainda precise de ajuda** Se você ainda precisar de ajuda para configurar o seu webchat entre em contato conosco\n",
      WORDPRESS:
        "# 1. Obtenha o módulo de extensão\nVá para o seu painel do Wordpress. Clique em **Módulos de Extensão / Adicionar Novo**\n\nVocê deve obter o módulo de extensão a partir [deste link](https://cdn.talqui.chat/static/plugins/urn%3Atalqui%3Awordpress%3A0/talqui-wordpress-plugin.zip) e carregá-lo em **/wp-content/plugins**\n\n# 2. Conecte-se a Reinvent\n Vá para a secção **Configurações da Reinvent**, no menu esquerdo. Clique em **Conectar com a Reinvent**.\n\n# 3. Explore a Reinvent\nVá para o seu website [{website_name}](http://{website_domain}) e sua janela de chat deve estar a funcionar. Se tiver algum problema, apague o seu cache e contacte o suporte.",
      SHOPIFY:
        "# 1. Obtenha o módulo de extensão\nVá para o [Reinvent Shopify Plugin](https://plugins.talqui.chat/urn:Reinvent.im:shopify/index.php/shopify/download) e clique em **Obter**.\n\n# 2. Aceda às suas aplicações da Shopify\nNo menu esquerdo, vá para a página **Aplicações** no seu Shopify. Vá para a aplicação *Reinvent* e clique em **Conectar com a Reinvent**.\n\n# 3. Explore a Reinvent\nVá para owebsite [{website_name}](http://{website_domain}) e sua janela de chat deve estar a funcionar. Se tiver algum problema, apague o seu cache e entre em contato com o suporte.",
      EMAIL:
        "# 1. E-mails na Reinvent\nComo o *Reinvent* é uma caixa de entrada de equipa, você pode receber e-mails no seu *Reinvent*. Os e-mails estão  a funcionar como redirecionamentos, encaminhando, por exemplo, **contact{\\'@\\'}{website_domain}** para o seu *Reinvent*. Você vai adorar.\n\n# 2. Copie o seguinte e-mail\n`{email_address}`\n\n# 3. Configurar encaminhamento de email\nConfigure um encaminhamento de email no painel do seu fornecedor de e-mail para o e-mail *Reinvent* fornecido.",
      PRESTASHOP:
        "# 1. Obtenha o módulo de extensão\nObtenha o módulo de extensão a partir [desta hiperligação](https://github.com/Reinvent-im/Reinvent-plugin-prestashop/raw/master/dist/Reinvent.zip).\n\n# 2. Carregue o seu módulo de extensão\nCarregue o módulo de extensão *Reinvent* para o diretório **módulos**. Verifique se o módulo *Reinvent* tem o nome **Reinvent**.\n\n# 3. Ative a Reinvent\nVá para a página **módulos e serviços**. Pesquise *Reinvent* e clique em **configurar**. De seguida, clique em **Conectar com a Reinvent**.\n\nPor fim, aceda ao website [{website_name}](http://{website_domain}) e a sua janela de chat deve estar a funcionar. Se tiver algum problema, apague o seu cache e entre em contato com o suporte.",
      WHMCS:
        "# 1. Obtenha o módulo de extensão\nObtenha o módulo de extensão a partir [desta hiperligação](https://github.com/Reinvent-im/Reinvent-plugin-whmcs/raw/master/dist/Reinvent.zip).\n\n# 2. Carregue o módulo de extensão\nCarregue o módulo de extensão do *Reinvent* para o diretório **módulos/complementos**. Certifique-se que o módulo do Reinvent se chama **Reinvent**.\n\n# 3. Ative a Reinvent\nVá para a página de complementos para ativar o * Reinvent *. ** Aqui está sua chave de licença: **\n\n`{website_id}`\n\nFinalmente, vá para o [[website_name} site](http://{website_domain}) e a sua janela de chat deve estar a funcionar. Se tiver algum problema, primeiro apague o seu cache e depois contacte o suporte.",
      SMS: "# 1. Obtenha o módulo de extensão\nObtenha o módulo de extensão a partir [desta hiperligação](/website/{website_id}/plugins/filter/all/?query=urn%3ATalqui.im%3Atwilio%3A0).\n\n# 2.Instale o módulo de extensão\n**Clique em instalar e vá para as configurações do módulo de extensão.**\n\n# 3. Vincule-o ao Twilio\n**Você precisa de colar as suas credenciais do Twilio (ID do Twilio e o Token do Twilio).** Depois, continue e selecione o seu número de telefone do Twilio SMS. O seu Reinvent estará ligado ao Twilio. Se tiver algum problema, contacte o suporte.",
      MESSENGER:
        '# 1. Obtenha o módulo de extensão\nObtenha o módulo de extensão a partir [desta hiperligação](/website/{website_id}/plugins/filter/all/?query=urn%3ATalqui.im%3Amessenger%3A0).\n\n# 2.Instale o módulo de extensão\n**Clique em instalar e vá para as configurações do módulo de extensão.** Finalmente clique em "Conectar com o Messenger"\n\n# 3. Vincule-o ao Facebook\n**Você será redirecionado para o Facebook.** Siga os passos e selecione a sua página do Facebook. Se tiver algum problema, contacte o suporte.',
      IOS: "# 1. Instale o SDK\nInstale o SDK como explicado na nossa [página GitHub](https://github.com/Reinvent-im/Reinvent-sdk-ios).\n\n# 2. Instale o Reinvent no AppDelegate\nDepois de importar a Reinvent, você pode incluí-lo no **AppDelegate**:\n```{ios_delegate_code}```\n\n\n# 3. Abra a Reinvent\nAgora você pode abrir o Reinvent a partir do seu **View Controller**:\n```{ios_view_code}```\n\n",
      ANDROID:
        "# 1. Instalar o SDK\nInstale o SDK como explicado na nossa [página do GitHub](https://github.com/Reinvent-im/Reinvent-sdk-android).\n\n# 2. Iniciar a classe de aplicação\nDepois de importar Reinvent, você pode incluí-lo na subclasse **Aplicação**:\n```{android_application_code}```\n\n\n# 3. Lançamenta Reinvent\nPode agora lançar Reinvent a partir da sua **Actividade**:\n```{android_activity_code}```\n",
    },
    FILTER: {
      TITLE: "Filtro Avançado",
      CONTROLS_DONE: "Concluído",
      FORM_AND: "E",
      FORM_FIELD_PLACEHOLDER_STRING: "Insira um valor (e pressione Enter)",
      FORM_FIELD_PLACEHOLDER_NUMBER: "Insira um valor numérico",
      FORM_FIELD_PLACEHOLDER_NUMBER_HOURS: "Insira um número de horas",
      FORM_FIELD_PLACEHOLDER_NUMBER_SUFFIX: "Horas",
      FORM_FIELD_PLACEHOLDER_BOOLEAN: "Marque ou desmarque",
      FIELDS: {
        CRITERIA: {
          PEOPLE_EMAIL: "Endereço de e-mail",
          PEOPLE_PERSON_NAME: "Nome completo do contato",
          PEOPLE_PERSON_GENDER: "Gênero do contato",
          PEOPLE_PERSON_GENDER_MALE: "Masculino",
          PEOPLE_PERSON_GENDER_FEMALE: "Feminino",
          PEOPLE_PERSON_PHONE: "Telefone do contato",
          PEOPLE_PERSON_TIMEZONE: "Fuso horário do contato",
          PEOPLE_PERSON_LOCALES: "Idioma do contato",
          PEOPLE_PERSON_LOCATION_COUNTRY: "País do contato",
          PEOPLE_PERSON_LOCATION_REGION: "Região do contato",
          PEOPLE_PERSON_LOCATION_CITY: "Cidade do contato",
          PEOPLE_PERSON_JOB_NAME: "Nome do trabalho",
          PEOPLE_PERSON_JOB_TITLE: "Título do trabalho",
          PEOPLE_PERSON_JOB_SENIORITY_EXECUTIVE: "Executivo",
          PEOPLE_PERSON_JOB_SENIORITY_DIRECTOR: "Diretor",
          PEOPLE_PERSON_JOB_SENIORITY_MANAGER: "Gerente",
          PEOPLE_COMPANY_NAME: "Nome da empresa",
          PEOPLE_COMPANY_DOMAIN: "Nome do domínio da empresa",
          PEOPLE_COMPANY_TIMEZONE: "Fuso horário da empresa",
          PEOPLE_COMPANY_LOCATION_COUNTRY: "País da empresa",
          PEOPLE_COMPANY_LOCATION_REGION: "Região da empresa",
          PEOPLE_COMPANY_LOCATION_CITY: "Cidade da empresa",
          PEOPLE_ACTIVE: "Data da última atividade do contato",
          PEOPLE_SCORE: "Avaliação fornecida pelo contato",
          PEOPLE_SEGMENTS: "Tags do contato",
          SESSION_TAGS: "Tags do atendimento",
          SESSION_META_DATA: "Dados do atendimento",
          PEOPLE_DATA: "Dados do contato",
          PEOPLE_CREATED: "Data da criação do contato",
          CONVERSATION_STATE: "Estado da conversa",
          CONVERSATION_STATE_PENDING: "Pendente",
          CONVERSATION_STATE_UNRESOLVED: "Não Resolvido",
          CONVERSATION_STATE_RESOLVED: "Resolvido",
          CONVERSATION_AVAILABILITY: "Disponibilidade do usuário",
          CONVERSATION_AVAILABILITY_ONLINE: "Conectado",
          CONVERSATION_AVAILABILITY_AWAY: "Ausente",
          CONVERSATION_AVAILABILITY_OFFLINE: "Desligado",
          CONVERSATION_IS_BLOCKED: "Usuário bloqueado",
          CONVERSATION_META_EMAIL: "E-mail do usuário",
          CONVERSATION_META_PHONE: "Celular do usuário",
          CONVERSATION_META_IP: "IP do usuário",
          CONVERSATION_META_DEVICE_GEOLOCATION_COUNTRY: "País",
          CONVERSATION_META_DEVICE_GEOLOCATION_CITY: "Cidade",
          CONVERSATION_META_DEVICE_LOCALES: "Idioma do usuário",
          CONVERSATION_META_DEVICE_TIMEZONE: "Fuso horário do usuário",
          CONVERSATION_META_DATA: "Dados da conversa",
          CONVERSATION_META_SEGMENTS: "Segmentos da Conversa",
          CONVERSATION_CREATED: "Data de criação",
          CONVERSATION_UPDATED: "Data de atualização",
        },
        OPERATORS: {
          EQUALS_TO: "É igual a",
          DIFFERS_FROM: "Difere de",
          PATTERN: "Tem padrão",
          GREATER_THAN: "Maior que",
          LESS_THAN: "Menor que",
          GREATER_OR_EQUAL: "Maior ou igual",
          LESS_OR_EQUAL: "Menor ou igual",
          EXISTS: "Existe",
          NOT_EXISTS: "Não Existe",
          STARTS_WITH: "Começa com",
          ENDS_WITH: "Termina com",
          NOT_PATTERN: "Nenhum padrão",
        },
        SCORE: {
          BAD: "Mau",
          NORMAL: "Comum",
          GREAT: "Ótimo",
        },
      },
      CONTROLS_CREATE: "Guardar Este Filtro",
      CONTROLS_UPDATE: "Atualizar este filtro",
      CONTROLS_DELETE: "Eliminar este filtro",
      CONTROLS_CANCEL: "Cancelar",
      CONTROLS_NEW: "Novo Filtro",
      CRITERIA: {
        SEARCH_PLACEHOLDER: "Critérios de filtro",
        SEARCHED: "Critérios pesquisados",
        MOST_USED: "Critérios mais usados",
        ALL: "Todos os critérios",
      },
      REMOVE_FILTER_TITLE: "Tem a certeza que quer remover este filtro?",
      REMOVE_FILTER_DESCRIPTION: "Não poderá voltar a usar este filtro.",
      REMOVE_FILTER_PROCEED: "Remover filtro",
    },
    NEW_CAMPAIGN: {
      TITLE: "Criar uma nova campanha",
      STEP_TYPE: "Tipo de Campanha",
      STEP_NAME: "Nome da Campanha",
      TYPE_TITLE: "Selecione um tipo de campanha para criar",
      TYPE_LABEL:
        "Você pode criar uma campanha única; ou então uma campanha automatizada e contínua que é enviada para um contato quando um evento é acionado.",
      TYPE_PICKER_ONE_SHOT_TITLE: "Campanha Única",
      TYPE_PICKER_ONE_SHOT_DESCRIPTION:
        "Enviada uma vez para todos os contatos selecionados, ex. newsletter sobre um produto.",
      TYPE_PICKER_AUTOMATED_TITLE: "Campanha Automatizada",
      TYPE_PICKER_AUTOMATED_DESCRIPTION:
        "Enviada automaticamente a partir de eventos, ex. lembrar contatos inativos.",
      TYPE_PROCEED: "Continuar Com Este Tipo De Campanha",
      NAME_TITLE: "Digite o nome de sua campanha",
      NAME_LABEL:
        "O nome da campanha será usado como o assunto do e-mail que será enviado. Ou seja, será visível para todos os contatos selecionados.",
      NAME_FIELD_NAME_LABEL: "Nome da Campanha",
      NAME_FIELD_NAME_PLACEHOLDER: "Digite o nome da campanha…",
      NAME_PROCEED: "Configurar Minha Campanha",
    },
    EDIT_CAMPAIGN_NAME: {
      LABEL: "Nome da campanha",
      PLACEHOLDER: "Digite o nome (ex. \\'Atualização de Produto\\')",
      ACTION: "Editar Nome",
    },
    EDIT_CAMPAIGN_TAG: {
      NEW_LABEL: "Novo Marcador de Campanha",
      NEW_PLACEHOLDER: "Digite um novo marcador (ex. \\'Newsletters\\')",
      ADD_TAG: "Adicionar marcador…",
      NO_TAG: "Sem marcadores",
      ACTION_CLEAR: "Editar sem marcador",
      ACTION_ADD: "Editar com novo marcador",
      ACTION_USE: "Editar com marcador existente",
    },
    NEW_TEMPLATE: {
      NAME_LABEL: "Nome",
      NAME_PLACEHOLDER: "Digite o nome do modelo…",
      FORMAT_LABEL: "Formato (pode ser alterado depois)",
      PROCEED: "Começar a editar meu modelo",
    },
    IMPORT_CONTACTS: {
      TITLE: "Importar Perfis de Contatos",
      STEP_FILE: "Selecionar Arquivo",
      STEP_CONFIGURE: "Configurar Importação",
      STEP_IMPORT: "Prosseguir Importação",
      UPLOAD_TITLE:
        "Carregue um arquivo CVS para importar os perfis dos contatos",
      UPLOAD_LABEL:
        "Você pode enviar um arquivo CSV padrão contendo o nome completo e o e-mail dos seus contatos, para adicioná-los à sua base de dados da Reinvent.",
      UPLOAD_FORM_LABEL: "Enviar arquivo CSV (codificado em UTF-8)",
      UPLOAD_PROCEED: "Iniciar a importação do CSV",
      CONFIGURE_TITLE: "Configurar a Importação do CSV",
      CONFIGURE_LABEL:
        "Mapeie os campos personalizados dos contatos do seu CSV para os campos reconhecidos pela Reinvent. Uma vez configurados, o Reinvent estará pronto para executar a importação.",
      CONFIGURE_OPTIONS_TITLE: "Opções de Análise",
      CONFIGURE_OPTIONS_COLUMN_SEPARATOR: "Separador de Coluna",
      CONFIGURE_OPTIONS_SKIP_HEADER: "Pular cabeçalho",
      CONFIGURE_POPUP_CUSTOM_DATA_LABEL: "Chave de dados personalizados",
      CONFIGURE_POPUP_CUSTOM_DATA_PLACEHOLDER:
        "Insira uma chave de dados personalizada (ex. user_id)",
      CONFIGURE_POPUP_CUSTOM_DATA_PROCEED: "Criar chave de dados personalizada",
      CONFIGURE_MAPPING_COLUMN: "Coluna",
      CONFIGURE_MAPPING_LABEL: "Mapeie para…",
      CONFIGURE_MAPPING_SKIP: "Saltar este campo",
      CONFIGURE_MAPPING_CHOICES: {
        EMAIL: "Endereço de E-mail",
        PERSON_FIRSTNAME: "Primeiro nome",
        PERSON_LASTNAME: "Sobrenome",
        PERSON_NICKNAME: "Nome completo",
        PERSON_PHONE: "Número de telefone",
        PERSON_ADDRESS: "Endereço postal",
        PERSON_WEBSITE: "Site pessoal",
        PERSON_GEOLOCATION_COUNTRY: "País",
        PERSON_GEOLOCATION_CITY: "Cidade",
        COMPANY_NAME: "Companhia",
        SEGMENTS: "Segmentos",
        NOTEPAD: "Notas privado",
        CREATED_AT: "Data de criação",
        ACTIVE: "Data da última ativação",
        IS_UNSUBSCRIBED: "Foi Cancelado",
      },
      CONFIGURE_PROCEED: "Executar importação agora",
      PROCEED_TEXT_TITLE_PENDING: "Importando perfis dos contatos…",
      PROCEED_TEXT_TITLE_DONE: "Importação dos perfis dos contatos concluída!",
      PROCEED_TEXT_LABEL_IMPORTED: "contatos importados",
      PROCEED_TEXT_LABEL_SKIPPED: "pulou",
      PROCEED_TEXT_LABEL_REMAINING: "restante.",
      PROCEED_TEXT_LABEL_ESTIMATED: "(Deve terminar em {minutes} minutos)",
      PROCEED_CLOSE: "Fechar Importação",
      PROCEED_HELP:
        "Pode fechar o popup. A sua importação ainda será executada.",
    },
    IMPORT_HELPDESK: {
      TITLE: "Importar Artigos da Central de Ajuda",
      STEP_CONFIGURE: "Configurar Importação",
      STEP_IMPORT: "Prosseguir Importação",
      CONFIGURE_TITLE: "Importar Central de Ajuda com um clique",
      CONFIGURE_LABEL:
        "O Reinvent vai automaticamente configurar e ajustar artigos do seu centro de ajuda anterior. Submeta o URL do seu centro de ajuda e comece a importação.",
      FORM_FIELD_HELPDESK_URL_LABEL: "URL do Centro de Ajuda Anterior",
      FORM_FIELD_HELPDESK_URL_PLACEHOLDER: "docs.empresa.help",
      PROCEED_TITLE: "O seu centro de ajuda está a ser importado",
      PROCEED_LABEL:
        "O nosso bot começou a converter o seu centro de ajuda. Isto pode levar até 10 minutos. Iremos notificá-lo quando o seu centro de ajuda estiver pronto para ser usado.",
      CONFIGURE_PROCEED: "Importar Central de Ajuda",
      PROCEED_CLOSE: "Ok, Fechar Isto",
    },
    ROUTING_RULE: {
      TITLE: "Regra de Encaminhamento",
      STEP_NAME: "Nome",
      STEP_CONDITIONS: "Condições",
      STEP_ASSIGN: "Atribuir para",
      NAME_TITLE: "Escolha um nome para a sua regra",
      NAME_LABEL:
        "O nome da regra ajuda a identificar essa regra específica entre todas as suas regras. Não se esqueça de ajustar a prioridade da regra após adicioná-la.",
      NAME_FIELD_NAME_LABEL: "Nome da Regra",
      NAME_FIELD_NAME_PLACEHOLDER: "Insira o nome da regra…",
      NAME_PROCEED: "Continuar para Condições da Regra",
      CONDITIONS_PICKER_TITLE: "Selecionar condições de encaminhamento",
      CONDITIONS_PICKER_LABEL:
        "Quando uma nova conversa é iniciada, algumas condições de encaminhamento opcionais podem ser verificadas antes de atribuir a conversa a alguém.",
      TYPE_PICKER_SIMPLE_TITLE: "Condição Simples",
      TYPE_PICKER_SIMPLE_DESCRIPTION:
        "Atribuir diretamente a operadores específicos.",
      TYPE_PICKER_ADVANCED_TITLE: "Condição Avançada",
      TYPE_PICKER_ADVANCED_DESCRIPTION:
        "Atribuir com base em segmentações, dados do usuários, local do usuário etc.",
      CONDITIONS_PICKER_PROCEED: "Continuar com esta Condição",
      CONDITIONS_ADVANCED_PROCEED: "Usar Estas Condições Avançadas",
      ASSIGN_TITLE: "Escolher operadores para serem designados",
      ASSIGN_LABEL:
        "Quando todas as condições de encaminhamento coincidirem, um operador alvo ou alguém do grupo de alvo é atribuído à conversa.",
      ASSIGN_NOTICE_TITLE: "Você selecionou mais do que um operador.",
      ASSIGN_NOTICE_LABEL:
        "Se uma nova conversa coincidir com a regra de encaminhamento, vai ser designada ao operador mais ativo (ou seja, um operador conectado, ou ao último operador conectado se todos estiverem ausentes). Desta forma, o operador designado tem mais hipóteses de responder rápido.",
      ASSIGN_WARNING_TITLE: "Operadores fantasmas selecionados.",
      ASSIGN_WARNING_LABEL:
        "Os operadores fantasmas são operadores que anteriormente eram adicionados a esta regra, mas que já não são membros do seu site. Você pode removê-los com segurança da regra.",
      ASSIGN_PROCEED: "Guardar Regra de Encaminhamento",
    },
    MANAGE_LIMIT: {
      TITLE: "Atualize seu {name} Limites",
      STEP_CONFIGURE: "Configurar o limite",
      INTRODUCTION_TITLE_LIMIT: "O que é o {name}",
      INTRODUCTION_TITLE_QUOTA: "O que é a cota {name}?",
      INTRODUCTION_DESCRIPTION_DEFAULT:
        "Seu atual plano Reinvent lhe permite um certo nível de uso dos limites do {name}, o que é suficiente para a maioria da equipe. Se você precisar de mais, você pode atualizar para um limite superior.",
      INTRODUCTION_DESCRIPTION_TRANSLATE_REQUESTS:
        "Seu plano atual Reinvent lhe permite traduzir uma certa quantidade de mensagens por semana. Se você precisar traduzir mais, você pode atualizar para um limite superior.",
      INTRODUCTION_DESCRIPTION_TRANSLATE_CHARACTERS:
        "Seu plano atual Reinvent lhe permite traduzir uma certa quantidade de caracteres por semana. Se você precisar traduzir mais, você pode atualizar para um limite superior.",
      INTRODUCTION_DESCRIPTION_BUCKET_URL:
        "Seu plano atual Reinvent permite que você faça o upload de uma certa quantidade de arquivos por dia. Se você precisar fazer mais upload, você pode atualizar para um limite superior.",
      INTRODUCTION_DESCRIPTION_OPERATOR_INVITES:
        "Seu atual plano Reinvent permite que sua equipe cresça para um certo número de operadores. Se você precisar convidar mais agentes, por favor, atualize seu limite.",
      INTRODUCTION_DESCRIPTION_PEOPLE_PROFILES:
        "Seu atual plano Reinvent permite armazenar um certo número de contatos em seu Reinvent CRM , o que é suficiente para a maioria da equipe. Se você precisar armazenar mais contatos, você pode atualizar para um limite superior.",
      INTRODUCTION_DESCRIPTION_STATUS_PROBES:
        "Seu plano atual Reinvent lhe permite monitorar uma quantidade máxima de anfitriões com Reinvent Status. Para monitorar mais, por favor, atualize seus limites.",
      INTRODUCTION_DESCRIPTION_CAMPAIGN_RECIPIENT:
        "Seu atual plano Reinvent permite enviar campanhas para um determinado número máximo de destinatários. Você precisa enviar campanhas para mais pessoas? Você pode melhorar seus limites lá!",
      INTRODUCTION_MORE: "Leia mais sobre limites",
      POINT_INCLUDED_TITLE: "Incluído em seu plano:",
      POINT_INCLUDED_UNITS_IN_PLAN: "(em plano)",
      POINT_ADDITIONAL_TITLE: "Limites adicionais:",
      POINT_ADDITIONAL_UNITS_PER_UNIT: "(por unidade)",
      POINT_TOTAL_TITLE: "Total limite:",
      POINT_TOTAL_UNITS_TOTAL: "total",
      POINT_TOTAL_PER_TIME: "Cada {time}",
      POINT_TOTAL_NOTICE_ENTRIES_QUOTA: "Você já usou",
      POINT_TOTAL_NOTICE_ENTRIES_LIMIT: "Você tem atualmente",
      POINT_TOTAL_NOTICE_INSUFFICIENT: "(isto não é suficiente)",
      POINT_TYPE_DEFAULT: "itens",
      POINT_TYPE_TRANSLATE_REQUESTS: "mensagens traduzidas",
      POINT_TYPE_TRANSLATE_CHARACTERS: "caracteres traduzidos",
      POINT_TYPE_BUCKET_URL: "upload de arquivos",
      POINT_TYPE_OPERATOR_INVITES: "operadores",
      POINT_TYPE_PEOPLE_PROFILES: "contatos",
      POINT_TYPE_STATUS_PROBES: "anfitriões monitorados",
      POINT_TYPE_CAMPAIGN_RECIPIENT: "destinatários da campanha",
      NAMESPACE_FILTER_CONVERSATION: "Filtros de conversação executados",
      NAMESPACE_FILTER_PEOPLE: "Filtros de contato executados",
      NAMESPACE_TRANSLATE_REQUESTS: "LiveTranslate mensagens traduzidas",
      NAMESPACE_TRANSLATE_CHARACTERS: "LiveTranslate caracteres traduzidos",
      NAMESPACE_BUCKET_URL: "Chatbox arquivos carregados",
      NAMESPACE_OPERATOR_INVITES: "Limite de operadores de caixas de entrada",
      NAMESPACE_PEOPLE_PROFILES: "CRM limite de contatos",
      NAMESPACE_STATUS_PROBES: "Limite de nós da página de status",
      NAMESPACE_CAMPAIGN_RECIPIENT: "Limite de destinatários da campanha",
      DISCLAIMER:
        "Os limites são cobrados mensalmente, ao mesmo tempo que o seu plano.",
      PROCEED: "Ativar esses limites",
    },
    CONFIGURE_TOKEN: {
      TITLE: "Ativar a Autenticação de Dois Fatores",
      STEP_PREPARE: "Preparar",
      STEP_SCAN: "Escanear código",
      STEP_VERIFY: "Verificar código",
      PREPARE_WARNING_TITLE:
        "Se perder o seu telefone, será bloqueado da sua conta.",
      PREPARE_WARNING_LABEL:
        "Portanto, você deve fazer uma cópia da sua chave TOTP. Aconselhamos que escreva a chave privada que forneceremos na próxima etapa num pedaço de papel.",
      PREPARE_PROCEED: "Continuar Configuração",
      SCAN_TITLE: "Digitalize isto com a sua aplicação de autenticação",
      SCAN_LABEL:
        "Se você não conseguir digitalizar o código, também poderá instalar a seguinte chave privada na sua aplicação de autenticação. Também pode fazer uma cópia em papel.",
      SCAN_CODE_COPY: "Copiar chave privada",
      GET_HELP: "Precisa de ajuda?",
      SCAN_PROCEED: "Eu Digitalizei O Código",
      VERIFY_TITLE: "Verificar o código digitalizado",
      VERIFY_LABEL:
        "Para garantir que sua chave privada está instalada corretamente, digite o código de 6 dígitos que a sua aplicação de autenticação gera abaixo.",
      VERIFY_FIELD_TOKEN_LABEL:
        "Confirme o seu token (Autenticação de Dois Fatores)",
      VERIFY_FIELD_TOKEN_PLACEHOLDER: "Insira o seu token de 6 dígitos…",
      VERIFY_PROCEED: "Verificar Código e Finalizar",
      PREPARE_LABEL:
        "A Autenticação de Dois Fatores torna sua conta do Reinvent mais segura, pedindo um código temporário no acesso. O código é gerado por um aplicativo TOTP no seu telemóvel. Pode usar [Google Authenticator](https://support.google.com/accounts/answer/1066447), [OnePassword](https://1password.com/).",
    },
    MOBILE: {
      TITLE: "Transferir aplicações do Reinvent",
      LABEL: "Para usar o Reinvent no seu celular, baixe o aplicativo.",
      DOWNLOAD_IOS: "Reinvent para iOS",
      DOWNLOAD_ANDROID: "Reinvent para Android",
      GO_SETTINGS: "Ir para Configurações",
      OR: "Ou",
    },
    CLOSE_WARNING: {
      SAVE_TITLE: "Quer salvar seu {type} antes de sair?",
      SAVE_DESCRIPTION: "As mudanças que não forem salvas serão perdidas.",
      CLOSE_BACK: "Voltar",
      CLOSE_WARNING: "Fechar sem salvar",
      TYPES: {
        CAMPAIGN: "campanha",
        TEMPLATE: "modelo",
        ARTICLE: "artigo",
      },
    },
    SWITCH_PLAN: {
      BANNER: {
        STEP_CHOICE: "Escolha",
        STEP_BILLING: "Detalhes",
      },
      HEADER: {
        STEP_CHOICE: {
          TITLE: "Crie experiências fantásticas para seus clientes",
          SUBTITLE: "Seu plano atual é ",
        },
        STEP_CHANGES: {
          TITLE: "Confira as mudanças em seu plano",
          SUBTITLE_PART_1: "Obrigado pelo seu tempo no plano",
          SUBTITLE_PART_2:
            "Estes são os benefícios que você está quase perdendo.",
        },
        STEP_BILLING: {
          TITLE: "Você está quase no {selected_plan_name}!",
          SUBTITLE: "Atualize para Desbloquear mais recursos",
        },
        BENEFITS: {
          TRIAL: "14 dias de teste",
          FAIR: "Preços fixos",
          CANCEL: "Cancelar a qualquer momento",
        },
      },
      WARNING: {
        NOT_OWNER:
          "Não é possível editar sua assinatura porque você não é proprietário de sua equipe",
      },
      PLANS: {
        BASIC: {
          ENTICE: "Para experiências básicas de conversação.",
          FEATURES: [
            "Software de bate-papo ao vivo",
            "Aplicativos móveis",
            "30 dias de história",
            "Notificações",
          ],
        },
        PRO: {
          ENTICE:
            "Crie uma caixa de entrada compartilhada para centralizar suas mensagens de vários canais e aumentar o leadgen.",
          FEATURES: [
            "Histórico ilimitado de bate-papo",
            "Respostas Enlatadas",
            "Caixa de entrada de e-mail",
            "Integração com o Messenger",
            "Gatilho de bate-papo ao vivo",
          ],
        },
        UNLIMITED: {
          ENTICE:
            "Aproveite uma plataforma de suporte ao cliente com todos os recursos para se comunicar com leads e clientes em todos os canais.",
          FEATURES: [
            "Base de conhecimento",
            "Chatbot",
            "Campanhas",
            "CRM",
            "Encaminhamento / atribuição",
            "MagicBrowse e videochamadas",
            "Logotipo nítido",
          ],
        },
        PER_MONTH: "/ m",
        AGENTS: "agentes",
        UNLIMITED_AGENTS: "Agentes ilimitados",
        UNLIMITED_AGENTS_EXPLAIN_MAIN: "20 agentes incluídos gratuitamente",
        UNLIMITED_AGENTS_EXPLAIN_SUB:
          "Adicionar mais agentes acima disso por uma pequena taxa",
        UPGRADE_TO: "Atualize para {plan}",
        DOWNGRADE_TO: "Faça o downgrade para {plan}",
        CURRENT_PLAN: "Seu plano atual",
        CONTINUE_WITH_PLAN: "Continue com este plano",
      },
      COMPARE_OFFERS: "Compare nossas ofertas",
      CONTACT_US: "Dúvidas? Fale conosco!",
      DOWNGRADE: {
        TITLE:
          "O cancelamento do seu plano afeta o suporte ao cliente. Verifique as alterações.",
        CONFIRM: {
          TITLE: "Você ainda deseja fazer o cancelamento?",
          SUBTITLE:
            "Ao fazer o cancelamento hoje, você perde todos os recursos do seu plano atual. Também notificaremos os agentes afetados que sua conta foi desativada.",
          LABEL: "Entendo as mudanças e como elas afetarão minha equipe",
          BUTTON_DOWNGRADE: "Aplique",
          BUTTON_KEEP: "Manter meu plano atual",
        },
      },
      PAYMENT: {
        SELECT_BILLING: {
          TITLE: "Selecione seu método de cobrança",
          ADD_BILLING_METHOD: "Adicionar um método de cobrança",
        },
        ADD_BILLING_METHOD: {
          TITLE: "Selecione seu método de cobrança",
          ADD_CREDIT_CARD: "Pagar com cartão de crédito",
          ADD_PAYPAL: "Pagar com PayPal",
        },
        BILLING_DETAILS: {
          TITLE: "Seus detalhes de cobrança",
        },
        PAYMENT_DETAILS: {
          TITLE: "Detalhes do pagamento",
        },
        SUMMARY: {
          TITLE: "Resumo do Pedido",
          TODAYS_CHARGE: "Cobrança de hoje",
          STATEMENT:
            "Será cobrado agora o valor proporcional ao período de faturamento atual. Sua assinatura é **faturada mensalmente** e será renovada automaticamente por {currency}{price} em **{date}**. Você pode cancelar a qualquer momento.",
          ITEM_1: "Este é um pagamento seguro e criptografado com o Stripe",
          ITEM_2: "Sem engajamento",
          ITEM_3: "Preços fixos",
          BUTTON_UPGRADE: "Atualize para Reinvent {plan_name}",
          BUTTON_SOON_DONE: "Concluído em breve, aguarde",
        },
      },
      BENEFITS: {
        AGENTS:
          "Sua equipe tem atualmente **{operators} agentes**. Seu novo novo plano inclui apenas **{max} agentes**. Alguns agentes perderão o acesso.",
        CRM: "O recurso de contatos está incluído apenas no Reinvent Unlimited.\n\nVocê perderá o acesso aos seus **{contacts} contatos**.",
        TRIGGERS:
          "No momento, você está usando **{triggers} gatilhos** em seu site.\n\nComo o plano gratuito não inclui gatilhos, você poderá ter **2x menos leads** no futuro.",
        HISTORY:
          "O plano gratuito possui um **histórico de mensagens de 30 dias**. Após esse período, as mensagens enviadas anteriormente são ocultadas. **Isso inclui conversas existentes**.\n\nSeu plano atual possui uma reserva de mensagens **ilimitada**.",
        FILES:
          "**Você não poderá mais compartilhar arquivos** usando o plano gratuito. Isso inclui arquivos regulares, GIFs, imagens e mensagens de áudio.",
        BOT: "O plugin Reinvent Bot é oferecido apenas no Reinvent Unlimited. O bot irá parar de responder automaticamente a seus clientes",
        HELPDESK:
          "O Reinvent Helpdesk é oferecido apenas no Reinvent Unlimited. O domínio **help.{domain}** será **desativado** e **todos os seus artigos**.",
        EMAIL:
          "Os e-mails enviados para o contato{\\'@\\'}{domain} **não serão mais redirecionados para sua Reinvent Inbox**.",
        CAMPAIGNS:
          "No momento, você está enviando campanhas de marketing usando a Reinvent. ** Este recurso será desativado **. As campanhas Reinvent são oferecidas apenas na Reinvent Unlimited.",
        MESSENGER:
          "Não será possível conversar com clientes do Messenger. Esse recurso não está incluído no plano gratuito.",
        TELEGRAM:
          "No momento, o Reinvent está vinculado ao seu Telegram. Esse recurso não está incluído no plano gratuito.",
        ZAPIER:
          "No momento, o Reinvent está vinculado à sua conta Zapier. Este recurso está disponível apenas no Reinvent Unlimited.",
        SEARCH:
          "Você **não poderá pesquisar** conversas e contatos anteriores. O recurso de pesquisa não está incluído no plano gratuito.",
        MAGICBROWSE:
          "O recurso MagicBrowse está disponível apenas no Reinvent Unlimited. Você não será capaz de cobrowsing com seus clientes.",
        SLACK:
          "Sua equipe do Slack não poderá receber e responder a mensagens Reinvent.",
        CUSTOMIZATION:
          "Não será possível **personalizar sua caixa de bate-papo** usando códigos de cores e textos personalizados.",
        SHORTCUTS:
          "**Todos os modelos de resposta serão desativados** e você não poderá mais usá-los.",
      },
      FEATURES: {
        MESSAGING: "Mensagens",
        "UNLIMITED-HISTORY": "História Ilimitada",
        AGENTS: "Agentes",
        HISTORY: "História",
        TRIGGERS: "Gatilhos",
        SHORTCUTS: "Atalhos",
        NOTES: "Notas",
        CUSTOMIZATION: "Costumização",
        AUDIO: "Áudio",
        FILES: "Compartilhamento de arquivos",
        SLACK: "Slack",
        FACEBOOK: "Facebook",
        TWITTER: "Twitter",
        EMAIL: "O email",
        TELEGRAM: "Telegram",
        MAGICMAP: "MagicMap",
        SEARCH: "Procurar",
        BLOCK: "Quadra",
        HELPDESK: "Base de conhecimento",
        STATUS: "Página Status",
        REMINDERS: "Lembretes de bate-papo",
        ROUTING: "Encaminhamento / Atribuição",
        MAGICBROWSE: "MagicBrowse",
        LIVETRANSLATE: "LiveTranslate",
        "AUTO-RESPONDER": "Resposta automática",
        BOT: "Robô",
        "AUTOMATED-CAMPAIGNS": "Campanhas automatizadas",
        CAMPAIGNS: "Campanhas de marketing",
        CRM: "CRM",
        "VIDEO-CALLS": "Chamada de Vídeo",
        ANALYTICS: "Métricas",
        SMS: "SMS",
        MESSENGER: "Messenger",
        LINE: "Line",
        ZAPIER: "Zapier",
        SALESFORCE: "Salesforce",
        PIPEDRIVE: "Pipedrive",
        ZOHO: "Zoho",
        HUBSPOT: "Hubspot",
        "USER-RATINGS": "Classificações de usuários",
        DEBRANDING: "Debranding",
      },
    },
    PLAN_UPDATED: {
      HEADER: "Seu plano foi atualizado",
      TITLE: "Comece a desfrutar do seu Plano Reinvent",
      SUBTITLE_BASIC:
        "Obrigado por usar Reinvent! Seu plano foi atualizado. Você pode nos contatar a qualquer momento se você tiver alguma dúvida sobre sua assinatura.",
      SUBTITLE_PAID:
        "Obrigado por usar Reinvent! Seu plano foi atualizado. Aqui estão algumas novidades que você pode desfrutar com o seu novo plano.",
      SLIDESHOW: {
        PRO: {
          TRIGGERS: "Enviar mensagens automáticas usando gatilhos",
          SHORTCUTS: "Aumentar a produtividade usando respostas de atalho",
          EMAIL:
            "Gerenciar e-mails diretamente da sua caixa de entrada Reinvent",
          CUSTOMIZATION: "Melhor experiência e personalização de mensagens",
          NOTES:
            "Colaborar com colegas de equipe usando notas e menções particulares",
        },
        UNLIMITED: {
          KNOWLEDGE: "Instruir os clientes com uma Central de Ajuda",
          BOT: "Criar o seu próprio robô para automatizar respostas",
          ROUTING:
            "Configurar regras de roteamento e designação automática de agentes",
          ANALYTICS: "Use estatísticas para tomar decisões com base em dados",
          MESSAGING: "Construir experiências de mensagens multicanal",
        },
        LEARN_MORE: "Saiba mais",
      },
      CONFIRM: "Voltar ao meu painel",
    },
    AUTHENTICATION: {
      CONFIRM_LOGOUT_TITLE: "De certeza que deseja sair da Reinvent?",
      CONFIRM_LOGOUT_DESCRIPTION:
        "Você precisará fazer login novemente para acessar seu painel de atendimento",
      CONFIRM_LOGOUT_PROCEED: "Sair da Reinvent",
    },
    SET_NICKNAME: {
      LABEL: "Selecionar um nome",
      PLACEHOLDER: "Inserir um nome... (ex. Isabella)",
      ACTION: "Definir nome",
    },
    SET_EMAIL: {
      LABEL: "Definir um novo endereço de e-mail",
      PLACEHOLDER: "Digite um novo e-mail… (ex. john.doe{'@'}gmail.com)",
      ACTION: "Definir e-mail",
    },
    SET_PHONE: {
      LABEL: "Digite um novo número de telefone",
      PLACEHOLDER: "Digite um novo número de telefone… (ex. +1628281234567)",
      ACTION: "Telefone fixo",
    },
    SET_WIDGET_ITEM_VALUE: {
      LABEL: "Definir um novo valor",
      PLACEHOLDER: "Digite um valor…",
      ACTION: "Definir valor",
    },
    ADD_PARTICIPANT: {
      EMAIL_LABEL: "Endereço de e-mail do participante",
      EMAIL_PLACEHOLDER: "Insira o endereço de e-mail do participante …",
      ACTION: "Adicionar participante",
    },
    EXTERNAL_TRANSCRIPT: {
      EMAIL_LABEL: "E-mail do usuário externo",
      EMAIL_PLACEHOLDER: "Digite um endereço de e-mail externo…",
      NOTICE:
        "Este usuário externo poderá enviar mensagens dentro da conversa.",
      ACTION: "Enviar Transcrição",
    },
    EDIT_CARD: {
      GO: "Editar Este Cartão",
    },
    CHANGELOG: {
      TITLE: "Ainda não há atualização.",
      EMPTY_NO_UPDATES_TITLE: "Nenhuma actualização",
      EMPTY_NO_UPDATES_DESCRIPTION: "Ainda não há qualquer actualização.",
    },
  },
  PLUGINS: {
    DEFAULT: {
      CONTROLS_MENU_PLUGINS: "Extensões",
      ROW_PLAN_UPGRADE_CHECKOUT: "Checkout separado",
      ROW_ACTIONS_VIEW: "Ver detalhes",
      EMPTY_NO_PLUGIN_DEFAULT_TITLE: "Não há nenhuma extensão.",
      EMPTY_NO_PLUGIN_DEFAULT_LABEL:
        "Não há nenhuma extensão disponível no momento. Volte mais tarde!",
      EMPTY_NO_PLUGIN_SEARCH_TITLE:
        "Nenhuma extensão foi encontrada para a sua pesquisa.",
      EMPTY_NO_PLUGIN_SEARCH_LABEL:
        "Você pode encontrar módulos de extensão por nome. Ajuste a sua pesquisa!",
      POPUPS_UNINSTALL_QUESTION:
        "Desinstalar este módulo de extensão do seu site?",
      POPUPS_UNINSTALL_LABEL:
        "Este módulo de extensão será desativado no seu site.",
      POPUPS_UNINSTALL_PROCEED: "Desinstalar Módulo de Extensão",
      ROW_ACTIONS_VIEW_TOOLTIP: "Ver os detalhes do módulo de extensão",
      ROW_ACTIONS_SETTINGS_TOOLTIP: "Configurar o módulo de extensão",
      ROW_ACTIONS_UNINSTALL_TOOLTIP: "Desinstalar o módulo de extensão",
    },
    SETTINGS: {
      IDENTITY_BY: "Por",
      PLUGIN_CONTACT: "Suporte de Plugin",
      PLUGIN_DOCUMENTATION: "Documentação",
      DISCLAIMER_UNOFFICIAL_WARNING: "Atenção: este é um terceiro plugin.",
      DISCLAIMER_UNOFFICIAL_NOTICE:
        "Não foi feito por Reinvent e seus dados não são hospedados por Reinvent.",
    },
    COMMON: {
      TITLE: {
        PLUGINS: "Módulo de extensão",
        CATEGORIES: "Categorias",
      },
      PLUGINS: {
        ALL: "Todos os módulos de extensão",
        INSTALLED: "Módulos de extensão instalados",
      },
      CATEGORIES: {
        FEATURE: "Funcionalidades do Reinvent",
        AUTOMATION: "Automação",
        CMS: "CMS",
        CRM: "CRM",
        MARKETING: "Marketing",
        MESSAGING: "Mandar uma mensagem",
        TEAMWORK: "Trabalho em equipe",
        OTHER: "Outros",
      },
    },
    PREVIEW: {
      INSTALL: "Instalar",
      INSTALL_CONFIRM: "{price}, Confirmar?",
      INSTALLED: "Instalado",
      SETTINGS: "Configurações",
      DOCUMENTATION: "Obter ajuda",
      VIDEO: "Ver vídeo",
      WEBSITE: "Site do autor",
      CONTACT: "Contato do autor",
      TERMS: "Termos de uso",
      PRIVACY: "Política de privacidade",
      VIEW_MARKETPLACE: "Ver todos os detalhes",
      INSTALL_PAID: "Módulo de extensão pago:",
      ALREADY_INSTALLED: "Plugin já instalado",
      PER_MONTH: "/mês",
      SEPARATE_CHECKOUT: "Pago separadamente",
      AUTHOR_DEVELOPER: "Desenvolvedor",
      PLAN_FREE: "Grátis",
      SCOPES_PERMISSIONS: "Permissões",
      SCOPES_HELP: "O que são permissões?",
      SCOPES_ACCESS_READ: "Leia",
      SCOPES_ACCESS_WRITE: "Escrever",
      SCOPES_ACCESS_READ_WRITE: "Ler e gravar",
      SCOPE_GROUPS: {
        WEBSITE: "Site",
        MESSAGING: "Mensagens",
        CONTACTS: "Contatos",
      },
      SCOPE_NAMESPACES: {
        BUCKET_URL: "arquivos",
        WEBSITE_AVAILABILITY: "disponibilidade",
        WEBSITE_OPERATORS: "operadores",
        WEBSITE_SETTINGS: "configurações",
        WEBSITE_VERIFY: "verificar configurações",
        WEBSITE_VISITORS: "visitantes",
        WEBSITE_CONVERSATION_INITIATE: "na criação da conversa",
        WEBSITE_CONVERSATION_SESSIONS: "sessões",
        WEBSITE_CONVERSATION_SUGGEST: "sugestões",
        WEBSITE_CONVERSATION_MESSAGES: "mensagens",
        WEBSITE_CONVERSATION_STATES: "status",
        WEBSITE_CONVERSATION_PARTICIPANTS: "participantes",
        WEBSITE_CONVERSATION_PAGES: "páginas",
        WEBSITE_CONVERSATION_EVENTS: "eventos",
        WEBSITE_CONVERSATION_ACTIONS: "ações",
        WEBSITE_CONVERSATION_BROWSING: "MagicBrowse",
        WEBSITE_CONVERSATION_CALLS: "ligações",
        WEBSITE_CONVERSATION_REMINDERS: "lembretes",
        WEBSITE_CONVERSATION_ROUTING: "encaminhamento",
        WEBSITE_PEOPLE_STATISTICS: "estatísticas",
        WEBSITE_PEOPLE_SUGGEST: "sugestões",
        WEBSITE_PEOPLE_PROFILES: "perfis",
        WEBSITE_PEOPLE_CONVERSATIONS: "conversas",
        WEBSITE_PEOPLE_EVENTS: "eventos",
        WEBSITE_PEOPLE_DATA: "dados",
        WEBSITE_PEOPLE_SUBSCRIPTIONS: "inscrições de e-mail",
      },
    },
  },
  CALL: {
    STATE_CALLING: "A ligar…",
    STATE_ONGOING: "A decorrer",
    LABEL_AUDIO_MUTED: "Áudio silenciado",
    LABEL_VIDEO_MUTED: "Vídeo silenciado",
  },
  CONTACTS: {
    COMMON: {
      NEW_CONTACT: "Novo Contato",
      DOCUMENTATION: "Documentation",
    },
    GENERIC: {
      POPUPS_NEW_CONTACT_EMAIL_LABEL: "E-mail do contato",
      POPUPS_NEW_CONTACT_EMAIL_PLACEHOLDER: "Digite o e-mail do contato…",
      POPUPS_NEW_CONTACT_NAME_LABEL: "Nome do Contato",
      POPUPS_NEW_CONTACT_NAME_PLACEHOLDER: "Digite o nome completo do contato…",
      POPUPS_NEW_CONTACT_PROCEED: "Adicionar contato",
      POPUPS_NEW_CONTACT_UPGRADE_DESCRIPTION:
        "Você atingiu seu limite de contatos CRM, incluído gratuitamente em seu plano.\n*você pode adicionar mais contatos, aumentando seus limites***.\n\nPor favor, siga nossas instruções para subscrever limites mais altos.",
      POPUPS_NEW_CONTACT_UPGRADE_PROCEED: "Aumentar CRM Limites",
    },
    DEFAULT: {
      CONTROLS_ACTIONS: "Ações",
      CONTROLS_ACTIONS_REMOVE_SELECTED: "Excluir os perfis selecionados",
      CONTROLS_ACTIONS_REMOVE_FILTERED: "Excluir os perfis encontrados",
      CONTROLS_ACTIONS_EXPORT: "Exportar perfis dos contatos",
      CONTROLS_ACTIONS_IMPORT: "Importar perfis dos contatos",
      HEAD_FIRST_NAME: "Nome completo",
      HEAD_LOCATION: "Localização",
      HEAD_COMPANY: "Companhia",
      HEAD_SEGMENTS: "Segmentos",
      HEAD_LAST_ACTIVE: "Ativo pela última vez",
      HEAD_SCORE: "Pontuação",
      ROW_UNKNOWN: "Desconhecido",
      ROW_NO_SEGMENTS: "Sem segmentos",
      ROW_ACTIVE_ONLINE: "Ativo agora",
      ROW_ACTIVE_SINCE: "Desde",
      EMPTY_NO_CONTACT_DEFAULT_TITLE: "Você não tem nenhum contato.",
      EMPTY_NO_CONTACT_DEFAULT_LABEL:
        "Os contatos são armazenados a partir de conversas, ou podem ser criados e até importados.",
      EMPTY_NO_CONTACT_SEARCH_TITLE: "A busca não encontrou nenhum contato.",
      EMPTY_NO_CONTACT_SEARCH_LABEL:
        "Você pode encontrar contatos por nome, e-mail, país, empresa etc.",
      BANNER_LIMIT_ALMOST_REACHED_TITLE:
        "Você quase atingiu o limite de contatos CRM para seu plano. Por favor, considere aumentá-lo para continuar adicionando novos contatos.",
      BANNER_LIMIT_ALMOST_REACHED_ACTION: "Aumentar os limites",
      BANNER_LIMIT_REACHED_TITLE:
        "Você atingiu os limites de contatos CRM para seu plano. Nenhum novo contato será criado até que você os aumente.",
      BANNER_LIMIT_REACHED_ACTION: "Aumentar os limites",
      BANNER_IMPORT_TITLE:
        "Já tem contatos em outro CRM? Importe-os para o Reinvent",
      BANNER_IMPORT_ACTION: "Importar contatos",
      POPUPS_EXPORT_PROFILES_TITLE:
        "Você vai exportar o seu banco de dados de contatos",
      POPUPS_EXPORT_PROFILES_NOTICE:
        "Isto levará alguns minutos. Você receberá um e-mail quando a exportação terminar.",
      POPUPS_EXPORT_PROFILES_PROCEED: "Exportar Contatos",
      POPUPS_REMOVE_CONTACTS_TITLE: "Os contatos selecionados serão removidos",
      POPUPS_REMOVE_CONTACTS_NOTICE:
        "Os dados desses contatos serão apagados permanentemente.",
      POPUPS_REMOVE_CONTACTS_PROCEED: "Remover Contatos Selecionados",
      POPUPS_DESTROY_CONTACTS_TITLE:
        "Todos os contatos buscados serão removidos",
      POPUPS_DESTROY_CONTACTS_NOTICE:
        "Os dados para esses contatos serão apagados permanentemente. Certifique-se que o seu filtro de pesquisa está correto.",
      POPUPS_DESTROY_CONTACTS_PROCEED: "Remover Todos os Contatos Buscados",
      TITLE: "Contatos",
      HEAD_EMAIL: "E-mail",
      ROW_MORE_SEGMENTS: "Ver todos os segmentos",
      ROW_PREVIEW: "Pré-visualização",
      POPUPS_UPDATE_FILTER_TITLE: "Quer atualizar este filtro?",
      POPUPS_UPDATE_FILTER_LABEL:
        "Este filtro vai ser atualizado para todos na sua equipa.",
      POPUPS_UPDATE_FILTER_PROCEED: "Atualizar filtro",
    },
    PROFILE: {
      ACTIVE_NOW: "Ativo agora",
      ACTIVE_LAST: "Última vez ativo:",
      ALERT_EMAIL_NOT_SUBSCRIBED_LABEL:
        "desinscrito de todos os e-mails enviados pelo Reinvent (usando o link dos e-mails).",
      ALERT_EMAIL_NOT_SUBSCRIBED_ACTION: "Reinscrever este contato agora?",
      BOXES: {
        CONTACT: {
          NAME: "Informações de contato de",
          ENTER_VALUE: "Introduza",
          FIELD_NAME: "Nome",
          FIELD_EMAIL: "E-mail",
          FIELD_PHONE: "Telefone",
          FIELD_ADDRESS: "Morada",
          FIELD_WEBSITE: "Site",
          FIELD_GENDER: "Género",
          FIELD_GENDER_PLACEHOLDER: "Selecione um género",
          FIELD_GENDER_MALE: "Masculino",
          FIELD_GENDER_FEMALE: "Feminino",
          FIELD_SUBSCRIPTION: "Notificações",
          FIELD_SUBSCRIPTION_ENABLED: "Ativar",
          FIELD_SUBSCRIPTION_DISABLED: "Desativar",
          FIELD_CREATED_AT: "Data de criação",
          FIELD_UPDATED_AT: "Última atualização",
        },
        CONVERSATIONS: {
          NAME: "Conversas com",
          EMPTY: "Nenhuma conversa encontrada.",
          ACTION: "Nova Conversa",
        },
        COMPANY: {
          NAME_1: "Companhia",
          NAME_2: "Trabalha para",
          ENTER_VALUE: "Introduza",
          FIELD_COMPANY: "Companhia",
          FIELD_JOB_TITLE: "Título do Trabalho",
          FIELD_JOB_ROLE: "Cargo",
          FIELD_WEBSITE: "Site",
          FIELD_CITY: "Cidade",
          FIELD_COUNTRY: "País",
          FIELD_EMPLOYEES: "Funcionários",
        },
        DEVICES: {
          NAME: "Dispositivos para",
          EMPTY: "Nenhum dispositivo disponível.",
          ON: "ligado",
        },
        SEGMENTS: {
          NAME: "Segmentos para",
          FORM_PLACEHOLDER: "Segmentar contato..",
        },
        LOCATION: {
          NAME: "Última Localização Reportada",
          LEGEND_CITY: "Cidade, País",
          LEGEND_TIME: "Horário Local",
          LEGEND_LANGUAGES: "ldiomas falados",
          EMPTY: "Nenhuma localização disponível.",
        },
        NOTEPAD: {
          NAME: "Bloco de Notas privado ativo",
          ACTION_CANCEL: "Cancelar alterações",
          ACTION_SAVE: "Guardar Alterações",
          TEXT_PLACEHOLDER: "Escreva as suas notas privadas…",
        },
        DATA: {
          NAME: "Dados para",
          ACTION: "Adicionar Dados",
          EMPTY: "Sem dados disponíveis",
          FORM_KEY_PLACEHOLDER: "Introduza a chave…",
          FORM_VALUE_PLACEHOLDER: "Introduza o valor…",
          HELP_LABEL:
            "Os dados do visitante podem ser definidos lá e na janela de chat.",
          HELP_LEARN: "Aprender como",
        },
        RATINGS: {
          NAME: "Pontuação da classificação de",
          LEGEND_SCORE: "Pontuação",
          LEGEND_COMMENT: "Comentário",
          VIEW_CONVERSATION: "Ver conversa",
          EMPTY: "Nenhuma classificação submetida",
          COMMENT_EMPTY: "Nenhum comentário submetido.",
        },
        PAGES: {
          NAME_1: "Páginas",
          NAME_2: "Visitado",
          PAGES_VISITED: "Páginas visitadas",
          TIME_ON_WEBSITE: "Tempo no site",
          EMPTY: "Nenhuma página foi pesquisada.",
          MORE: "Mostrar todas as páginas",
        },
        EVENTS: {
          NAME: "Eventos para",
          EMPTY: "Nenhum evento adicionado",
          DATA_OPEN: "ver data",
          DATA_CLOSE: "ocultar data",
          HELP_LABEL:
            "Os eventos dos visitantes são enviados da janela de chat.",
          HELP_LEARN: "Aprender como",
        },
        CAMPAIGNS: {
          NAME: "Campanhas para",
          EMPTY: "Não foram encontradas campanhas.",
          STATE_OPENED: "aberto",
          STATE_CLICKED: "clicado",
          STATE_UNSUBSCRIBED: "inscrição cancelada",
        },
      },
      GO_TO_FULL: "Abrir perfil completo",
      MESSAGE: "Enviar uma mensagem",
      STATUS: {
        ONLINE: "Ativo",
        AWAY: "Inativo",
        OFFLINE: "Desligado",
        BLOCKED: "Bloqueado",
      },
    },
  },
  CAMPAIGNS: {
    COMMON: {
      NEW_CAMPAIGN: "Nova campanha",
      NEW_TEMPLATE: "Novo modelo",
      SAVE_CAMPAIGN_DRAFT: "Guardar Rascunho",
      SAVE_CAMPAIGN: "Guardar",
      TEST_CAMPAIGN: "Receber Teste",
      CONFIGURE_CAMPAIGN: "Configurar",
      SEND_GO_CAMPAIGN: "Terminar",
      SEND_RESUME_CAMPAIGN: "Retomar",
      SEND_ACTIVATE_CAMPAIGN: "Ativar",
      SEND_PAUSE_CAMPAIGN: "Pausar",
      SHOW_ANALYTICS: "Mostrar Análises",
      BREADCRUMB_RECIPIENTS: "Destinatários",
      BREADCRUMB_CONDITIONS: "Condições",
      BREADCRUMB_TEMPLATES: "Modelos",
      BREADCRUMB_EDITOR: "Compositor",
      BREADCRUMB_ANALYTICS: "Análises",
      BACK: "Voltar",
      NEXT: "Próximo",
      DOCUMENTATION: "Documentação",
    },
    DEFAULT: {
      CONTROLS_NAME: "Campanhas",
      CONTROLS_ACTIONS: "Ações",
      CONTROLS_ACTIONS_CAMPAIGNS_REMOVE: "Remover campanhas selecionadas",
      CONTROLS_ACTIONS_TEMPLATES_REMOVE: "Remover modelos selecionados",
      CONTROLS_ACTIONS_CAMPAIGNS_DUPLICATE: "Duplicar campanhas selecionadas",
      CONTROLS_ACTIONS_TEMPLATES_DUPLICATE: "Duplicar modelos selecionados",
      FILTERS_TYPES_TITLE: "Tipos",
      FILTERS_TYPES_ALL: "Todos os tipos",
      FILTERS_TYPES_ONE_SHOT: "Única",
      FILTERS_TYPES_AUTOMATED: "Automatizada",
      FILTERS_TAGS_TITLE: "Etiquetas",
      FILTERS_TAGS_ALL: "Todas as Tags",
      FILTERS_TEMPLATES_TITLE: "Modelos",
      FILTERS_TEMPLATES_ALL: "Todos os Modelos",
      FILTERS_TEMPLATES_STATIC: "Reinvent Modelos",
      FILTERS_TEMPLATES_CUSTOM: "Os seus modelos",
      HEAD_CAMPAIGN_NAME: "Nome da Campanha",
      HEAD_STATUS: "Estado",
      HEAD_CONTACTS: "Contatos",
      HEAD_LAST_UPDATE: "Última Atualização",
      ROW_SECTION_AUTOMATED: "Campanhas Automatizadas",
      ROW_SECTION_ONE_SHOT: "Campanhas Únicas",
      ROW_NAME_EDIT: "Editar nome",
      ROW_NAME_TAG: "Etiqueta de edição",
      ROW_STATUS_NOT_CONFIGURED: "Não configurado",
      ROW_STATUS_READY_SEND: "Pronta para enviar",
      ROW_STATUS_READY_ACTIVATE: "Pronta para ser ativada",
      ROW_STATUS_SENDING: "A enviar…",
      ROW_STATUS_ACTIVE: "Ativa",
      ROW_STATUS_PREPARING: "A preparar…",
      ROW_STATUS_INACTIVE: "Inativo",
      ROW_STATUS_PAUSED: "Em Pausa",
      ROW_STATUS_DONE: "Envio concluído",
      ROW_RECIPIENTS_LABEL: "destinatários",
      ROW_REACHED_LABEL: "alcançada",
      EMPTY_NO_CAMPAIGN_DEFAULT_TITLE: "Não tem nenhuma campanha",
      EMPTY_NO_CAMPAIGN_DEFAULT_LABEL:
        "Campanhas servem para enviar e-mails para contatos específicos. Crie a sua primeira campanha!",
      EMPTY_NO_CAMPAIGN_SEARCH_TITLE:
        "Nenhuma campanha foi encontrada para a sua pesquisa.",
      EMPTY_NO_CAMPAIGN_SEARCH_LABEL: "Você pode encontrar campanhas por nome.",
      EMPTY_NO_TEMPLATE_DEFAULT_TITLE:
        "Não foi encontrado nenhum modelo para a sua pesquisa.",
      EMPTY_NO_TEMPLATE_DEFAULT_LABEL:
        "Você pode encontrar os modelos por nome.",
      EMPTY_NO_TEMPLATE_SEARCH_TITLE:
        "Não foi encontrado nenhum modelo para a sua pesquisa.",
      EMPTY_NO_TEMPLATE_SEARCH_LABEL:
        "Você pode encontrar os modelos por nome.",
      POPUPS_REMOVE_CAMPAIGNS_TITLE:
        "As campanhas selecionadas serão removidas",
      POPUPS_REMOVE_CAMPAIGNS_NOTICE:
        "Essas campanhas serão apagadas permanentemente.",
      POPUPS_REMOVE_CAMPAIGNS_PROCEED: "Remover Campanhas Selecionadas",
      POPUPS_REMOVE_TEMPLATES_TITLE: "Os modelos seleccionados serão removidos",
      POPUPS_REMOVE_TEMPLATES_NOTICE:
        "Esses modelos serão permanentemente apagados.",
      POPUPS_REMOVE_TEMPLATES_PROCEED: "Remover Modelos Selecionados",
      POPUPS_DUPLICATE_CAMPAIGNS_TITLE:
        "As campanhas selecionadas serão duplicadas",
      POPUPS_DUPLICATE_CAMPAIGNS_NOTICE:
        "Será criada uma cópia de cada campanha seleccionada.",
      POPUPS_DUPLICATE_CAMPAIGNS_TOO_MANY:
        "Foram seleccionadas demasiadas campanhas. Você pode duplicar um máximo de campanhas de {maximum} de cada vez.",
      POPUPS_DUPLICATE_CAMPAIGNS_PROCEED: "Duplicar as Campanhas Selecionadas",
      POPUPS_DUPLICATE_TEMPLATES_TITLE:
        "Os modelos seleccionados serão duplicados",
      POPUPS_DUPLICATE_TEMPLATES_NOTICE:
        "Será criada uma cópia de cada campanha seleccionada. Se você selecionou muitos modelos, isso pode levar algum tempo.",
      POPUPS_DUPLICATE_TEMPLATES_TOO_MANY:
        "Demasiados modelos foram seleccionados. Você pode duplicar um máximo de {maximum} templates de cada vez.",
      POPUPS_DUPLICATE_TEMPLATES_PROCEED: "Duplicar os Modelos Selecionados",
      POPUPS_CUSTOM_DOMAIN_TITLE: "Inicializar Campanhas",
      POPUPS_CUSTOM_DOMAIN_NOTICE:
        "Para enviar campanhas, você precisa configurar um domínio de e-mail personalizado, como emails.empresa.com. O uso de um subdomínio personalizado vai melhorar a entrega dos seus e-mails.",
      POPUPS_CUSTOM_DOMAIN_NOTICE_1:
        "Configurar um domínio de e-mail personalizado é um pouco técnico, mas nós fizemos alguma documentação para ajudá-lo nisso. Recomendamos verificar a nossa documentação antes de ir para o próximo passo.",
      POPUPS_CUSTOM_DOMAIN_NOTICE_2: "leia o nosso artigo de ajuda",
      POPUPS_CUSTOM_DOMAIN_PROCEED: "Configurar Domínio do E-mail",
      POPUPS_VERIFY_WEBSITE_TITLE: "Verifique sua conta",
      POPUPS_VERIFY_WEBSITE_NOTICE:
        "Para poder enviar campanhas por e-mail, precisamos verificar os documentos de sua empresa. O processo é feito para proteger nossos usuários contra spam e preservar sua reputação de e-mail.",
      POPUPS_VERIFY_WEBSITE_NOTICE_1:
        "Não se preocupe! Este processo é rápido. Entre em contato com nosso suporte ao cliente usando o seguinte botão. Nossa equipe precisará dos documentos de sua empresa e fará uma pergunta.",
      POPUPS_VERIFY_WEBSITE_PROCEED: "Verificar minha conta",
      POPUPS_REPLACE_TEMPLATE_TITLE: "A campanha já tem uma mensagem",
      POPUPS_REPLACE_TEMPLATE_NOTICE:
        "A seleção de um novo modelo substituirá permanentemente a mensagem da campanha existente.",
      POPUPS_REPLACE_TEMPLATE_PROCEED: "Substituir modelo e mensagem",
      HEAD_ACTIONS: "Ações",
    },
    EDIT: {
      PICKER_ALL: "Enviar para todos os contatos",
      PICKER_PEOPLE: "Enviar para contatos selecionados",
      PICKER_SEGMENT: "Enviar para um segmento de contatos",
      PICKER_FILTER: "Enviar para um filtro de contatos…",
      SEARCH_PLACEHOLDER_PEOPLE: "Buscar em todos os contatos…",
      STATISTICS_BACK: "Voltar",
      STATISTICS_UNKNOWN: "Desconhecido",
      STATISTICS_VIEW_PROFILE: "Ver Perfil",
      STATISTICS_EMPTY_TITLE: "Não existem estatísticas (por agora).",
      STATISTICS_EMPTY_LABEL:
        "Usuários que interagiram com alguma campanha serão exibidos aqui (somente para e-mails de campanhas).",
      STATISTICS_HEAD_FIRST_NAME: "Nome Completo",
      STATISTICS_HEAD_LOCATION: "Localização",
      STATISTICS_HEAD_DATE: "Data",
      EDITOR_SUBJECT_PLACEHOLDER: "Entre um assunto para a campanha…",
      EDITOR_SUBJECT_HELP:
        "Assunto da campanha (visível para os beneficiários)",
      EDITOR_TAG_NAME_FULL_LABEL: "Nome completo do perfil",
      EDITOR_TAG_NAME_FIRST_LABEL: "Primeiro nome do perfil",
      EDITOR_TAG_NAME_LAST_LABEL: "Sobrenome do perfil",
      EDITOR_TAG_EMAIL_LABEL: "Email do perfil",
      EDITOR_TAG_COUNTRY_LABEL: "País do perfil",
      EDITOR_TAG_CITY_LABEL: "Cidade do perfil",
      EDITOR_TAG_WEBSITE_LABEL: "URL do site do perfil",
      EDITOR_TAG_COMPANY_NAME_LABEL: "Nome da empresa do perfil",
      EDITOR_TAG_DATA_LABEL: "Dados de perfil (na subchave para configurar)",
      EDITOR_TARGETS_NONE: "Esta mensagem não tem ninguém como alvo",
      EDITOR_TARGETS_ALL:
        "Esta mensagem será enviada para todos os seus contatos",
      EDITOR_TARGETS_CONTACTS_1: "Esta mensagem tem como alvo",
      EDITOR_TARGETS_CONTACTS_2: "contatos",
      EDITOR_OPTION_DELIVER_TO: "Enviar",
      EDITOR_OPTION_DELIVER_TO_CHATBOX: "Chat",
      EDITOR_OPTION_DELIVER_TO_EMAIL: "E-mail",
      EDITOR_OPTION_MANAGE_TEMPLATES: "Modelos",
      EDITOR_OPTION_EDIT_OPTIONS: "Configurações",
      EDITOR_SEND_AS: "Enviar como:",
      EDITOR_TARGETS: "Destinatários:",
      EDITOR_TARGETS_TOO_COMPLEX:
        "A contagem de destinatários é demasiado complexa para ser obtida em tempo útil.",
      EDITOR_HELP_MAIN_MARKDOWN:
        "A mensagem da campanha é formatada com o Markdown. O Markdown é fácil de usar.",
      EDITOR_HELP_MAIN_HTML:
        "A mensagem da campanha é formatada com HTML. Pode usar o editor de código para uma edição avançada.",
      EDITOR_HELP_DOCS: "encontrar documentos aqui",
      POPUPS_SEND_CAMPAIGN_QUESTION: "Enviar agora esta campanha?",
      POPUPS_SEND_CAMPAIGN_LABEL:
        "* {recipients} os destinatários** receberão a mensagem da campanha na sua caixa de entrada.",
      POPUPS_SEND_CAMPAIGN_LABEL_CHATBOX: "conversa",
      POPUPS_SEND_CAMPAIGN_LABEL_EMAIL: "caixa de entrada",
      POPUPS_SEND_CAMPAIGN_TOO_MANY_RECIPIENTS_1:
        "A sua campanha é actualmente rejeitada porque a quantidade de beneficiários está acima dos limites do seu plano:",
      POPUPS_SEND_CAMPAIGN_TOO_MANY_RECIPIENTS_2: "leia nosso artigo de ajuda",
      POPUPS_SEND_CAMPAIGN_PROCEED: "Enviar Campanha",
      POPUPS_SEND_CAMPAIGN_PROCEEDING: "Campanha de Envio",
      POPUPS_SEND_CAMPAIGN_PREPARING: "Envio em {seconds}s",
      POPUPS_ACTIVATE_CAMPAIGN_QUESTION: "Ativar agora esta campanha?",
      POPUPS_ACTIVATE_CAMPAIGN_LABEL:
        "Esta campanha começará agora a observar os eventos dos usuários.",
      POPUPS_ACTIVATE_CAMPAIGN_PROCEED: "Campanha ativa",
      POPUPS_TOGGLE_FORMAT_QUESTION:
        "Alternar o formato do código da campanha?",
      POPUPS_TOGGLE_FORMAT_LABEL:
        "O formato será alternado entre o Markdown e HTML. Algum código existente pode já não funcionar, embora você não vá perder o código.",
      POPUPS_TOGGLE_FORMAT_PROCEED: "Código Alternado",
      POPUPS_MANAGE_TEMPLATES_QUESTION: "Gerenciar seus modelos de campanha",
      POPUPS_MANAGE_TEMPLATES_LABEL:
        "Pode guardar o código atual como modelo para reutilizar mais tarde, ou inserir um modelo guardado aqui.",
      POPUPS_MANAGE_TEMPLATES_SAVE_TITLE: "Guardar código como modelo",
      POPUPS_MANAGE_TEMPLATES_SAVE_PLACEHOLDER: "Dê um nome a este modelo…",
      POPUPS_MANAGE_TEMPLATES_SAVE_CREATE: "Guardar",
      POPUPS_MANAGE_TEMPLATES_LIST_TITLE: "Modelos salvos",
      POPUPS_MANAGE_TEMPLATES_EMPTY:
        "Sem modelos guardados. Eles ainda podem estar a carregar.",
      POPUPS_MANAGE_TEMPLATES_PROCEED: "Terminado",
      POPUPS_EDIT_OPTIONS_TRACKING: "Use o rastreamento",
      POPUPS_EDIT_OPTIONS_SENDER_NAME_WEBSITE:
        "Mostrar o nome do site no remetente",
      POPUPS_EDIT_OPTIONS_SENDER_EMAIL_REPLY_LABEL:
        "Responder a e-mail (se houver)",
      POPUPS_EDIT_OPTIONS_SENDER_EMAIL_REPLY_PLACEHOLDER:
        "Digite o e-mail de reposição (pode estar em branco)",
      POPUPS_EDIT_OPTIONS_PROCEED: "Opções de Edição",
      POPUPS_PREVIEW_RECIPIENTS_QUESTION: "Prever os destinatários da campanha",
      POPUPS_PREVIEW_RECIPIENTS_LABEL:
        "Esses são os destinatários para os quais a campanha será enviada. Alguns podem ter cancelado a inscrição dos e-mails e podem não a receber.",
      POPUPS_PREVIEW_RECIPIENTS_ERROR:
        "Os destinatários não puderam ser pré-visualizados. Muito provavelmente o filtro da sua campanha levou muito tempo para ser calculado. Tente novamente mais tarde.",
      POPUPS_PREVIEW_RECIPIENTS_EMPTY:
        "Nenhum destinatário foi encontrado no filtro desta campanha.",
      POPUPS_PREVIEW_RECIPIENTS_MORE: "Mostrar mais destinatários",
      POPUPS_PREVIEW_RECIPIENTS_SUBSCRIBED: "Inscrito: receberá campanha",
      POPUPS_PREVIEW_RECIPIENTS_UNSUBSCRIBED:
        "Não inscrito: não receberá campanha",
      EDITOR_ERROR_LABEL:
        "Esta mensagem é demasiado longa. Você conseguirá enviá-la mas estará incompleta.",
      EDITOR_ERROR_MARKED: "max. caracteres",
      EDITOR_NOTICE_ONE_SHOT_NOT_READY:
        "Esta campanha ainda não está pronta para ser enviada. Adicione uma mensagem, escolha um remetente e adicione destinatários.",
      EDITOR_NOTICE_ONE_SHOT_DISPATCHED:
        "Esta campanha foi enviada. Já não pode editá-la.",
      EDITOR_NOTICE_AUTOMATED_NOT_READY:
        "Esta campanha ainda não está pronta para ser ativada. Adicione uma mensagem e configure o fluxo de entrega.",
      EDITOR_NOTICE_AUTOMATED_DEACTIVATED:
        "Esta campanha está desativada . Não vai voltar a ser acionada até a ativar.",
      TITLE: "Construa e envie a sua campanha",
    },
    RECIPIENTS: {
      TITLE: "Selecione os destinatários da sua campanha",
    },
    FLOW: {
      TITLE:
        "Configure como é que as suas campanhas automatizadas são acionadas",
      FORM_NOTICE_DOCS: "Ler documentos",
      PART_1_TITLE: "Iniciar no Evento do Usuário",
      PART_1_FORM_FIELD_EVENT_LABEL: "Evento",
      PART_1_FORM_FIELD_EVENT_PLACEHOLDER: "Nome do evento…",
      PART_1_FORM_NOTICE_1: "Entenda o que são os eventos dos usuários",
      PART_1_FORM_NOTICE_2: ", e como utilizá-los aqui.",
      PART_2_TITLE: "Definir Condições do Filtro",
      PART_2_FORM_FIELD_DELAY_LABEL: "Filtrar após minutos",
      PART_2_FORM_NOTICE_1: "As condições de filtro são usadas para afinar",
      PART_2_FORM_NOTICE_2: "se deve enviar a campanha",
      PART_2_FORM_NOTICE_3: "baseado nos filtros de dados do usuário.",
      PART_3_TITLE: "Configurações de Entrega",
      PART_3_FORM_FIELD_ONCE_LABEL: "Enviar uma vez por contato",
      PART_3_FORM_FIELD_DELAY_LABEL: "Enviar após minutos",
      PART_4_TITLE: "A Mensagem é Enviada Para o Contato",
    },
    TEMPLATES: {
      TITLE: "Escolha um modelo HTML",
      BOX_TITLE: "Os seus modelos disponíveis",
      BOX_DESCRIPTION:
        "Fizemos uma lista de todos o modelos HTML que guardou para mais tarde usar. Para criar um novo modelo HTML ou usar o Markdown, pode saltar este passo.",
      TEMPLATE_ACTION: "Use este modelo",
      BASIS_UNSUBSCRIBE: "Cancelar inscrição",
      PREVIEW_OPTIONS_TO_LABEL: "Para:",
      PREVIEW_OPTIONS_TO_VALUE: "john.doe{\\'@\\'}gmail.com",
      PREVIEW_OPTIONS_REPLY_TO_LABEL: "Responder para:",
      USE_MARKDOWN: "Ignore este passo",
    },
    TEMPLATE: {
      NAME_PLACEHOLDER: "Digite um nome para o modelo…",
      EDITOR_NOTICE_DEFAULT:
        "Este é um modelo padrão fornecido por Reinvent. Você não pode editá-lo.",
      EDITOR_HELP_MAIN_MARKDOWN:
        "Este modelo é formatado com Markdown. O Markdown é fácil de usar.",
      EDITOR_HELP_MAIN_HTML:
        "Este modelo é formatado em HTML. Você pode usar o editor de código para edição avançada.",
      ACTION_SAVE: "Salvar modelo",
    },
    ANALYTICS: {
      TITLE: "Monitorize as estatísticas da sua campanha",
      OPENED: "Aberto",
      CLICKED: "Clicado",
      UNSUBSCRIBED: "Não inscrito",
      ROW_PROFILE_CONTACT: "Perfil",
      ROW_PROFILE_ACTIONS_LINK_URL: "Abrir link clicado: {link}",
      TIPS_TITLE: "Dicas:",
      TIPS_IP_REPUTATION_WEAK:
        "A reputação de seu IP está ruim, muitas pessoas estão se desinscrevendo?",
      TIPS_ASSIGNED_IP_UPGRADE:
        "Usar um IP dedicado para melhorar as taxas de abertura",
      TIPS_LINK_NOT_CLICKED:
        "Poucas pessoas que abriram a campanha clicaram num link",
      TIPS_EMAIL_JUST_SENT:
        "Você acabou de enviar esta campanha, as estatísticas ainda podem estar vazias",
      TIPS_EMAIL_STILL_SENDING:
        "Esta campanha ainda está sendo enviada, as estatísticas podem ainda estar vazias",
    },
  },
  HELPDESK: {
    COMMON: {
      NEW_ARTICLE: "Novo artigo",
      NEW_CATEGORY: "Nova Categoria",
      DOCUMENTATION: "Documentação",
    },
    DEFAULT: {
      CONTROLS_NAME: "Central de Ajuda",
      CONTROLS_ACTIONS_ARTICLE_REMOVE: "Remover artigos selecionados",
      CONTROLS_ACTIONS_CATEGORY_SECTION_REMOVE:
        "Remover as seções selecionadas",
      CONTROLS_ACTIONS_ADD_LOCALE: "Adicionar novo idioma",
      CONTROLS_ACTIONS_EXPORT_ARTICLES: "Exportar artigos",
      CONTROLS_ACTIONS_IMPORT_ARTICLES: "Importar artigos",
      CONTROLS_ACTIONS_DESTROY_LOCALE: "Destruir idioma atual",
      CONTROLS_ACTIONS_VIEW_HELPDESK: "Ver minha Central de Ajuda",
      CONTROLS_ACTIONS: "Ações",
      FILTERS_CATEGORY_ARTICLES_TITLE: "Artigos",
      FILTERS_CATEGORY_CATEGORIES_TITLE: "Categorias",
      FILTERS_STATUS_TITLE: "Estado",
      FILTERS_STATUS_PUBLISHED: "Publicado",
      FILTERS_STATUS_DRAFT: "Rascunho",
      FILTERS_VISIBILITY_VISIBLE: "Visível",
      FILTERS_VISIBILITY_HIDDEN: "Oculto",
      HEAD_ARTICLES_TITLE: "Título do Artigo",
      HEAD_ARTICLES_STATUS: "Estado",
      HEAD_ARTICLES_STATISTICS: "Estatísticas",
      HEAD_CATEGORIES_NAME: "Nome da Categoria",
      HEAD_CATEGORIES_STATISTICS: "Estatísticas",
      HEAD_LAST_UPDATE: "Última Atualização",
      ROW_SECTION_ARTICLES_NO_CATEGORY: "Nenhuma Categoria",
      ROW_SECTION_ARTICLES_ARTICLES: "artigos",
      ROW_SECTION_CATEGORIES_NO_DESCRIPTION: "Sem descrição.",
      ROW_SECTION_CATEGORIES_ADD: "Adicionar seção",
      ROW_ARTICLES_STATUS_ONLINE: "Conectado",
      ROW_ARTICLES_STATUS_HIDDEN: "Oculto",
      ROW_ARTICLES_STATUS_DRAFT: "Rascunho",
      ROW_ARTICLES_VISITS_LABEL: "visitas",
      ROW_ARTICLES_EDIT: "Editar Artigo",
      ROW_CATEGORIES_SECTION_LABEL: "Seção",
      ROW_CATEGORIES_ARTICLES_LABEL: "artigos",
      ROW_CATEGORIES_EDIT: "Seção de Edição",
      ROW_DATE_CREATED: "Criado:",
      ROW_VIEW: "Ver",
      EMPTY_NO_ARTICLE_DEFAULT_TITLE: "Não existem artigos.",
      EMPTY_NO_ARTICLE_DEFAULT_LABEL:
        "Crie o seu primeiro artigo. Escreva um rascunho, pré-visualize, e publique.",
      EMPTY_NO_ARTICLE_SEARCH_TITLE:
        "Não foi encontrado nenhum artigo para a sua pesquisa.",
      EMPTY_NO_ARTICLE_SEARCH_LABEL: "Você pode encontrar artigos pelo nome.",
      EMPTY_NO_CATEGORY_DEFAULT_TITLE: "Não existem categorias.",
      EMPTY_NO_CATEGORY_DEFAULT_LABEL:
        "Crie a sua primeira categoria. Dê-lhe uma cor e uma imagem, e teste-a ao vivo!",
      EMPTY_NO_CATEGORY_SEARCH_TITLE:
        "Nenhuma categoria foi encontrada para a sua pesquisa.",
      EMPTY_NO_CATEGORY_SEARCH_LABEL:
        "Você pode encontrar categorias por nome.",
      POPUPS_INITIALIZE_TITLE: "Inicializar o seu centro de ajuda",
      POPUPS_INITIALIZE_STEP_INTRO: "Introdução",
      POPUPS_INITIALIZE_STEP_CONFIGURE: "Configurar",
      POPUPS_INITIALIZE_STEP_LOCALE: "Localizar",
      POPUPS_INITIALIZE_STEP_IMPORT: "Importar",
      POPUPS_INITIALIZE_SLIDESHOW_STEP_EDUCATE_TITLE:
        "Treine seus Clientes com Reinvent",
      POPUPS_INITIALIZE_SLIDESHOW_STEP_EDUCATE_DESCRIPTION:
        "Crie sua própria Central de Ajuda com Reinvent e minimize a necessidade de suporte ao cliente",
      POPUPS_INITIALIZE_SLIDESHOW_STEP_BROWSE_TITLE:
        "Selecione as respostas mais frequentes direto na sua Caixa de Entrada do Reinvent e responda tudo num instante",
      POPUPS_INITIALIZE_CONFIGURE_TITLE:
        "Vamos configurar a sua Base de Conhecimento",
      POPUPS_INITIALIZE_CONFIGURE_DESCRIPTION:
        "Você está a poucos passos de usar a sua Base de Conhecimento.",
      POPUPS_INITIALIZE_LOCALE_TITLE: "Configure o idioma da Central de Ajuda",
      POPUPS_INITIALIZE_LOCALE_DESCRIPTION:
        "A Base de Conhecimento do Reinvent pode ter vários idiomas. Indique o idioma padrão da Central de Ajuda.",
      POPUPS_INITIALIZE_DONE_TITLE: "Você está quase terminando!",
      POPUPS_INITIALIZE_DONE_DESCRIPTION:
        "Você usa outro serviço de Central de Ajuda? Sem problema! Você pode importar todos os artigos só com um clique.",
      POPUPS_INITIALIZE_ACTION_CONFIGURE:
        "Configurar minha Base de Conhecimentos",
      POPUPS_INITIALIZE_ACTION_CREATE: "Criar minha Base de Conhecimentos",
      POPUPS_INITIALIZE_ACTION_IMPORT: "Importar do serviço anterior",
      POPUPS_INITIALIZE_ACTION_CREATE_ARTICLE: "Criar meu primeiro artigo",
      POPUPS_INITIALIZE_OR: "Ou",
      POPUPS_INITIALIZE_NOTICE:
        "O seu centro de ajuda precisa de ser inicializado antes de poder ser usado.",
      POPUPS_INITIALIZE_PROCEED: "Ir para Configurações da Central de Ajuda",
      POPUPS_ADD_LOCALE_TITLE: "Adicionar idioma à Central de Ajuda",
      POPUPS_ADD_LOCALE_NOTICE:
        "A Central de Ajuda pode ter vários idiomas, mas é necessário pelo menos um.",
      POPUPS_ADD_LOCALE_LOCALE_LABEL: "Selecione um idioma",
      POPUPS_ADD_LOCALE_LOCALE_ENTICE:
        "(Você poderá configurar outros idiomas depois)",
      POPUPS_ADD_LOCALE_PROCEED: "Adicionar Idioma",
      POPUPS_DESTROY_LOCALE_TITLE: "Destruir o idioma atual?",
      POPUPS_DESTROY_LOCALE_NOTICE:
        "Leia com atenção antes de confirmar a destruição:",
      POPUPS_DESTROY_LOCALE_LOCALE_LABEL: "Confirmar remoção do idioma",
      POPUPS_DESTROY_LOCALE_LOCALE_PLACEHOLDER: "Digite o nome do idioma:",
      POPUPS_DESTROY_LOCALE_EMPHASIS_1:
        "Todos os dados da Central de Ajuda (artigos, categorias) para este idioma serão perdidos e não poderão ser recuperados.",
      POPUPS_DESTROY_LOCALE_EMPHASIS_2:
        "Os dados que você destruir não poderão ser recuperados.",
      POPUPS_DESTROY_LOCALE_CONFIRM:
        "Tem certeza que você quer destruir o idioma atual?",
      POPUPS_DESTROY_LOCALE_PROCEED: "Destruir Idioma",
      POPUPS_EXPORT_HELPDESK_TITLE:
        "Você vai exportar seus artigos da Central de Ajuda",
      POPUPS_EXPORT_HELPDESK_NOTICE:
        "Isso levará alguns minutos. Você receberá um email quando a exportação terminar.",
      POPUPS_EXPORT_HELPDESK_PROCEED: "Artigos de exportação",
      POPUPS_NEW_ARTICLE_TITLE: "Adicionar um artigo ao seu centro de ajuda",
      POPUPS_NEW_ARTICLE_NOTICE: "Este artigo será adicionado ao idioma:",
      POPUPS_NEW_ARTICLE_TITLE_LABEL: "Título do artigo",
      POPUPS_NEW_ARTICLE_TITLE_PLACEHOLDER: "Digite um título para seu artigo…",
      POPUPS_NEW_ARTICLE_PROCEED: "Criar Artigo",
      POPUPS_NEW_CATEGORY_TITLE: "Adicionar categoria à Central de Ajuda",
      POPUPS_NEW_CATEGORY_NOTICE:
        "Esta categoria vai ser adicionada ao idioma:",
      POPUPS_NEW_CATEGORY_NAME_LABEL: "Nome da categoria",
      POPUPS_NEW_CATEGORY_NAME_PLACEHOLDER:
        "Digite um nome para sua categoria…",
      POPUPS_NEW_CATEGORY_PROCEED: "Criar categoria",
      POPUPS_NEW_SECTION_TITLE: "Acrescentar uma seção à categoria",
      POPUPS_NEW_SECTION_NOTICE:
        "A seção será acrescentada à categoria:{category}",
      POPUPS_NEW_SECTION_NAME_LABEL: "Nome da seção",
      POPUPS_NEW_SECTION_NAME_PLACEHOLDER: "Digite um nome para sua seção..",
      POPUPS_NEW_SECTION_PROCEED: "Criar Seção",
      POPUPS_REMOVE_ARTICLES_TITLE: "Remover artigos selecionados?",
      POPUPS_REMOVE_ARTICLES_NOTICE:
        "Por favor, leia o seguinte e depois confirme para destruir: **{selected} artigos**",
      POPUPS_REMOVE_ARTICLES_EMPHASIS_1:
        "Todos os artigos selecionados serão perdidos e não poderão ser recuperados.",
      POPUPS_REMOVE_ARTICLES_PROCEED: "Remover Artigos Selecionados",
      POPUPS_REMOVE_CATEGORIES_TITLE: "Remover categorias selecionadas?",
      POPUPS_REMOVE_CATEGORIES_NOTICE:
        "Por favor, leia o seguinte e depois confirme para destruir: **{selected} categorias***",
      POPUPS_REMOVE_CATEGORIES_EMPHASIS_1:
        "Todas as categorias selecionadas serão perdidas e não poderão ser recuperadas.",
      POPUPS_REMOVE_CATEGORIES_PROCEED: "Remover Categorias Selecionadas",
      POPUPS_REMOVE_SECTIONS_TITLE: "Remover as seções selecionadas?",
      POPUPS_REMOVE_SECTIONS_NOTICE:
        "Por favor, leia o seguinte e depois confirme para destruir: *{selected}seções***",
      POPUPS_REMOVE_SECTIONS_EMPHASIS_1:
        "Todas as seções selecionadas serão perdidas e não poderão ser recuperadas.",
      POPUPS_REMOVE_SECTIONS_PROCEED: "Remover as seções selecionadas",
      POPUPS_EDIT_CATEGORY_NAME_LABEL: "Nome da Categoria",
      POPUPS_EDIT_CATEGORY_NAME_PLACEHOLDER: "Digite um nome para a categoria…",
      POPUPS_EDIT_CATEGORY_DESCRIPTION_LABEL: "Descrição da Categoria",
      POPUPS_EDIT_CATEGORY_DESCRIPTION_PLACEHOLDER:
        "Digite uma descrição para a categoria…",
      POPUPS_EDIT_CATEGORY_COLOR_LABEL: "Cor da categoria",
      POPUPS_EDIT_CATEGORY_COLOR_PLACEHOLDER: "Código da cor (ex. #000000)",
      POPUPS_EDIT_CATEGORY_IMAGE_LABEL: "Imagem da categoria",
      POPUPS_EDIT_CATEGORY_PROCEED: "Salvar categoria",
      POPUPS_EDIT_CATEGORY_ORDER_LABEL: "Ordenação de Categorias",
      POPUPS_EDIT_CATEGORY_ORDER_PLACEHOLDER: "Sequência (ex. 1, 2 etc.)",
      POPUPS_EDIT_SECTION_NAME_LABEL: "Nome da seção",
      POPUPS_EDIT_SECTION_NAME_PLACEHOLDER: "Digite um nome de seção..",
      POPUPS_EDIT_SECTION_ORDER_LABEL: "Índice de ordem de seção",
      POPUPS_EDIT_SECTION_ORDER_PLACEHOLDER: "Índice (ex. 1, 2, etc.)",
      POPUPS_EDIT_SECTION_PROCEED: "Salvar Seção",
    },
    ARTICLE: {
      EDITOR_TITLE_PLACEHOLDER: "Insira um nome para o artigo…",
      EDITOR_NOTICE_DRAFT:
        "Este artigo é um rascunho. Não estará visível na Central de Ajuda até que seja publicado.",
      EDITOR_NOTICE_HIDDEN:
        "Este artigo está despublicado. Não estará visível na Central de Ajuda até que seja republicado.",
      EDITOR_HELP_MAIN:
        "Os artigos são formados com o Markdown. O Markdown é fácil de usar.",
      EDITOR_HELP_DOCS: "Encontre os documentos aqui",
      EDITOR_CATEGORY: "Na categoria:",
      EDITOR_CATEGORY_NONE: "Nenhuma categoria",
      EDITOR_SETTINGS: "Configurações",
      EDITOR_VIEW: "Ver",
      EDITOR_ACTION_PUBLISH: "Publicar Artigo",
      EDITOR_ACTION_UNPUBLISH: "Despublicar Artigo",
      EDITOR_ACTION_REPUBLISH: "Voltar a Publicar Artigo",
      EDITOR_ACTION_SAVE_DRAFT: "Guardar Rascunho",
      EDITOR_ACTION_SAVE: "Guardar",
      POPUPS_ALTERNATE_LOCALES_TITLE: "Vincular artigos a idiomas",
      POPUPS_ALTERNATE_LOCALES_NOTICE:
        "Vincular artigos similares em outros idiomas.",
      POPUPS_ALTERNATE_LOCALES_LOCALE_LABEL: "Idioma",
      POPUPS_ALTERNATE_LOCALES_NO_LOCALE: "Selecione um idioma",
      POPUPS_ALTERNATE_LOCALES_ARTICLE_LABEL: "Artigo",
      POPUPS_ALTERNATE_LOCALES_ARTICLE_SELECT_EMPTY:
        "(nenhum artigo vinculado)",
      POPUPS_ALTERNATE_LOCALES_CONSOLIDATE_QUESTION:
        "Este artigo está vinculado a {alternates_count} outro(s). Deseja adicioná-los também?",
      POPUPS_ALTERNATE_LOCALES_CONSOLIDATE_ANSWER_YES: "Sim",
      POPUPS_ALTERNATE_LOCALES_CONSOLIDATE_ANSWER_NO: "Não adicionar",
      POPUPS_ALTERNATE_LOCALES_PROCEED: "Salvar idiomas vinculados",
      POPUPS_EDIT_ARTICLE_CATEGORY_CATEGORIES_LABEL: "Categoria de artigo",
      POPUPS_EDIT_ARTICLE_CATEGORY_SECTIONS_LABEL: "Seção na categoria",
      POPUPS_EDIT_ARTICLE_CATEGORY_FIELD_CATEGORY_NONE: "Nenhuma categoria",
      POPUPS_EDIT_ARTICLE_CATEGORY_FIELD_SECTION_NONE:
        "Nenhuma seção (não classificada)",
      POPUPS_EDIT_ARTICLE_CATEGORY_PROCEED: "Categoria de edição",
      POPUPS_EDIT_ARTICLE_SETTINGS_FEATURED_LABEL: "Destaque este artigo",
      POPUPS_EDIT_ARTICLE_SETTINGS_ORDER_LABEL: "Índice de pedidos de artigos",
      POPUPS_EDIT_ARTICLE_SETTINGS_ORDER_PLACEHOLDER: "Índice (ex. 1, 2, etc.)",
      POPUPS_EDIT_ARTICLE_SETTINGS_DESCRIPTION_LABEL:
        "Descrição do artigo (pode ser usado para SEO)",
      POPUPS_EDIT_ARTICLE_SETTINGS_DESCRIPTION_PLACEHOLDER:
        "Texto descritivo... (pode ser deixado vazio)",
      POPUPS_EDIT_ARTICLE_SETTINGS_PROCEED: "Editar Configurações",
    },
  },
  FILTERS: {
    DATE: {
      MONTHS: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      DAYS: [
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
        "Domingo",
      ],
      POINTS: {
        NOW: "Agora",
        SECOND: "Segundo",
        MINUTE: "Minuto",
        HOUR: "Hora",
        DAY: "Dia",
        YESTERDAY: "Ontem",
      },
    },
  },
  BASES: {
    COMMANDS: {
      SELECTOR_KEYS_NAVIGATE: "para navegar",
      SELECTOR_KEYS_SELECT: "para selecionar",
      SELECTOR_KEYS_EXIT: "para sair",
    },
    HEADER: {
      CONTROLS_SEARCH: "Procurar",
    },
    DROPDOWN_ITEM: {
      SHARE_AUDIO_RECORDING_MAIN: "A gravar…",
      SHARE_AUDIO_RECORDING_SECONDS: "segundos",
    },
    UPGRADE: {
      LABEL: "Atualizar",
      GO_FROM_TOUR: "Atualizar para {plan_name}",
    },
    PRICE: {
      MONTH: "mês",
      YEAR: "ano",
    },
  },
  FORMS: {
    FIELD_SELECT: {
      FILTER: "Filtrar opções…",
      LOADING: "A carregar opções…",
    },
    FIELD_FILE: {
      ACTION: "Selecione ou arraste um arquivo",
      INVALID: "O arquivo é inválido",
      ACTION_REMOVE: "Remover este arquivo",
    },
    FIELD_TIME: {
      TO: "até",
      ADD: "Adicionar",
    },
    FIELD_DURATION: {
      MONTHS: "Meses",
      WEEKS: "Semanas",
      DAYS: "Dias",
      HOURS: "Horas",
    },
    FIELD_KEY_VALUE: {
      FIELD_PLACEHOLDER_OBJECT_KEY: "Chave de dados",
      FIELD_PLACEHOLDER_OBJECT_VALUES:
        "Valor dos dados (separados por vírgula)",
    },
    FIELD_COLOR_PICKER: {
      SEE_MORE: "Veja mais",
    },
  },
  BUTTONS: {
    CANCEL: "Cancelar",
    CLOSE: "Fechar",
  },
  RESTRICTS: {
    FILE: {
      TITLE: "Envie qualquer arquivo nesta conversa",
      LABEL: "Envie imagens, documentos, etc.",
    },
    SHORTCUTS: {
      TITLE: "Configure os atalhos das mensagens",
      LABEL: "Ganhe tempo com respostas rápidas",
    },
    NOTES: {
      TITLE: "Partilhe mensagens de notas privadas",
      LABEL: "Fixe informações para a sua equipa",
    },
    BROWSING: {
      TITLE: "Veja o seu ecrã de visitante",
      LABEL: "Com MagicBrowse",
    },
    CALL: {
      TITLE: "Ligue aos seus visitantes",
      LABEL: "Video e chamadas de áudio",
    },
    BLOCK: {
      TITLE: "Bloqueie spammers",
      LABEL: "Bloqueio baseado no IP",
    },
    PROFILE: {
      TITLE: "Ver os perfis sociais dos visitantes",
      LABEL: "Facebook, Twitter, etc.",
    },
    IP: {
      TITLE: "Ver o IP do visitante",
      LABEL: "Útil para legal e debug",
    },
    HISTORY: {
      TITLE: "Obtenha um histórico de mensagens ilimitado",
      LABEL: "Recupere esta mensagem antiga",
    },
    AUDIO: {
      TITLE: "Envie mensagens de áudio",
      LABEL: "Evite escrever mensagens longas",
    },
    HELPDESK: {
      TITLE: "Configurar sua Central de Ajuda",
      LABEL: "Publicar site da Central de Ajuda",
    },
    REMINDERS: {
      TITLE: "Lembretes agendados",
      LABEL: "Lembrar de coisas",
    },
  },
  NOTIFICATIONS: {
    MENTIONED: "Você foi mencionado",
    ASSIGNED: "Você foi designado",
    NOTE: "Foi deixada uma nota",
  },
  BANNERS: {
    OFFLINE_NOTICE:
      "Shh! Neste momento aparece como desligado para os seus visitantes.",
    TIMEZONE_NOTICE:
      "Oops! Parece que o seu fuso horário configurado é diferente do fuso horário da sua localização atual ({timezone}).",
    CHANGE_AVAILABILITY: "Configurar a minha disponibilidade",
    CHANGE_TIMEZONE: "Configurar o meu fuso horário",
    RECONNECT_NOTICE_MAIN: "Ups, o Reinvent está desconectado.",
    RECONNECT_NOTICE_WILL_PROCEED:
      "Sem preocupações, estou a tentar reconectar-me…",
    RECONNECT_NOTICE_PROCEEDING: "A reconectar-me…",
    NOTIFICATION_NOTICE:
      "As notificações estão desativadas no seu navegador. É necessário ativá-las para ser notificado de novas mensagens.",
    ENABLE_NOTIFICATION: "Ativar notificações",
    RELOAD_Talqui: "Recarregar o Reinvent",
    SAFE_MODE_NOTICE:
      "Devido a razões técnicas o seu painel de controlo está limitado às Mensagens.",
    NEW_VERSION_NOTICE:
      "Acabamos de atualizar o Reinvent com correções e funcionalidades. Recarregue-o para utilizar a sua última versão.",
    TRIAL_EXPIRE_SOON_NOTICE:
      "O seu teste grátis vai expirar {expiration_time}. Atualize agora para manter todas as funcionalidades",
    TRIAL_EXPIRE_SOON_SELECT: "Assinar agora",
    ACTION_UNPAID_SUBSCRIPTION: "Ver Faturas",
    CONTENT_UNPAID_SUBSCRIPTION:
      "Sua assinatura da Reinvent parece conter débitos - verifique agora para evitar suspensão de acesso",
    ACTION_MISS_PAYMENT_METHOD: "Cadastrar agora",
    CONTENT_MISS_PAYMENT_METHOD:
      "Você não tem um método de pagamento cadastrado - cadastre um agora para evitar suspensão da conta",
    PLAN_RECENTLY_EXPIRED_NOTICE:
      "Sua assinatura do Reinvent expirou. Atualize seu plano para restaurar todos os recursos.",
    PLAN_RECENTLY_EXPIRED_SELECT: "Atualizar meu plano do Reinvent",
    PLAN_SANDBOX_NOTICE:
      "Alguns recursos do Reinvent podem ser limitados, pois seu site está atualmente no modo sandbox. Você deve usá-lo apenas para fins de desenvolvimento.",
    SEE_HELP: "Porque estou vendo isso?",
    SEE_CHANGELOG: "Ver novas alterações",
    USAGE_QUOTA_ALMOST_REACHED_NOTICE:
      "Sua quota {name} está quase atingida. Talvez você precise atualizar seu limite para continuar usando este recurso.",
    USAGE_QUOTA_REACHED_NOTICE:
      "Você alcançou sua cota {name}. Você precisa atualizar seu limite para continuar usando este recurso.",
    VIEW_LIMIT_QUOTA: "Ver limite",
  },
  SPOTLIGHT: {
    FORM_FIELD_PLACEHOLDER: "Buscar (conteúdo de mensagem ou contatos)",
    FORM_ACTIONS_CLEAR: "Limpar",
    HISTORY_TITLE: "Últimas pesquisas",
    HISTORY_ITEM_FORGET: "Esquecer",
    RESULTS_SECTIONS_ALL: "Todos",
    RESULTS_SECTIONS_MESSAGES: "Mensagens",
    RESULTS_SECTIONS_CONTACTS: "Contatos",
    RESULTS_SECTIONS_HELPDESK: "Central de Ajuda",
    RESULTS_SECTIONS_PLUGINS: "Módulo de extensão",
    RESULTS_CONTENTS_ITEMS_TITLE_SINGULAR: "resultado",
    RESULTS_CONTENTS_ITEMS_TITLE_PLURAL: "resultados",
    RESULTS_CONTENTS_ITEMS_TITLE_TOTAL_SINGULAR: "resultado total",
    RESULTS_CONTENTS_ITEMS_TITLE_TOTAL_PLURAL: "resultados totais",
    RESULTS_CONTENTS_ITEMS_ACTIONS_NEXT: "Ver próximos resultados",
    RESULTS_CONTENTS_FILTERS_TITLE: "Filtrar por",
    RESULTS_CONTENTS_FILTERS_DATE_NAME: "Data",
    RESULTS_CONTENTS_FILTERS_DATE_CLEAR: "Limpar filtro da data",
    RESULTS_CONTENTS_FILTERS_DATE_START: "De",
    RESULTS_CONTENTS_FILTERS_DATE_END: "Para",
    RESULTS_CONTENTS_EMPTY:
      "Lamentamos, nenhum resultado encontrado para a pesquisa. Tente com outra palavra-chave.",
  },
  ALERT: {
    ACTION_GO: "Abrir a hiperligação da ação",
    DISCARD_ONE: "Descartar",
    DISCARD_ALL: "Descartar todos os alertas",
    EMPTY: {
      TITLE: "Nenhum alerta",
      DESCRIPTION:
        "Todos os alertas foram lidos. Novos alertas aparecerão aqui.",
    },
    TABS: {
      FEED: "Feed",
      UPDATES: "Atualizações",
    },
    FEED: {
      WEBSITE_AGENT_LIMIT_REACHED: {
        TITLE: "Limite de agentes atingido",
        DESCRIPTION:
          "{website_name} atingiu o seu limite de agentes. Atualize o seu plano para adicionar mais agentes.",
      },
      BILLING_PAYMENT_METHOD_EXPIRE: {
        TITLE: "Método de pagamento expirado",
        DESCRIPTION:
          "O seu cartão de pagamento está expirando. Para manter ativas as suas assinaturas, por favor atualize o seu método de pagamento.",
      },
      BILLING_PAYMENT_ACTION: {
        TITLE: "Atenção para a renovação das assinaturas",
        DESCRIPTION:
          "Seu banco está pedindo que você valide o pagamento para renovar suas assinaturas do site Reinvent, devido ao 3-D Secure. Por favor, clique neste link.",
      },
      BILLING_PAYMENT_ERROR: {
        TITLE: "Falha ao renovar as assinaturas",
        DESCRIPTION:
          "O seu plano não pode ser renovado devido a falhas de pagamento. Por favor verifique a sua informação de pagamento.",
      },
      MESSAGE_REMINDER: {
        TITLE: "Lembrete acionado",
        DESCRIPTION: "Um lembrete agendado foi acionado ({reminder_note}).",
      },
      WEBSITE_EXPORT_COMPLETE: {
        TITLE: "Exportação concluída",
        DESCRIPTION:
          "A sua exportação está concluída. Agora você pode baixar o arquivo. O link vai expirar em um dia.",
      },
      HELPDESK_IMPORT_COMPLETE: {
        TITLE: "A importação da Central de Ajuda foi concluída",
        DESCRIPTION: "A sua Central de Ajuda está pronta.",
      },
      WEBSITE_SUBSCRIPTIONS_DEACTIVATED: {
        TITLE: "Assinaturas desativadas",
        DESCRIPTION:
          "As suas assinaturas do Reinvent foram desativadas devido a falhas de pagamento, ou o seu site não tem nenhum método de pagamento.",
      },
      WEBSITE_PLAN_TRIAL_ENDED: {
        TITLE: "O período de teste do Reinvent acabou",
        DESCRIPTION:
          "Agora você está usando o Reinvent Basic, porque sua avaliação do Reinvent Unlimited terminou e seu site não tem método de pagamento.",
      },
      WEBSITE_AGENT_PURGED: {
        TITLE: "Acesso ao site revogado",
        DESCRIPTION:
          "{website_name} tem demasiados agentes. Você foi removido do {website_name} para se enquadrar no limite de agentes do plano.",
      },
      WEBSITE_CERTIFICATE_GENERATION_FAILURE: {
        TITLE: "Erro na geração de certificado",
        DESCRIPTION:
          "O certificado para **{domain}** não pôde ser gerado, o que resultará em erros de segurança neste domínio. Fale com nosso suporte para corrigir isso.",
      },
      WEBSITE_CERTIFICATE_RENEWAL_FAILURE: {
        TITLE: "Erro na renovação do certificado",
        DESCRIPTION:
          "O certificado para **{domain}** não pôde ser renovado, o que significa que este domínio pode não ser mais alcançável em breve. Por favor contacte o nosso suporte para resolver este problema.",
      },
    },
  },
  EDITOR: {
    MODE_CODE: "Conteúdo",
    MODE_PREVIEW: "Pré-visualização",
    POPUPS_INSERT_COLOR_FIELD_LABEL_COLOR: "Cor do texto",
    POPUPS_INSERT_COLOR_FIELD_COLOR_PLACEHOLDER: "Código da cor (ex.#000000)",
    POPUPS_INSERT_COLOR_FIELD_LABEL_LABEL: "Conteúdo do texto",
    POPUPS_INSERT_COLOR_FIELD_LABEL_PLACEHOLDER: "Insira a cor do texto…",
    POPUPS_INSERT_COLOR_PROCEED: "Insira o Texto Colorido",
    POPUPS_INSERT_CODE_PROCEED: "Inserir Código",
    POPUPS_INSERT_CODE_FIELD_VARIANT_LABEL: "Tipo de código",
    POPUPS_INSERT_CODE_FIELD_VARIANT_OPTION_INLINE:
      "Código Inline (linha única)",
    POPUPS_INSERT_CODE_FIELD_VARIANT_OPTION_BLOCK:
      "Bloco de código (multilinha)",
    POPUPS_INSERT_CODE_FIELD_LANGUAGE_LABEL: "Linguagem de programação",
    POPUPS_INSERT_LINK_FIELD_TYPE_MODE_URL: "URL externo",
    POPUPS_INSERT_LINK_FIELD_TYPE_MODE_HELPDESK: "Artigo da Central de Ajuda",
    POPUPS_INSERT_LINK_FIELD_LABEL_LABEL: "Descrição do link",
    POPUPS_INSERT_LINK_FIELD_LABEL_PLACEHOLDER: "Digite a descrição do link",
    POPUPS_INSERT_LINK_FIELD_URL_LABEL: "Link",
    POPUPS_INSERT_LINK_FIELD_URL_PLACEHOLDER: "Digite o link…",
    POPUPS_INSERT_LINK_FIELD_LOCALE_LABEL: "Idioma",
    POPUPS_INSERT_LINK_FIELD_ARTICLE_LABEL: "Artigo",
    POPUPS_INSERT_LINK_PROCEED: "Digite o link",
    POPUPS_INSERT_IMAGE_FIELD_LABEL_LABEL: "Descrição da imagem",
    POPUPS_INSERT_IMAGE_FIELD_LABEL_PLACEHOLDER:
      "Digite a descrição da imagem…",
    POPUPS_INSERT_IMAGE_PROCEED: "Inserir Imagem",
    POPUPS_EDIT_IMAGE_FIELD_URL_LABEL: "URL da imagem",
    POPUPS_EDIT_IMAGE_FIELD_URL_PLACEHOLDER: "Digite a URL da imagem…",
    POPUPS_EDIT_IMAGE_FIELD_WIDTH_LABEL: "Largura (em pixels)",
    POPUPS_EDIT_IMAGE_FIELD_HEIGHT_LABEL: "Altura (em pixels)",
    POPUPS_EDIT_IMAGE_FIELD_ALIGN_LABEL: "Alinhar",
    POPUPS_EDIT_IMAGE_FIELD_RATIO_LABEL: "Manter proporção",
    POPUPS_EDIT_IMAGE_FIELD_ALIGN_OPTION_LEFT: "À esquerda",
    POPUPS_EDIT_IMAGE_FIELD_ALIGN_OPTION_CENTER: "No centro",
    POPUPS_EDIT_IMAGE_FIELD_ALIGN_OPTION_RIGHT: "À direita",
    POPUPS_EDIT_IMAGE_PROCEED: "Editar imagem",
    POPUPS_INSERT_GIF_FIELD_SEARCH_PLACEHOLDER: "Procurar por GIFs…",
    POPUPS_INSERT_GIF_PROCEED: "Inserir GIF",
    POPUPS_INSERT_VIDEO_FIELD_LABEL_LABEL: "Rótulo do vídeo",
    POPUPS_INSERT_VIDEO_FIELD_LABEL_PLACEHOLDER: "Inserir rótulo do vídeo",
    POPUPS_INSERT_VIDEO_FIELD_URL_LABEL: "URL do vídeo do YouTube",
    POPUPS_INSERT_VIDEO_FIELD_URL_PLACEHOLDER:
      "Inserir o URL do vídeo do YouTube",
    POPUPS_INSERT_VIDEO_PROCEED: "Inserir vídeo",
    POPUPS_INSERT_FILE_FIELD_TEXT_LABEL: "Texto do link do arquivo",
    POPUPS_INSERT_FILE_FIELD_TEXT_PLACEHOLDER:
      "Digite o texto para o link do arquivo…",
    POPUPS_INSERT_FILE_PROCEED: "Inserir arquivo",
    POPUPS_INSERT_REPLACEMENT_FIELD_TAG_LABEL: "Tag de substituição",
    POPUPS_INSERT_REPLACEMENT_FIELD_FALLBACK_LABEL:
      "Valor substituto (opcional)",
    POPUPS_INSERT_REPLACEMENT_FIELD_FALLBACK_PLACEHOLDER:
      "Insira um valor substituto …",
    POPUPS_INSERT_REPLACEMENT_PROCEED: "Inserir Tag de Substituição",
    WIDGETS_LINK_EDIT: "Editar",
  },
  CHATBOX_SETUP: {
    SLIDESHOW: {
      WORDPRESS: {
        "1_TITLE": "Vá para seu Painel de Administração Wordpress",
        "1_DESCRIPTION": "E depois vá para Plugins &gt; Adicionar novo.",
        "2_TITLE": "Busca e instalação Reinvent",
        "2_DESCRIPTION":
          "Digite Reinvent no console de busca e depois instale o plugin.",
        "3_TITLE": "Ativar Reinvent",
        "3_DESCRIPTION":
          "Uma vez que Reinvent esteja instalado, clique em ativado.",
        "4_TITLE": "Link Reinvent",
        "4_DESCRIPTION": "Instale Reinvent em seu site WordPress.",
      },
      WEBFLOW: {
        "1_TITLE": "Vá para seu Painel de Administração do Webflow",
        "1_DESCRIPTION": "E depois gaste o menu de configurações.",
        "2_TITLE": "Ir para Configurações",
        "2_DESCRIPTION": "Você será redirecionado para a seção de ajustes.",
        "3_TITLE": "Clique no código personalizado",
        "3_DESCRIPTION": "E depois cole o código do widget Reinvent.",
        "4_TITLE": "Publicar mudanças",
        "4_DESCRIPTION": "Publique mudanças em seus domínios Webflow.",
      },
      WIX: {
        "1_TITLE": "Selecione seu site Wix",
        "1_DESCRIPTION": "E depois clique em Select &amp; Edit site.",
        "2_TITLE": "Ir para Configurações",
        "2_DESCRIPTION": "Você será redirecionado para a seção de ajustes.",
        "3_TITLE": "Clique no código personalizado",
        "3_DESCRIPTION":
          "Você será redirecionado para a seção de códigos personalizados.",
        "4_TITLE": "Clique em Adicionar Código",
        "4_DESCRIPTION": "Adicione seu código na seção HEAD.",
        "5_TITLE": "Colar o código do Widget Reinvent ",
        "5_DESCRIPTION": "E não se esqueça de economizar!",
      },
      SQUARESPACE: {
        "1_TITLE": "Vá para seu Painel de Administração do Squarespace",
        "1_DESCRIPTION": "E depois vá para Settings.",
        "2_TITLE": "Ir para Configurações",
        "2_DESCRIPTION": "Você será redirecionado para a seção avançada.",
        "3_TITLE": "Clique código de injeção",
        "3_DESCRIPTION":
          "Isto só está disponível nos planos pagos da Squarespace.",
        "4_TITLE": "Cole o código do widget Reinvent ",
        "4_DESCRIPTION": "E não se esqueça de economizar!",
      },
      JOOMLA: {
        "1_TITLE": "Vá para seu Painel de Administração Joomla",
        "1_DESCRIPTION": "E depois vá para System.",
        "2_TITLE": "Ir para Extensões",
        "2_DESCRIPTION": "Depois, para instalar a partir da Web.",
        "3_TITLE": "Busca de Reinvent",
        "3_DESCRIPTION": "A partir da guia Instalar da Web.",
        "4_TITLE": "Instalar Reinvent",
        "4_DESCRIPTION": "A partir da guia Instalar da Web.",
        "5_TITLE": "Instalar Reinvent",
        "5_DESCRIPTION": "Clique no botão verde de instalação.",
        "6_TITLE": "Ir para o módulo Reinvent ",
        "6_DESCRIPTION": "Clique em System e depois em Reinvent.",
        "7_TITLE": "Link Reinvent com seu Joomla",
        "7_DESCRIPTION": "Clique em Conectar com Reinvent.",
      },
    },
  },
};
