<template>
  <BasePopup v-on:close="this.$emit('close')" v-on:enter="onConfirmLogout">
    <template v-slot:actions>
      <div :style="{ 'margin-right': '10px' }">
        <BaseButton v-on:click="this.$emit('close')" color="black" reverse>
          {{ this.$t("BUTTONS.CANCEL") }}
        </BaseButton>
      </div>
      <BaseButton
        v-on:click="onConfirmLogout"
        color="red"
        left-icon="check_circle"
      >
        {{ this.$t("POPUPS.AUTHENTICATION.CONFIRM_LOGOUT_PROCEED") }}
      </BaseButton>
    </template>

    <BaseHeadlines
      :title="this.$t('POPUPS.AUTHENTICATION.CONFIRM_LOGOUT_TITLE')"
      :description="this.$t('POPUPS.AUTHENTICATION.CONFIRM_LOGOUT_DESCRIPTION')"
    />
  </BasePopup>
</template>
<script>
export default {
  emits: ["close", "logout"],
  methods: {
    onConfirmLogout() {
      this.$emit("logout");
    },
  },
};
</script>
