import axios from "axios";
import qs from "qs";
// import $store from "@/store";
import $router from "@/router";
import BaseToast from "@/components/Base/BaseToast.vue";

import { createI18n } from "vue-i18n";
const i18n = new createI18n({
  locale: "pt-BR",
  fallbackLocale: "en-US",
  // fallbackRootWithEmptyString: true,
  messages: {
    "en-US": require("@/localize/api/locale-en.json"),
    "pt-BR": require("@/localize/api/locale-pt_BR.json"),
  },
});

class _http {
  constructor() {
    this.interceptorDisabled = !1;
    this.unauthorizedDisabled = !1;
    this.offlineCount = 0;
    this.offlineLockMode = !0;
    this.offlineScheduler = null;
    this._client = axios.create({
      baseURL: `${process.env.VUE_APP_API_ADDRESS}`,
      timeout: 30000,
    });
    this._transformResponses();
  }
  delete(e, t = {}, i = {}, a = {}) {
    return this._client.delete(this._prepareRestUrl(e, t), {
      ...a,
      data: i,
    });
  }
  get(e, t = {}, i = {}) {
    return this._client.get(this._prepareRestUrl(e, t), i);
  }
  head(e, t = {}, i = {}) {
    return this._client.head(this._prepareRestUrl(e, t), i);
  }
  patch(e, t = {}, i = {}, a = {}) {
    return this._client.patch(this._prepareRestUrl(e, t), i, a);
  }
  post(e, t = {}, i = {}, a = {}) {
    return this._client.post(this._prepareRestUrl(e, t), i, a);
  }
  put(e, t = {}, i = {}, a = {}) {
    return this._client.put(this._prepareRestUrl(e, t), i, a);
  }
  _prepareRestUrl(e = [], t = {}) {
    let i = "";
    return (
      Array.isArray(e) ? (i += e.join("/")) : "string" === typeof e && (i += e),
      Object.keys(t).length > 0 && (i += "?" + qs.stringify(t)),
      i
    );
  }
  addInterceptor(e, t) {
    return this._client.interceptors.response.use(e, t);
  }
  deleteHeader(e) {
    delete this._client.defaults.headers.common[e];
  }
  setHeader(e, t) {
    this._client.defaults.headers.common[e] = t;
  }
  one(e, t) {
    return new httpFactory(this, t ? [e, t].join("/") : e);
  }
  _transformResponses() {
    this._client.interceptors.response.use(
      (res) => {
        if (res.data && "object" === typeof res.data.data) {
          // e.response.data.status = e.response.status;
          res.data.data.__status = res.status;
          return Promise.resolve(res.data.data);
        } else {
          return Promise.resolve(res);
        }
        //     e.headers &&
        //       e.headers["x-safe-mode"] &&
        //       store.commit("common/setSafeModeEnabled", {
        //         value: !0,
        //       }),
        //     e.data && "object" === typeof e.data.data
        //       ? ((e.data.data.__status = e.status),
        //         (e.data.data.__url = (e.request || {}).responseURL || ""),
        //         Promise.resolve(e.data.data))
        //       : Promise.resolve(e)
        //   ),
        //   (e) =>
        //     e.response && "object" === typeof e.response.data
        //       ? ((e.response.data.status = e.response.status),
        //         (e.response.data.headers = e.response.headers),
        //         (e.response.data.silent = !0 === (e.response.config || {}).silent),
        //         Promise.reject(e.response.data))
        //       : Promise.reject(e.response)
      },
      (error) => {
        if (error.response.status === 401) {
          /**
           * handling with logout expired token cases
           */
          $router.push({ name: "initiate.logout" });
          BaseToast.warning(i18n.global.t("TOKEN_EXPIRED_ERROR"));
        } else {
          /**
           * Translatable error
           */
          if (error?.response?.data?.error) {
            BaseToast.error(i18n.global.t(error?.response?.data?.error));
          } else {
            BaseToast.error(
              "For some unknown reason your request has not success #B5999",
            );
          }
        }

        return Promise.reject(error);
      },
    );
  }
}

class httpFactory {
  constructor(e, t) {
    return (this._httpFactory = e), (this._relativePath = t), this;
  }
  one(e, t) {
    return new httpFactory(
      this._httpFactory,
      t
        ? [this._relativePath, e, t].join("/")
        : [this._relativePath, e].join("/"),
    );
  }
  delete(e = {}, t = {}, i = {}) {
    return this._httpFactory.delete(this._relativePath, t, e, i);
  }
  get(e = {}, t = {}) {
    return this._httpFactory.get(this._relativePath, e, t);
  }
  head(e = {}, t = {}) {
    return this._httpFactory.head(this._relativePath, e, t);
  }
  patch(e = {}, t = {}, i = {}) {
    return this._httpFactory.patch(this._relativePath, t, e, i);
  }
  post(e = {}, t = {}, i = {}) {
    return this._httpFactory.post(this._relativePath, t, e, i);
  }
  put(e = {}, t = {}, i = {}) {
    return this._httpFactory.put(this._relativePath, t, e, i);
  }
}

export default new _http();
