<template>
  <div
    :class="[
      'c-sidebar',
      {
        'c-sidebar--mac': isMacOs,
      },
    ]"
  >
    <div class="c-sidebar__wrapper">
      <div class="c-sidebar__switcher">
        <div
          class="c-sidebar__avatar-wrapper u-has-tooltip"
          v-on:click="(...e) => onWebsiteClick(...e)"
        >
          <BaseAvatar
            class="c-sidebar__avatar c-sidebar__avatar--website"
            :availability="'online'"
            :avatar-id="tenantID"
            :avatar-url="avatarUrl"
            custom-size="32px"
            parent-color="#1c293b"
            cursor="pointer"
            type="website"
          ></BaseAvatar>
          <!-- <BaseNotification
            v-if="Object.keys(notification).length > 0"
            :color="notification.color"
            :size="notification.count ? 'mini' : 'micro'"
            :grow-with="notification.count"
            :class="[
              'c-sidebar__notification',
              {
                'c-sidebar__notification--count': notification.count > 0,
              },
            ]"
          >
            {{ notification.count > 99 ? "99+" : notification.count }}
          </BaseNotification> -->
          <BaseTooltip
            v-if="currentTenant.tenantName"
            align="right"
            color="white"
            direction="middle"
          >
            {{ currentTenant.tenantName }}
            <!-- <span class="c-sidebar__tooltip-tips"
              >({{ this.$t("TOOLTIPS.SIDEBAR_WEBSITE_OFFLINE") }})</span
            > -->
          </BaseTooltip>
        </div>
        <SidebarMenu
          v-if="menus.website"
          class="c-sidebar__menu"
          v-on:close="(e) => (menus.website = !1)"
          v-on:logout="(e) => (popups.confirmLogout.visible = !0)"
          v-on:switchWebsite="onSwitchWebsiteProceed"
          v-on:showInviteOperator="onInviteOperator"
        ></SidebarMenu>
      </div>

      <component
        :is="item.component"
        v-for="item in features"
        :key="item.id"
        :to="item.disabled ? features[0].link : item.link"
        v-on:click="(t) => (item.onClick ? item.onClick() : null)"
        :class="[
          'c-sidebar__feature',
          'c-sidebar__feature--' + item.id,
          'u-has-tooltip',
          {
            'c-sidebar__feature--disabled': item.disabled,
            'c-sidebar__feature--loading': false,
          },
        ]"
      >
        <BaseTooltip
          v-if="item.tooltip"
          direction="middle"
          align="right"
          color="white"
        >
          {{ item.tooltip }}
          <span v-if="item.tips" class="c-sidebar__tooltip-tips">
            ({{ item.tips }})
          </span>
        </BaseTooltip>
        <BaseIcon
          v-if="item.icon"
          :name="item.icon.name"
          :class="[
            'c-sidebar__icon',
            {
              'c-sidebar__icon--active': item.id === currentSection,
            },
          ]"
        ></BaseIcon>
        <span
          v-else
          :class="[
            'c-sidebar__icon',
            {
              'c-sidebar__icon--active': item.id === currentSection,
            },
          ]"
        ></span>
        <span v-if="item.label" class="c-sidebar__label u-ellipsis">
          {{ item.label }}
        </span>
        <span
          v-if="item.notifications && item.notifications.count > 0"
          :class="[
            'c-sidebar__notifications',
            'c-sidebar__notifications--' + item.notifications.color,
            'u-bold',
          ]"
        >
          {{
            item.notifications.count > 999 ? "99+" : item.notifications.count
          }}
        </span>
      </component>
    </div>

    <ConfirmLogout
      v-if="popups.confirmLogout.visible"
      v-on:close="(e) => (popups.confirmLogout.visible = !1)"
      v-on:logout="onConfirmLogout"
    ></ConfirmLogout>

    <InviteOperator
      v-if="popups.inviteOperator.visible"
      v-on:close="(e) => (popups.inviteOperator.visible = !1)"
      :tenantID="tenantID"
    ></InviteOperator>

    <ChatboxIntegrations
      v-if="popups.chatboxIntegrations.visible"
      v-on:close="(e) => (popups.chatboxIntegrations.visible = !1)"
      :website-id="tenantID"
      :website-name="currentTenant.tenantName"
      :website-domain="currentTenant.tenantWebsite"
    ></ChatboxIntegrations>
  </div>
</template>
<script>
// var o = g(i("5bf4"));
// var n = g(i("9bed"));
import ConfirmLogout from "@/components/Popups/ConfirmLogout.vue";
// import * as r from "@/components/SwitchPlan.vue";
// import * as s from "@/components/UpgradePopup.vue";
import ChatboxIntegrations from "@/components/ChatboxIntegrations.vue";
// import * as c from "@/classes/websites.js";
// import * as d from "@/mixins/plugins.js";
// import * as M from "@/classes/platformAssistant.js";

import SidebarMenu from "@/components/SidebarMenu.vue";
import InviteOperator from "@/components/Popups/InviteOperator.vue";
import * as u from "@/classes/platform.js";

import Seetings from "@/views/app/website/plugins/settings.vue";

import mitt from "mitt";
const emitter = mitt();

const mapping = {
  "app/meetings/list": "meetings",
  "app/insights": "segments",
  "app/assistant": "assistant",
};

// const p = 36e5;
export default {
  components: {
    ChatboxIntegrations,
    SidebarMenu,
    InviteOperator,
    ConfirmLogout,
  },
  // mixins: [d.default],
  data() {
    return {
      isMacOs: "darwin" === u.default.getOs(),
      menus: {
        website: !1,
      },
      popups: {
        confirmLogout: {
          visible: !1,
        },
        chatboxIntegrations: {
          visible: !1,
        },
        inviteOperator: {
          visible: !1,
        },
      },
      acquireAlertsCountScheduler: null,
      featureSelectedID: null,
    };
  },
  showInviteOperator() {
    emitter.emit("inviteOperator");
  },
  watch: {
    $route() {
      this.featureSelectedID = null;
    },
  },
  computed: {
    avatarUrl() {
      return require("@/assets/images/components/base/BaseAvatar/reinvent.png");
    },
    tenantID() {
      return this.$store.getters["tenantsTalqui/getTenantID"];
    },
    currentTenant() {
      return (
        this.$store.getters["tenantsTalqui/getTenant"](this.tenantID) || {}
      );
    },
    features() {
      return [
        {
          id: "home",
          tooltip: "Home",
          ...(this.$route.name === "app.website.plugins.settings"
            ? {
                component: "span",
                onClick: () =>
                  this.propagateFrameData("home", {
                    name: "app.home",
                  }),
              }
            : {
                component: "router-link",
                link: {
                  name: "app.website.plugins.settings",
                  params: {
                    tenantID: this.$route.params.tenantID,
                    pluginID: process.env.VUE_APP_REINVENT_PLUGIN,
                  },
                  query: {},
                },
              }),
          icon: {
            name: "home",
          },
        },
        {
          id: "meetings",
          tooltip: "Meetings",
          ...(this.$route.name === "app.website.plugins.settings"
            ? {
                component: "span",
                onClick: () =>
                  this.propagateFrameData("meetings", {
                    name: "app.meetings.list",
                  }),
              }
            : {
                component: "router-link",
                link: {
                  name: "app.website.plugins.settings",
                  params: {
                    tenantID: this.$route.params.tenantID,
                    pluginID: process.env.VUE_APP_REINVENT_PLUGIN,
                  },
                  query: {
                    page: "app/meetings/list",
                  },
                },
              }),
          icon: {
            name: "signal",
          },
        },
        {
          id: "segments",
          tooltip: "Segmentos",
          ...(this.$route.name === "app.website.plugins.settings"
            ? {
                component: "span",
                onClick: () =>
                  this.propagateFrameData("segments", {
                    name: "app.insights",
                  }),
              }
            : {
                component: "router-link",
                link: {
                  name: "app.website.plugins.settings",
                  params: {
                    tenantID: this.$route.params.tenantID,
                    pluginID: process.env.VUE_APP_REINVENT_PLUGIN,
                  },
                  query: {
                    page: "app/insights",
                  },
                },
              }),
          icon: {
            name: "light-bulb",
          },
        },
        {
          id: "assistant",
          tooltip: "Assistant",
          ...(this.$route.name === "app.website.plugins.settings"
            ? {
                component: "span",
                onClick: () =>
                  this.propagateFrameData("assistant", {
                    name: "app.assistant",
                  }),
              }
            : {
                component: "router-link",
                link: {
                  name: "app.website.plugins.settings",
                  params: {
                    tenantID: this.$route.params.tenantID,
                    pluginID: process.env.VUE_APP_REINVENT_PLUGIN,
                  },
                  query: {
                    page: "app/assistant",
                  },
                },
              }),
          icon: {
            name: "sparkles",
          },
        },
        {
          id: "inbox",
          tooltip: this.$t("SIDEBAR.INBOX"),
          component: "router-link",
          link: {
            name: "app.website.inbox.index",
            params: {
              tenantID: this.tenantID,
            },
          },
          icon: {
            name: "chat-bubble-left",
          },
        },
        {
          id: "campaigns",
          tooltip: this.$t("SIDEBAR.CAMPAIGNS"),
          component: "router-link",
          link: {
            name: "app.website.campaigns.index",
            params: {
              tenantID: this.tenantID,
              channel: "all",
            },
          },
          icon: {
            name: "paper-airplane",
          },
        },
        {
          id: "search",
          tooltip: "" + this.$t("SIDEBAR.SEARCH"),
          component: "span",
          onClick: this.onFeatureSearchClick,
          icon: {
            name: "search",
          },
        },
        {
          id: "settings",
          tooltip: this.$t("SIDEBAR.SETTINGS"),
          component: "router-link",
          link: {
            name: "app.settings.index",
          },
          disabled: false,
          icon: {
            name: "settings",
          },
        },
      ];
    },
    currentSection() {
      if (this.featureSelectedID) {
        return this.featureSelectedID;
      }

      if (mapping[this.$route.query?.page]) {
        return mapping[this.$route.query?.page];
      }

      let $routeName = this.$route.name;
      return $routeName === "app.website.plugins.settings"
        ? "home"
        : $routeName.startsWith("app.settings.")
          ? $routeName.split(".")[1]
          : $routeName.split(".")[2];
    },
    spotlight() {
      return this.$store.getters["common/getSpotlight"];
    },
  },
  mounted() {
    emitter.on("inviteOperator", this.onInviteOperator);
  },
  beforeUnmount() {
    emitter.off("inviteOperator", this.onInviteOperator);
  },
  methods: {
    propagateFrameData(id = null, route) {
      this.featureSelectedID = id;

      Seetings.propagateFrameData({
        eventName: "router:change",
        eventData: route,
      });
    },
    onConfirmLogout() {
      this.popups.confirmLogout.visible = false;
      location.href = "/initiate/logout/";
    },
    onInviteOperator() {
      this.popups.inviteOperator.visible = true;
    },
    onFeatureSearchClick() {
      this.$store.commit("common/setSpotlight", {
        value: !this.spotlight,
      });
    },
    onChatboxSetupClick() {
      this.popups.chatboxIntegrations.visible = !0;
    },

    onWebsiteClick() {
      this.menus.website = !this.menus.website;
    },
    onSwitchWebsiteProceed(tenantID) {
      this.menus.website = !1;
      window.location.replace(`/tenant/${tenantID}/inbox`);
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.c-base-avatar__picture) {
  background-color: unset;
  border-radius: unset;
}

.c-sidebar {
  z-index: 3;
  width: 78px;
  height: 100%;
  background-color: #151823;
  -webkit-app-region: drag;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .c-sidebar__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin: 30px 0 12px;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
    .c-sidebar__tooltip-tips {
      padding-left: 7px;
      color: #75869c;
    }
    .c-sidebar__switcher {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-bottom: 42px;
      .c-sidebar__avatar-wrapper {
        cursor: pointer;
        .c-sidebar__avatar {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          margin-bottom: 14px;
          border-radius: 100%;
          -webkit-transition: -webkit-box-shadow 0.15s linear;
          transition: -webkit-box-shadow 0.15s linear;
          transition: box-shadow 0.15s linear;
          transition:
            box-shadow 0.15s linear,
            -webkit-box-shadow 0.15s linear;
        }
        .c-sidebar__avatar--website {
          margin-bottom: 0;
        }
      }
      .c-sidebar__notification {
        position: absolute;
        right: -2px;
        bottom: -4px;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        border: 2px solid #1c293b;
      }
      .c-sidebar__notification--count {
        right: -5px;
        bottom: -7px;
      }
      .c-sidebar__menu {
        position: absolute;
        top: 55px;
        left: 0;
      }
    }
    .c-sidebar__feature {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      margin-bottom: 2px;
      padding: 2px;
      width: 50px;
      height: 50px;
      border-radius: 2px;
      text-align: center;
      -webkit-transition: background-color 0.1s linear;
      transition: background-color 0.1s linear;
      cursor: pointer;
      will-change: background-color;
      .c-sidebar__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 24px;
        height: 24px;
        outline: 0;
        background-size: cover;
        text-align: center;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
      }
      .c-sidebar__label {
        position: absolute;
        right: -4px;
        bottom: calc(-50% + 4px);
        left: -4px;
        display: block;
        color: #fff;
        text-align: center;
        letter-spacing: 0.3px;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        -webkit-transition: color 0.1s linear;
        transition: color 0.1s linear;
      }
      .c-sidebar__notifications {
        position: absolute;
        right: -2px;
        bottom: 0;
        z-index: 1;
        display: inline-block;
        padding: 0 5px;
        min-width: 8px;
        height: 20px;
        border-radius: 2px;
        -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
        color: #fff;
        text-shadow: none;
        letter-spacing: 0.5px;
        font-size: 13px;
        line-height: 21px;
        -webkit-transition: bottom 75ms linear;
        transition: bottom 75ms linear;
        -webkit-animation-name: bounceIn;
        animation-name: bounceIn;
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }
      .c-sidebar__notifications--blue {
        background-color: #0093ee;
      }
      .c-sidebar__notifications--orange {
        background-color: #fd7b1f;
      }
      .c-sidebar__notifications--red {
        background-color: #e0102b;
      }
      &:active {
        .c-sidebar__notifications {
          bottom: -4px;
        }
        background-color: hsla(0, 0%, 100%, 0.07);
      }
      &:hover {
        .c-sidebar__notifications {
          bottom: -4px;
        }
        background-color: hsla(0, 0%, 100%, 0.05);
      }
    }
    .c-sidebar__feature.u-has-tooltip {
      .c-base-tooltip {
        width: 140px;
      }
    }
    .c-sidebar__feature {
      &--search {
        margin: auto 0 0;
      }

      &--search,
      &--home,
      &--meetings,
      &--segments,
      &--assistant,
      &--inbox,
      &--campaigns,
      &--settings {
        width: 46px;
        height: 46px;
        border-radius: 100%;
        .c-sidebar__icon {
          fill: #75869c;
          stroke: #75869c;

          &--active {
            fill: #fff;
            stroke: #fff;
          }
        }
      }
    }
    .c-sidebar__feature--disabled {
      cursor: not-allowed;
    }
    .c-sidebar__feature--loading {
      -webkit-animation: sidebarFeatureLoading 2s linear infinite;
      animation: sidebarFeatureLoading 2s linear infinite;
    }
  }
}
.c-sidebar--mac {
  .c-sidebar__wrapper {
    margin-top: 60px;
  }
}
@media (max-width: 68.75em) {
  .c-sidebar {
    width: 60px;
    .c-sidebar__wrapper {
      margin-top: 45px;
      .c-sidebar__feature {
        margin-bottom: 8px;
        width: 40px;
        height: 40px;
        .c-sidebar__icon {
          width: 20px;
          height: 20px;
        }
        .c-sidebar__label {
          display: none;
        }
        .c-sidebar__notifications {
          right: 0;
          padding: 0 4px;
          height: 17px;
          font-size: 11px;
          line-height: 18px;
        }
      }
    }
  }
}

@media (max-height: 41.25em) {
  .c-sidebar {
    .c-sidebar__wrapper {
      margin-top: 22px;
      .c-sidebar__switcher {
        margin-bottom: 22px;
      }
    }
  }
}
@media (max-width: 34.3125em) {
  .c-sidebar {
    display: none;
  }
}
@media (max-height: 38.75em) {
  .c-sidebar {
    width: 60px;
    .c-sidebar__wrapper {
      .c-sidebar__feature {
        margin-bottom: 8px;
        width: 40px;
        height: 40px;
        .c-sidebar__icon {
          width: 20px;
          height: 20px;
        }
        .c-sidebar__label {
          display: none;
        }
        .c-sidebar__notifications {
          right: 0;
          padding: 0 4px;
          height: 17px;
          font-size: 11px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
