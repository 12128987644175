<template>
  <div class="c-plugins-settings">
    <div class="c-plugins-settings__container">
      <div v-if="!isReady || loading" class="c-plugins-settings__loader">
        <BaseSpinner class="c-plugins-settings__spinner" />
      </div>

      <BaseFrame
        v-if="pluginSettingsReference"
        @message="onHandleMessageIncoming"
        @frameLoaded="onLoadFrame"
        :src="pluginSettingsReference"
        :class="[
          'c-plugins-settings__iframe',
          {
            'c-plugins-settings__iframe--invisible': !isReady,
          },
        ]"
        fallback="webview.js"
        ref="frame"
      />
    </div>
  </div>
</template>
<script>
import BaseToast from "@/components/Base/BaseToast.vue";

import mitt from "mitt";
const emitter = mitt();

export default {
  data() {
    return {
      tenantID: this.$route.params.tenantID,
      pluginID: this.$route.params.pluginID,
      loading: true,
      isReady: false,
    };
  },
  computed: {
    pluginDetails() {
      return this.$store.getters["pluginsTalqui/getPluginDetails"](
        this.pluginID,
      );
    },
    operatorToken() {
      return this.$store.getters["operatorsTalqui/getToken"];
    },
    pluginSettingsReference() {
      if (!this.pluginDetails?.pluginSettings) return "";

      return `${this.pluginDetails.pluginSettings}${this.$route.query?.page || ""}?tenantID=${this.tenantID}&pluginID=${this.pluginID}&p-token=${this.operatorToken}`;
    },
    pluginSettings() {
      return this.$store.getters["pluginsTalqui/getPluginConnection"](
        this.pluginDetails?.pluginURN,
      );
    },
  },
  watch: {
    pluginSettings(newSettings) {
      this.propagateFrameData({
        eventName: "plugin:settings",
        eventData: newSettings || {},
      });
    },
  },
  destroyed() {
    this.unbindEventHandlers();
  },
  unmounted() {
    this.unbindEventHandlers();
  },
  propagateFrameData(payload) {
    emitter.emit("propagateFrameData", payload);
  },
  mounted() {
    this.loading = true;

    this.loadSettings();
    this.loadPluginsConnections();
    this.bindEventHandlers();
    emitter.on("propagateFrameData", this.propagateFrameData);
  },
  beforeUnmount() {
    emitter.off("propagateFrameData", this.propagateFrameData);
  },
  methods: {
    loadSettings() {
      this.$store
        .dispatch("pluginsTalqui/acquirePluginDetails", {
          pluginID: this.pluginID,
        })
        .then(() => {
          this.isReady = true;
        })
        .catch((err) => {
          console.log("@error-aquire-details", err);
        });
    },
    loadPluginsConnections() {
      this.$store.dispatch("pluginsTalqui/acquirePluginConnections", {
        tenantID: this.tenantID,
      });
    },
    isJsonEvent(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }

      return true;
    },
    bindEventHandlers() {
      window.addEventListener("message", this.onHandleMessageIncoming, false);
    },
    unbindEventHandlers() {
      window.removeEventListener(
        "message",
        this.onHandleMessageIncoming,
        false,
      );
    },
    onHandleMessageIncoming(e) {
      if (this.isJsonEvent(e.data)) {
        var eventDecoded = JSON.parse(e.data);
        let hasEventName = Object.prototype.hasOwnProperty.call(
          eventDecoded,
          "name",
        );
        let hasEventData = Object.prototype.hasOwnProperty.call(
          eventDecoded,
          "data",
        );
        if (hasEventName && hasEventData) {
          switch (eventDecoded.name) {
            case "save:plugin":
              this.eventSavePlugin({ pluginSettings: eventDecoded.data });
              break;
            case "toast:show":
              this.eventToastShow({ payload: eventDecoded.data });
              break;
            case "router:push":
              this.eventRouterPush({ payload: eventDecoded.data });
              break;
          }
        }
      }
    },
    eventToastShow({ payload }) {
      var toastAction = BaseToast[payload.type];
      if (
        "function" === typeof toastAction &&
        typeof payload.message === "string"
      ) {
        toastAction(payload.message, !1);
      }
    },
    eventRouterPush({ payload }) {
      this.$router.push({
        name: payload.name,
        ...(payload?.params && {
          params: payload.params,
        }),
      });
    },
    eventSavePlugin({ pluginSettings }) {
      this.$store
        .dispatch("pluginsTalqui/upsertPluginConnection", {
          tenantID: this.tenantID,
          pluginID: this.pluginID,
          pluginSettings: pluginSettings,
        })
        .then((res) => {
          if (res.token) {
            this.propagateFrameData({
              eventName: "connection:success",
              eventData: res,
            });
          }
        });
    },
    propagateFrameData({ eventName, eventData = {} }) {
      let t = this.$refs.frame;

      if (t) {
        let n = JSON.stringify({
          name: eventName,
          data: eventData,
        });
        t.onData(n);
      }
    },
    onLoadFrame() {
      this.propagateFrameData({
        eventName: "plugin:settings",
        eventData: this.pluginSettings || {},
      });

      this.propagateFrameData({
        eventName: "plugin:environment",
        eventData: {
          tenantID: this.tenantID,
          pluginID: this.pluginID,
          token: this.operatorToken,
          sessionID: null,
          widgetID: null,
        },
      });

      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.c-plugins-settings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  .c-plugins-settings__top-bar {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .c-plugins-settings__container {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #f6f8fa;
    .c-plugins-settings__iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
    .c-plugins-settings__loader {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      .c-plugins-settings__spinner {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
      }
    }
    .c-plugins-settings__iframe--invisible {
      visibility: hidden;
    }
  }
}
</style>
