<template>
  <ValidationObserver as="div" v-slot="{ meta }">
    <BasePopup v-on:close="this.$emit('close')" v-on:enter="sendInvite">
      <BaseHeadlines
        :title="this.$t('TALQUI.POPUPS.INVITE_OPERATOR.TITLE')"
        :description="this.$t('TALQUI.POPUPS.INVITE_OPERATOR.DESCRIPTION')"
      />

      <BaseDivider class="u-mt20 u-mb20"></BaseDivider>

      <FieldInput
        class="u-mb20"
        :modelValue="form.inviteEmail"
        v-on:update:modelValue="(M) => (form.inviteEmail = M)"
        v-on:keyup="(M, L, j) => onInputKeyup(j, !meta.valid)"
        :autofocus="!form.inviteEmail"
        :disabled="loading"
        :label="this.$t('TALQUI.POPUPS.INVITE_OPERATOR.INVITE_EMAIL_LABEL')"
        :placeholder="
          this.$t('TALQUI.POPUPS.INVITE_OPERATOR.INVITE_EMAIL_PLACEHOLDER')
        "
        rules="required|email|max:100"
        name="email"
        size="medium"
        type="email"
      ></FieldInput>

      <template v-slot:actions>
        <div :style="{ 'margin-right': '10px' }">
          <BaseButton v-on:click="this.$emit('close')" color="red" reverse>
            {{ this.$t("BUTTONS.CANCEL") }}
          </BaseButton>
        </div>
        <BaseButton
          v-on:click="sendInvite"
          color="black"
          left-icon="check_circle"
          :disabled="!meta.valid || loading"
          :loading="loading"
        >
          {{ this.$t("TALQUI.POPUPS.INVITE_OPERATOR.CONFIRM_INVITE") }}
        </BaseButton>
      </template>
    </BasePopup>
  </ValidationObserver>
</template>
<script>
import tenant from "@/classes/talqui/tenant";
import BaseToast from "@/components/Base/BaseToast.vue";

export default {
  emits: ["close"],
  data() {
    return {
      loading: false,
      form: {
        inviteEmail: "",
      },
    };
  },
  props: {
    tenantID: {
      type: String,
      required: true,
    },
  },
  methods: {
    onInputKeyup(M, L = false) {
      "Enter" === M.key && !0 !== L && this.sendInvite();
    },
    sendInvite() {
      this.loading = true;
      return Promise.resolve()
        .then(() =>
          tenant.tenantOne(this.tenantID).one("operators").one("invite").post({
            inviteEmail: this.form.inviteEmail,
          }),
        )
        .then((res) => {
          console.log(res);
          BaseToast.success(
            this.$t("TALQUI.POPUPS.INVITE_OPERATOR.TOAST_SUCCESS"),
          );
          return Promise.resolve();
        })
        .catch(() => {
          BaseToast.error(this.$t("TALQUI.POPUPS.INVITE_OPERATOR.TOAST_ERROR"));
          return Promise.resolve();
        })
        .finally(() => {
          this.loading = false;
          this.$emit("close");
        });
    },
  },
};
</script>
