<template>
  <div class="c-sidebar-menu o-full-screen">
    <div class="c-sidebar-menu__container">
      <div class="c-sidebar-menu__box">
        <div class="c-sidebar-menu__inner">
          <div class="c-sidebar-menu__main">
            <BaseAvatar
              class="c-sidebar-menu__avatar"
              :avatar-id="me.operatorID"
              :avatar-url="me.operatorPhoto"
              :rounded="true"
              cursor="default"
              type="operator"
            >
            </BaseAvatar>
            <div class="c-sidebar-menu__content u-ellipsis">
              <span class="c-sidebar-menu__title u-ellipsis">
                {{ me.operatorFirstname + " " + me.operatorLastname }}
              </span>
              <span class="c-sidebar-menu__description u-ellipsis">
                {{ me.operatorEmail }}
              </span>
            </div>
          </div>
          <div class="c-sidebar-menu__items">
            <!-- <div class="c-sidebar-menu__item u-ellipsis">
              <span class="c-sidebar-menu__label">Ver alertas</span>
            </div> -->

            <!-- <a
              href="/settings/account/"
              class="c-sidebar-menu__item u-ellipsis"
            >
              <span class="c-sidebar-menu__label">Ajustes da conta</span>
            </a> -->

            <div
              v-on:click="onInviteOperator"
              class="c-sidebar-menu__item u-ellipsis"
            >
              <span class="c-sidebar-menu__label">Convidar um operador</span>
            </div>

            <!-- <div
              class="c-sidebar-menu__item u-ellipsis c-sidebar-menu__item--stealth-enable"
            >
              <span class="c-sidebar-menu__label">Ativar modo invisível</span>
            </div> -->

            <!-- <div
              class="c-sidebar-menu__item c-sidebar-menu__item--support u-ellipsis"
            >
              <span class="c-sidebar-menu__label">Central de ajuda</span>
            </div> -->

            <div
              v-on:click="onLogout"
              class="c-sidebar-menu__item c-sidebar-menu__item--logout u-ellipsis"
            >
              <span class="c-sidebar-menu__label">Sair da aplicação</span>
            </div>
          </div>

          <div class="c-sidebar-menu__websites">
            <div
              v-for="tenant in tenants"
              :key="tenant.tenantID"
              class="c-sidebar-menu__website"
              v-on:click="onSwitchWebsite(tenant.tenantID)"
            >
              <BaseAvatar
                class="c-sidebar-menu__avatar"
                :avatar-id="tenant.tenantID"
                :avatar-url="tenant.tenantLogo"
                cursor="default"
                type="website"
                :circular="true"
              />
              <div class="c-sidebar-menu__content u-ellipsis">
                <span class="c-sidebar-menu__title u-ellipsis">
                  {{ tenant.tenantName }}
                </span>
                <span class="c-sidebar-menu__description u-ellipsis">
                  {{ tenant.tenantWebsite }}
                </span>
              </div>
              <!-- <span
                class="c-base-notification c-base-notification--red c-base-notification--small c-sidebar-menu__notification"
              >
                <span class="c-base-notification__slot">4</span>
              </span> -->
            </div>
          </div>

          <div class="c-sidebar-menu__create u-ellipsis">
            <span class="c-sidebar-menu__label u-ellipsis"
              >Criar um novo website</span
            >
          </div>
        </div>
      </div>
    </div>

    <BaseOverlay
      class="c-sidebar-menu__overlay"
      v-on:click="onClose"
    ></BaseOverlay>
  </div>
</template>
<script>
import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import BaseOverlay from "@/components/Base/BaseOverlay.vue";
export default {
  components: {
    BaseAvatar,
    BaseOverlay,
    // AlertCenter: o.default,
  },
  emits: ["close", "logout", "switchWebsite", "showInviteOperator"],
  // data() {
  //   return {
  //     togglingInvisibleMode: !1,
  //     notifications: {
  //       visible: !1,
  //     },
  //   };
  // },
  computed: {
    hotkeys() {
      return {
        esc: this.onClose,
      };
    },
    me() {
      return this.$store.getters["operatorsTalqui/getMe"];
    },
    tenantID() {
      return this.$store.getters["tenantsTalqui/getTenantID"];
    },
    tenants() {
      return this.$store.getters["tenantsTalqui/getTenants"].filter(
        (tenant) => {
          return tenant.tenantID !== this.tenantID;
        },
      );
    },
    //   websitesWithoutCurrent() {
    //     return this.websites.filter((e) => e.id !== this.websiteId);
    //   },
    //   websiteId() {
    //     return (
    //       this.$store.getters["websites/getWebsiteId"] ||
    //       r.default.getDefaultWebsiteId()
    //     );
    //   },
    //   hasInviteOperator() {
    //     if (this.websiteId) {
    //       let e = this.operators[this.me.user_id];
    //       if (e && "owner" === e.role) return !0;
    //     }
    //     return !1;
    //   },
    //   operators() {
    //     return (
    //       this.$store.commit("operators/assertOperators", {
    //         websiteId: this.websiteId,
    //       }),
    //       this.$store.getters["operators/getOperators"](this.websiteId)
    //     );
    //   },
    //   alertsCount() {
    //     return this.$store.getters["alerts/getCount"];
    //   },
    //   websites() {
    //     return this.$store.getters["websites/getWebsites"];
    //   },
    //   me() {
    //     return this.$store.getters["user/getMe"];
    //   },
    //   stealth() {
    //     return this.$store.getters["user/getSchedule"].stealth;
    //   },
  },
  methods: {
    onInviteOperator() {
      // this.$router
      //   .push({
      //     name: "app.settings.websites.one",
      //     params: {
      //       websiteId: this.websiteId,
      //     },
      //   })
      //   .catch(() => {}),
      //   this.$store.commit("websites/setAddOperatorPopupVisible", {
      //     websiteId: this.websiteId,
      //     value: !0,
      //   }),
      this.$emit("showInviteOperator");
      this.$emit("close");
    },
    //   onAddWebsite() {
    //     this.$router
    //       .push({
    //         name: "app.settings.websites.all",
    //       })
    //       .catch(() => {}),
    //       this.$store.commit("websites/setAddWebsitePopupVisible", {
    //         value: !0,
    //       }),
    //       this.$emit("close");
    //   },
    //   onGetHelp() {
    //     n.default.show(), this.$emit("close");
    //   },
    onClose() {
      this.$emit("close");
    },
    onLogout() {
      this.$emit("close");
      this.$emit("logout");
    },
    //   onShowAlertCenter() {
    //     this.notifications.visible = !this.notifications.visible;
    //   },
    onSwitchWebsite(tenantID) {
      this.$emit("switchWebsite", tenantID);
    },
    //   onToggleInvisibleMode() {
    //     const e = !this.stealth;
    //     !0 !== this.togglingInvisibleMode &&
    //       ((this.togglingInvisibleMode = !0),
    //       this.$store
    //         .dispatch("user/toggleInvisibleMode", {
    //           stealth: e,
    //         })
    //         .then(
    //           () => (
    //             this.websiteId &&
    //               setTimeout(() => {
    //                 this.$store.dispatch("operators/acquireOperators", {
    //                   websiteId: this.websiteId,
    //                 });
    //               }, l),
    //             e
    //               ? a.default.info("app_invisible_mode_enabled")
    //               : a.default.info("app_invisible_mode_disabled"),
    //             this.$emit("close"),
    //             Promise.resolve()
    //           )
    //         )
    //         .catch(() => Promise.resolve())
    //         .then(() => {
    //           this.togglingInvisibleMode = !1;
    //         }));
    //   },
  },
};
</script>
<style lang="scss" scoped>
.c-sidebar-menu {
  z-index: 1000;
  -webkit-animation: fadeIn 0.1s linear;
  animation: fadeIn 0.1s linear;
  .c-sidebar-menu__container {
    position: absolute;
    top: 0;
    .c-sidebar-menu__box {
      position: relative;
      z-index: 1001;
      overflow: hidden;
      width: 300px;
      border-radius: 4px;
      background-color: #fff;
      -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
      -webkit-animation-name: fade-container;
      animation-name: fade-container;
      -webkit-animation-duration: 0.1s;
      animation-duration: 0.1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
      .c-sidebar-menu__inner {
        background-color: #fff;
        .c-sidebar-menu__main {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          padding: 15px 10px 10px 20px;
          .c-sidebar-menu__avatar {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            margin-right: 12px;
          }
          .c-sidebar-menu__content {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            .c-sidebar-menu__description {
              display: block;
              margin-top: 1px;
              color: #75869c;
              font-size: 13px;
            }
            .c-sidebar-menu__title {
              display: block;
              margin-top: 2px;
              color: #1c293b;
              font-weight: 700;
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
        .c-sidebar-menu__items {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          padding-bottom: 10px;
          .c-sidebar-menu__item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            padding: 6px 10px 6px 20px;
            background-color: #fff;
            color: #2c405a;
            font-weight: 700;
            font-size: 13px;
            -webkit-transition: none;
            transition: none;
            cursor: pointer;
            .c-sidebar-menu__spinner {
              margin-right: 7px;
              opacity: 0.65;
            }
            .c-sidebar-menu__label {
              -webkit-box-flex: 0;
              -ms-flex: 0 0 0 auto;
              flex: 0 0 0 auto;
            }
            .c-sidebar-menu__notification {
              -webkit-box-flex: 0;
              -ms-flex: 0 0 auto;
              flex: 0 0 auto;
              margin-left: 8px;
            }
            &:active {
              color: #fff;
              background-color: #008adf;
              -webkit-transition: background-color 75ms linear;
              transition: background-color 75ms linear;
              .c-sidebar-menu__notification {
                background-color: #fff;
                color: #1c293b;
              }
            }
            &:hover {
              color: #fff;
              background-color: #0093ee;
              .c-sidebar-menu__notification {
                background-color: #fff;
                color: #1c293b;
              }
            }
          }
          .c-sidebar-menu__item--stealth-disable.c-sidebar-menu__item--stealth-toggling {
            color: #fff;
            cursor: wait;
            background-color: #1e9a2f;
          }
          .c-sidebar-menu__item--stealth-enable.c-sidebar-menu__item--stealth-toggling {
            color: #fff;
            cursor: wait;
            background-color: #6c7e96;
          }
          .c-sidebar-menu__item--stealth-enable {
            color: #75869c;
            &:hover {
              background-color: #75869c;
            }
            &:active {
              background-color: #6c7e96;
            }
          }
          .c-sidebar-menu__item--stealth-disable {
            color: #21a733;
            &:hover {
              background-color: #21a733;
            }
            &:active {
              background-color: #1e9a2f;
            }
          }
          .c-sidebar-menu__item--support {
            color: #0093ee;
            &:hover {
              background-color: #0093ee;
            }
            &:active {
              background-color: #008adf;
            }
          }
          .c-sidebar-menu__item--logout {
            color: #e0102b;
            &:hover {
              background-color: #e0102b;
            }
            &:active {
              background-color: #d20f28;
            }
          }
        }
        .c-sidebar-menu__websites {
          overflow-x: hidden;
          overflow-y: auto;
          padding: 8px 8px 0;
          max-height: 210px;
          border-top: 1px solid #e3e8ef;
          &:after {
            display: block;
            height: 8px;
            content: "";
          }
          .c-sidebar-menu__website {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 10px 2px 10px 12px;
            border-radius: 3px;
            line-height: 0;
            -webkit-transition: background-color 50ms linear;
            transition: background-color 50ms linear;
            cursor: pointer;
            .c-sidebar-menu__avatar {
              -webkit-box-flex: 0;
              -ms-flex: 0 0 auto;
              flex: 0 0 auto;
            }
            .c-sidebar-menu__content {
              -webkit-box-flex: 1;
              -ms-flex: 1;
              flex: 1;
              margin: 0 12px;
              .c-sidebar-menu__description {
                display: block;
                line-height: 16px;
                margin-top: 3px;
                color: #3f536e;
                font-size: 13px;
              }
              .c-sidebar-menu__title {
                display: block;
                line-height: 16px;
                margin-top: 1px;
                color: #1c293b;
                font-weight: 700;
                font-size: 14px;
              }
            }
            .c-sidebar-menu__notification {
              -webkit-box-flex: 0;
              -ms-flex: 0 0 auto;
              flex: 0 0 auto;
              margin-right: 10px;
            }
            &:hover {
              background-color: #f4f6f8;
            }
            &:active {
              background-color: #edf1f4;
            }
          }
        }
        .c-sidebar-menu__create {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          padding: 0 10px;
          height: 39px;
          border-top: 1px solid #e3e8ef;
          background-color: #fafbfc;
          text-align: center;
          line-height: 22px;
          -webkit-transition: background-color 75ms linear;
          transition: background-color 75ms linear;
          cursor: pointer;
          &:last-of-type {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          .c-sidebar-menu__label {
            display: inline-block;
            color: #25374f;
            vertical-align: middle;
            font-weight: 700;
            font-size: 12.5px;
            -webkit-transition: all 75ms linear;
            transition: all 75ms linear;
            -webkit-transition-property:
              color,
              -webkit-transform;
            transition-property:
              color,
              -webkit-transform;
            transition-property: color, transform;
            transition-property:
              color,
              transform,
              -webkit-transform;
          }
          &:active {
            .c-sidebar-menu__label {
              color: #1c293b;
              -webkit-transform: translateY(1px);
              transform: translateY(1px);
            }
            background-color: #edf1f4;
          }
          &:hover {
            .c-sidebar-menu__label {
              color: #1c293b;
            }
            background-color: #f4f6f8;
          }
        }
      }
    }
  }
}
</style>
